import {
    isTaskCompletedMsg,
    isTaskFromQueueDeletedMsg,
    isTaskQueuedMsg,
    isTasksMovedMsg,
    TaskAssignedMsg,
    TaskCompletedMsg,
    TaskFromQueueDeletedMsg,
    TaskRoutedMsg
} from "../../models/task"
import { Dispatch } from "../../utility/common/storeHelper"
import { actions as queuesActions } from "../../store/queues/slice"
import { actions as operatorsActions } from "../../store/operators/slice"
import { logError } from "../../utility/common/logError"
import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Store } from "../../store/store"
import {
    isOperatorBecameActiveMsgDto,
    isOperatorStatusUpdatedMsgDto,
    OperatorBecameActiveMsg,
    OperatorStatusUpdatedMsg
} from "../../models/operator"
import { toSelectedOperatorStatusModel } from "../../utility/operatorStatus/convert"
import { RootState } from "../../store/rootReducer"
import { OperatorDtoConverter } from "../../utility/operators/convert"
import { isQueueCategory, isQueueCategoryRemoveResponse } from "../../models/queueCategory"
import { isQueueAddedMsg } from "../../models/queue"
import { updateSearch } from "../../store/queues/actions"
import debounce from "lodash/debounce"
import { channelTypeStringConverter } from "../../utility/channels/channelTypeStringConverter"
import { ChannelType } from "../../models/channel"

const TASK_QUEUED = "TaskQueued"
const TASKS_MOVED = "TasksMoved"
const TASK_DETACHED = "TaskDetached"
const TASK_ASSIGNED = "TaskAssigned"
const TASK_ROUTED = "TaskRouted"
const TASK_COMPLETED = "TaskCompleted"
const TASK_FROM_QUEUE_DELETED = "TaskFromQueueDeleted"
const OPERATOR_STATUS_UPDATED = "OperatorStatusUpdated"
const OPERATOR_BECAME_ACTIVE = "OperatorBecameActive"
const OPERATOR_BECAME_INACTIVE = "OperatorBecameInactive"
const OPERATOR_QUEUES_UPDATED = "OperatorQueuesUpdated"
const QUEUE_ADDED = "QueueAdded"
const QUEUE_UPDATED = "QueueUpdated"
const QUEUE_SL_UPDATED = "QueueSlUpdated"
const QUEUE_AWT_UPDATED = "QueueAwtUpdated"
const QUEUE_OPERATOR_TASKS_COUNT_UPDATED = "OperatorTasksCountUpdated"
const QUEUE_FINISHED_DIALOGS_UPDATED = "QueueFinishedDialogsUpdated"
const QUEUE_FINISHED_DIALOGS_DAILY_UPDATED = "QueueFinishedDialogsDailyUpdated"
const QUEUE_EXTEND_SETTINGS_UPDATED = "QueueExtendSettingsUpdated"
const QUEUE_CATEGORY_ADDED = "QueueCategoryAdded"
const QUEUE_CATEGORY_UPDATED = "QueueCategoryUpdated"
const QUEUE_CATEGORY_REMOVED = "QueueCategoryRemoved"

function logValidationError(event: string, data: string) {
    logError(`QueuesHub. ${event}. Received data is not valid.`, data)
}

function logHubError(event: string, error: unknown) {
    logError(`QueuesHub. ${event}. Unexpected error`, error)
}

class QueuesHub {
    private _hub: IHub
    private _tenantId?: string

    constructor(store: Store) {
        const reduxState = store.getState()
        let useAllTransportSignalR = false

        if (reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR) {
            useAllTransportSignalR = true
        }

        this._hub = new Hub(`/queues-hub`, useAllTransportSignalR)
        this.registerServerEvents(store.dispatch, store.getState)
    }

    async subscribe(tenantId: string) {
        await this._hub.subscribe("Subscribe", tenantId).catch(e => logError(e))
        this._tenantId = tenantId
    }

    async unsubscribe(tenantId: string) {
        await this._hub.unsubscribe("Unsubscribe", tenantId).catch(e => logError(e))
        this._tenantId = undefined
    }

    private registerServerEvents(dispatch: Dispatch, getState: () => RootState) {
        const debouncedUpdateSearch = debounce(() => dispatch(updateSearch(this._tenantId)), 2000)

        this._hub.registerEvent(TASK_QUEUED, (msgStr: string) => {
            try {
                const data = JSON.parse(msgStr)
                if (isTaskQueuedMsg(data)) {
                    data.Task.Channel.Type = channelTypeStringConverter.toChannelTypeString(
                        data.Task.Channel.Type as unknown as ChannelType
                    )
                    dispatch(queuesActions.addTask(data))
                    debouncedUpdateSearch()
                } else {
                    logValidationError(TASK_QUEUED, data)
                }
            } catch (e) {
                logHubError(TASK_QUEUED, e)
            }
        })
        this._hub.registerEvent(TASKS_MOVED, (msgStr: string) => {
            try {
                const data = JSON.parse(msgStr)
                if (isTasksMovedMsg(data)) {
                    dispatch(queuesActions.moveTasks(data))
                    debouncedUpdateSearch()
                } else {
                    logValidationError(TASKS_MOVED, data)
                }
            } catch (e) {
                logHubError(TASKS_MOVED, e)
            }
        })
        this._hub.registerEvent(TASK_DETACHED, (msgStr: string) => {
            try {
                dispatch(operatorsActions.detachTask(JSON.parse(msgStr)))
            } catch (e) {
                logHubError(TASK_DETACHED, e)
            }
        })
        this._hub.registerEvent(TASK_ASSIGNED, (msgStr: string) => {
            try {
                const msg: TaskAssignedMsg = JSON.parse(msgStr)
                msg.Task.Channel.Type = channelTypeStringConverter.toChannelTypeString(
                    msg.Task.Channel.Type as unknown as ChannelType
                )
                dispatch(
                    operatorsActions.addTask({
                        Task: msg.Task,
                        OperatorId: msg.OperatorId
                    })
                )
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(TASK_ASSIGNED, e)
            }
        })
        this._hub.registerEvent(TASK_ROUTED, (msgStr: string) => {
            try {
                const msg: TaskRoutedMsg = JSON.parse(msgStr)
                dispatch(
                    queuesActions.removeTask({
                        QueueId: msg.FromQueueId,
                        IsIndividual: msg.FromIndividual,
                        TaskId: msg.Task.Id
                    })
                )
                msg.Task.Channel.Type = channelTypeStringConverter.toChannelTypeString(
                    msg.Task.Channel.Type as unknown as ChannelType
                )
                dispatch(
                    operatorsActions.addTask({
                        Task: msg.Task,
                        OperatorId: msg.OperatorId
                    })
                )
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(TASK_ROUTED, e)
            }
        })
        this._hub.registerEvent(TASK_COMPLETED, (msgStr: string) => {
            try {
                const msg: TaskCompletedMsg = JSON.parse(msgStr)
                if (isTaskCompletedMsg(msg)) {
                    if (!msg.QueueId) {
                        return
                    }
                    dispatch(
                        queuesActions.removeTask({
                            QueueId: msg.QueueId,
                            IsIndividual: false,
                            TaskId: msg.TaskId
                        })
                    )
                    debouncedUpdateSearch()
                } else {
                    logValidationError(TASK_COMPLETED, msgStr)
                }
            } catch (e) {
                logHubError(TASK_COMPLETED, e)
            }
        })
        this._hub.registerEvent(TASK_FROM_QUEUE_DELETED, (msgStr: string) => {
            try {
                const msg: TaskFromQueueDeletedMsg = JSON.parse(msgStr)
                if (isTaskFromQueueDeletedMsg(msg)) {
                    dispatch(
                        queuesActions.removeTask({
                            QueueId: msg.FromQueueId,
                            IsIndividual: false,
                            TaskId: msg.TaskId
                        })
                    )
                    debouncedUpdateSearch()
                } else {
                    logValidationError(TASK_FROM_QUEUE_DELETED, msgStr)
                }
            } catch (e) {
                logHubError(TASK_FROM_QUEUE_DELETED, e)
            }
        })
        this._hub.registerEvent(OPERATOR_STATUS_UPDATED, (msgStr: string) => {
            try {
                const data = JSON.parse(msgStr)
                if (isOperatorStatusUpdatedMsgDto(data)) {
                    const state = getState()
                    const payload: OperatorStatusUpdatedMsg = {
                        ...data,
                        Status: toSelectedOperatorStatusModel(data.Status, state.userOperator.statuses)
                    }
                    dispatch(operatorsActions.updateOperatorStatus(payload))
                    debouncedUpdateSearch()
                } else {
                    logValidationError(OPERATOR_STATUS_UPDATED, data)
                }
            } catch (e) {
                logHubError(OPERATOR_STATUS_UPDATED, e)
            }
        })
        this._hub.registerEvent(OPERATOR_BECAME_ACTIVE, (msgStr: string) => {
            try {
                const data = JSON.parse(msgStr)
                if (isOperatorBecameActiveMsgDto(data)) {
                    const state = getState()
                    const payload: OperatorBecameActiveMsg = {
                        Operator: OperatorDtoConverter.toOperator(data.Operator, state.userOperator.statuses)
                    }
                    dispatch(operatorsActions.addActiveOperator(payload))
                    debouncedUpdateSearch()
                } else {
                    logValidationError(OPERATOR_BECAME_ACTIVE, data)
                }
            } catch (e) {
                logHubError(OPERATOR_BECAME_ACTIVE, e)
            }
        })
        this._hub.registerEvent(OPERATOR_BECAME_INACTIVE, (msgStr: string) => {
            try {
                dispatch(operatorsActions.removeInactiveOperator(JSON.parse(msgStr)))
                debouncedUpdateSearch()
            } catch (e) {
                logHubError(OPERATOR_BECAME_INACTIVE, e)
            }
        })
        this._hub.registerEvent(OPERATOR_QUEUES_UPDATED, (msgStr: string) => {
            try {
                dispatch(queuesActions.updateQueueOperators(JSON.parse(msgStr)))
            } catch (e) {
                logHubError(OPERATOR_QUEUES_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_ADDED, (msgStr: string) => {
            try {
                const data = JSON.parse(msgStr)
                if (isQueueAddedMsg(data)) {
                    dispatch(queuesActions.addQueue(data))
                    debouncedUpdateSearch()
                } else {
                    logValidationError(QUEUE_ADDED, data)
                }
            } catch (e) {
                logHubError(QUEUE_ADDED, e)
            }
        })
        this._hub.registerEvent(QUEUE_UPDATED, (msgStr: string) => {
            try {
                dispatch(queuesActions.updateQueue(JSON.parse(msgStr)))
            } catch (e) {
                logHubError(QUEUE_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_SL_UPDATED, (msgStr: string) => {
            try {
                dispatch(queuesActions.updateQueueSl(JSON.parse(msgStr)))
            } catch (e) {
                logHubError(QUEUE_SL_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_AWT_UPDATED, (msgStr: string) => {
            try {
                dispatch(queuesActions.updateQueueAwt(JSON.parse(msgStr)))
            } catch (e) {
                logHubError(QUEUE_AWT_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_OPERATOR_TASKS_COUNT_UPDATED, (msgStr: string) => {
            try {
                dispatch(queuesActions.updateOperatorTasksCount(JSON.parse(msgStr)))
            } catch (e) {
                logHubError(QUEUE_OPERATOR_TASKS_COUNT_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_FINISHED_DIALOGS_UPDATED, (msgStr: string) => {
            try {
                dispatch(queuesActions.updateQueueFinishedDialogs(JSON.parse(msgStr)))
            } catch (e) {
                logHubError(QUEUE_FINISHED_DIALOGS_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_FINISHED_DIALOGS_DAILY_UPDATED, (msgStr: string) => {
            try {
                dispatch(queuesActions.updateQueueFinishedDialogsDaily(JSON.parse(msgStr)))
            } catch (e) {
                logHubError(QUEUE_FINISHED_DIALOGS_DAILY_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_EXTEND_SETTINGS_UPDATED, (msgStr: string) => {
            try {
                const data = JSON.parse(msgStr)
                dispatch(queuesActions.updateQueueExtendedSettings(data.Settings))
            } catch (e) {
                logHubError(QUEUE_EXTEND_SETTINGS_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_CATEGORY_ADDED, (value: string) => {
            try {
                const parsedValue = JSON.parse(value)
                if (isQueueCategory(parsedValue)) {
                    dispatch(queuesActions.createQueueCategorySuccess(parsedValue))
                } else {
                    logValidationError(QUEUE_CATEGORY_ADDED, parsedValue)
                }
            } catch (e) {
                logHubError(QUEUE_CATEGORY_ADDED, e)
            }
        })
        this._hub.registerEvent(QUEUE_CATEGORY_UPDATED, (value: string) => {
            try {
                const parsedValue = JSON.parse(value)
                if (isQueueCategory(parsedValue)) {
                    dispatch(queuesActions.updateQueueCategorySuccess(parsedValue))
                } else {
                    logValidationError(QUEUE_CATEGORY_UPDATED, parsedValue)
                }
            } catch (e) {
                logHubError(QUEUE_CATEGORY_UPDATED, e)
            }
        })
        this._hub.registerEvent(QUEUE_CATEGORY_REMOVED, (value: string) => {
            try {
                const parsedValue = JSON.parse(value)
                if (isQueueCategoryRemoveResponse(parsedValue)) {
                    dispatch(queuesActions.deleteQueueCategorySuccess(parsedValue.CategoryId))
                } else {
                    logValidationError(QUEUE_CATEGORY_REMOVED, parsedValue)
                }
            } catch (e) {
                logHubError(QUEUE_CATEGORY_REMOVED, e)
            }
        })
    }
}

export default QueuesHub
