export interface MessageSender {
    Id: string
}

export interface Message {
    MsgId: string
    MsgType: MessageType
    Direction: MessageDirection
    IdInChannel: string
    ChannelId?: string
    ReplyToMessage?: Message
    Attachment?: MessageAttachment
    Attachments?: MessageAttachment[]
    Sticker?: Sticker
    Text: string
    Actions: Action[]
    ClientId: number
    OperatorId?: number
    OperatorName: string
    CallCenterOperatorId?: string
    DialogID: string
    UserPic?: string
    UserName?: string
    Context: string[][]
    IntentQaId?: string
    Flags: MessageFlags[]
    IsServiceMessage: boolean
    IsClientServiceMessage: boolean
    Added: string
    Meta: Record<string, string>
}

export interface MessageOperator {
    Id: string
    Name: string
}

export enum MessageTextType {
    Text = "Text",
    Markdown = "Markdown"
}

export interface UpdatedMessage {
    Id: string
    MessageType: MessageType
    Direction: MessageDirection
    Sender: MessageSender
    ChannelUserId?: string
    ChannelId: string
    Text: string
    Sticker?: Sticker
    Actions: Action[]
    Attachments: MessageAttachment[]
    DialogId: string
    AddedDate: number
    ReplyToMessage?: UpdatedMessage
    Context: string[][]
    Meta: Record<string, string>
    Status: MessageStatus
    TextType: MessageTextType
}

export enum MessageType {
    Initial = 0,
    Message = 1,
    SentConfirmation = 2,
    ReferenceCreated = 3,
    ReadConfirmation = 4,
    FailedConfirmation = 5,
    UpdateDialogProgram = 6,
    WritingStop = 7,
    Deleted = 8,
    PhoneNumber = 9,
    Location = 10,
    ReceivedByMediator = 11,
    ReceivedByOperator = 12,
    OperatorIsTyping = 13,
    OperatorStoppedTyping = 14,
    UpdateDialogScore = 15,
    FinishDialog = 16,
    CloseDialogIntention = 17,
    ConnectedOperator = 18,
    DialogFinished = 19,
    AutoGreeting = 20,
    UpdateDialogNegativeReason = 21,
    UpdateUserData = 22,
    ClientHold = 23,
    UpdateDialogUsefulness = 24,
    CobrowsingRequest = 100,
    CobrowsingStarted = 101,
    CobrowsingDenied = 102,
    CobrowsingStateUpdate = 103,
    CobrowsingFinished = 104
}

export enum MessageDirection {
    Incoming = 0,
    Outgoing = 1
}

export interface MessageAttachment {
    Url: string
    ContentType: string
    Name: string
    Size: number
}

interface Sticker {
    sticker_id: string
    sticker_url?: string
    animation_type: StickerAnimationType
}

enum StickerAnimationType {
    NoAnimation,
    Lottie
}

enum MessageFlags {
    Unknown = -1,
    Sent = 0,
    Delivered = 1,
    Read = 2,
    Failed = 3,
    Deleted = 4,
    Phone = 5,
    Location = 6,
    AutoGreeting = 7
}

export interface Action {
    action_id: string
    action_text: string
}

// здесь стоит UpdatedMessage
// но на беке есть некий ApiMessage, который отличается
// при создании реплаев это нужно учесть
// https://gitlab.crafttalk.ru/aibotdev/core/opbot/-/blob/develop/src/opbot/Workplace/V2/Dto/Messages.fs#L42
export interface SendMessageRequest {
    OmniUserId: string
    ChannelId: string
    StartNewDialog: boolean
    ReplyToMessage?: UpdatedMessage
    Attachments: MessageAttachment[]
    Sticker?: Sticker
    Text: string
    Actions: Action[]
    Meta: Record<string, string>
}

export interface ISendFinishRequest {
    OmniUserId: string
    ChannelId: string
    ProjectId: string
}

export interface MergedMessageRequest {
    projectId: string
    message: SendMessageRequest
    body: GetUpdatedMessagesRequest
}

export interface ScoreMessage {
    Id: string
    Score: string
}

export interface IdMessage {
    Id: string
}

export type DialogFinishScore = "1" | "2" | "3" | "4" | "5"

export interface DialogFinishMessage {
    Id: string
    DialogId: string
    Timestamp: number
    Score?: DialogFinishScore
}

export interface GetMessagesResponse {
    [dialogId: string]: Message[]
}

export interface GetUpdatedMessagesRequest {
    OmniUserId: string
    Count: number
    StartTime: number
    FromTodayDialogs: boolean
    ProjectId: string
}

export interface ApiMessage {
    Case: "Message"
    Fields: UpdatedMessage
}

export interface ApiScore {
    Case: "Score"
    Fields: ScoreMessage
}

export interface ApiDialogFinish {
    Case: "DialogFinish"
    Fields: DialogFinishMessage
}

export type ApiMessagesDU = ApiMessage | ApiScore | ApiDialogFinish

export interface GetUpdatedMessagesResponse {
    Messages: ApiMessagesDU[]
    HasMore: boolean
}

export enum SenderRole {
    Operator,
    Client
}

export enum SendMessageResponse {
    SentToExistingDialog = 1,
    NewDialogWillBeStarted = 2,
    HasActiveDialogAlready = 3,
    DialogDoesNotExist = 4
}

export interface GetMessageSenderDataRequest {
    SenderRole: SenderRole
    // A client's omniUserId or operator's userId
    Id: string
}

export enum MessageStatus {
    Unknown = 0,
    Sent = 1,
    ReceivedByMediator = 2,
    Delivered = 3,
    Read = 4
}
