import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { searchArticles } from "../../store/knowledgeBase/thunks"
import { ClassProps } from "../../utility/common/props"
import SearchArticlesInputLogic from "./SearchArticlesInputLogic"
import { SearchArticlesRequest } from "../../models/article"

export type SearchArticleInputContainerProps = ClassProps

const SearchArticleInputContainer: React.FC<SearchArticleInputContainerProps> = props => {
    const { className } = props
    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    const handleSearch = useCallback(
        (request: SearchArticlesRequest) => {
            if (projectId) {
                dispatch(searchArticles(projectId, request))
            }
        },
        [projectId, dispatch]
    )

    return <SearchArticlesInputLogic onSearch={handleSearch} className={className} />
}

export default SearchArticleInputContainer
