import { isSelectedOperatorStatusDto, SelectedOperatorStatusDto } from "./operatorStatusDto"
import { isTask, Task } from "./task"
import { isTenantQueues, TenantQueues } from "./operator"

export type OperatorDto = {
    Id: string
    FirstName: string
    LastName: string
    MiddleName?: string
    Status: SelectedOperatorStatusDto
    MaxCapacity: number
    Capacity: number
    AssignmentWebHook?: string
    RoutingWebHook?: string
    TenantsQueues: TenantQueues[]
    Tasks: Task[]
}

export const isOperatorDto = (value: unknown): value is OperatorDto => {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const operator = value as Partial<OperatorDto>

    return (
        typeof operator.Id === "string" &&
        typeof operator.FirstName === "string" &&
        typeof operator.LastName === "string" &&
        (typeof operator.MiddleName === "undefined" || typeof operator.MiddleName === "string") &&
        isSelectedOperatorStatusDto(operator.Status) &&
        typeof operator.MaxCapacity === "number" &&
        typeof operator.Capacity === "number" &&
        (typeof operator.AssignmentWebHook === "undefined" || typeof operator.AssignmentWebHook === "string") &&
        (typeof operator.RoutingWebHook === "undefined" || typeof operator.RoutingWebHook === "string") &&
        Array.isArray(operator.TenantsQueues) &&
        operator.TenantsQueues.every(isTenantQueues) &&
        Array.isArray(operator.Tasks) &&
        operator.Tasks.every(isTask)
    )
}
