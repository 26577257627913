import { prettyJson } from "../../helpers/json"
import { ParamValues } from "../../models/parameterDeclaration"
import {
    AppearanceValues,
    AuthAdvancedValues,
    AuthSettingsValues,
    AuthType,
    BlockValues,
    DialogEvaluationValues,
    ElementType,
    FileValues,
    HistoryValues,
    IFrameMode,
    InitialMessageMode,
    InitialMessageValues,
    InterfaceValues,
    MessageValues,
    OperatorValues,
    PromoValues,
    PushMessageValues,
    SyncValues,
    SystemValues,
    TransportType
} from "../../models/webChatValues"
import { parseField } from "../common/parametersTranslator"
import { Time } from "../common/time"

export const getAppearanceSettings = (clientConfig: ParamValues): AppearanceValues => ({
    headerText: clientConfig.headerText,
    headerDescription: clientConfig.headerDescription,
    baseColor: clientConfig.baseColor,
    textColor: clientConfig.textColor || "null",
    language: clientConfig.language
})

export const getAuthSettingsValues = (mainConfig: ParamValues): AuthSettingsValues => {
    const requireAuthFields = parseField(mainConfig.requireAuthFields, [])
    return {
        type: !mainConfig.requireAuth
            ? AuthType.Anonymously
            : mainConfig.requireCallbackAuth
            ? AuthType.JavaScriptAuthorization
            : AuthType.RequireAuthFields,
        useHash: mainConfig.useHash,
        hashedFields: parseField(mainConfig.hashedFields, []),
        isShownSalt: mainConfig.isShownSalt,
        salt: mainConfig.salt,
        requireFirstnameField: requireAuthFields.includes("first_name"),
        requireLastnameField: requireAuthFields.includes("last_name"),
        requirePhoneField: requireAuthFields.includes("phone"),
        requireWebhookAuth: mainConfig.requireWebhookAuth,
        webhookAuthorizationUrl: mainConfig.webhookAuthorizationUrl
    }
}

export const getAuthSettings = (authSettings: AuthSettingsValues) => {
    switch (authSettings.type) {
        case AuthType.Anonymously:
            return {
                requireAuth: false,
                requireCallbackAuth: false,
                authorizeWithoutClientData: true
            }
        case AuthType.RequireAuthFields:
            const requireAuthFields: string[] = []

            if (authSettings.requireFirstnameField) requireAuthFields.push("first_name")
            if (authSettings.requireLastnameField) requireAuthFields.push("last_name")
            if (authSettings.requirePhoneField) requireAuthFields.push("phone")

            return {
                requireAuth: true,
                requireCallbackAuth: false,
                authorizeWithoutClientData: false,
                requireAuthFields: requireAuthFields
            }
        case AuthType.JavaScriptAuthorization:
            return {
                requireAuth: true,
                requireCallbackAuth: true,
                authorizeWithoutClientData: false,
                useHash: authSettings.useHash,
                hashedFields: authSettings.hashedFields,
                salt: authSettings.useHash ? authSettings.salt : "",
                isShownSalt: authSettings.isShownSalt,
                requireWebhookAuth: authSettings.requireWebhookAuth,
                webhookAuthorizationUrl: authSettings.webhookAuthorizationUrl
            }
    }
}

export const isMobileVersionEnabled = (clientConfig: ParamValues): boolean =>
    clientConfig.alwaysOpen && clientConfig.disableClosing && clientConfig.disableHeader

export const getSyncSettings = (mainConfig: ParamValues): SyncValues => ({
    enabled: mainConfig.mediatorId !== mainConfig.namespace,
    mediatorId: mainConfig.mediatorId,
    namespace: mainConfig.namespace,
    namespaceCobrowsing: mainConfig.namespaceCobrowsing
})

export const getHistorySettings = (mainConfig: ParamValues, clientConfig: ParamValues): HistoryValues => ({
    showHistory: clientConfig.showHistory,
    historyMessageCount: mainConfig.historyMessageCount,
    historyFromActiveDialog: mainConfig.historyFromActiveDialog
})

export const getPushMessageSettings = (mainConfig: ParamValues, clientConfig: ParamValues): PushMessageValues => ({
    notificationNewMessages: clientConfig.notificationNewMessages,
    pushMessageTitle: clientConfig.pushMessageTitle,
    pushMessageBody: clientConfig.pushMessageBody,
    pushMessageBodyFromMessageText: clientConfig.pushMessageBodyFromMessageText,
    pushMessageIcon: clientConfig.pushMessageIcon,
    pushMessageTag: clientConfig.pushMessageTag,
    pushMessageTimeout: Time.msToSeconds(clientConfig.pushMessageTimeout ?? 0),
    swPublicKey: clientConfig.swPublicKey,
    externalPushServiceUrl: mainConfig.externalPushServiceUrl,
    subscriptionRequestDelay: Time.msToHours(clientConfig.subscriptionRequestDelay ?? 0)
})

export const getOperatorSettings = (clientConfig: ParamValues): OperatorValues => ({
    operatorIsTypingTimeout: clientConfig.operatorIsTypingTimeout,
    showConnectedOperatorNotify: clientConfig.showConnectedOperatorNotify,
    showOperatorName: clientConfig.showOperatorName,
    operatorNameTemplate: clientConfig.operatorNameTemplate,
    showFinishDialogByOperatorMessage: clientConfig.showFinishDialogByOperatorMessage,
    disableOperatorImage: clientConfig.disableOperatorImage
})

export const getInterfaceSettings = (clientConfig: ParamValues): InterfaceValues => ({
    disableClosing: clientConfig.disableClosing,
    disableHeader: clientConfig.disableHeader,
    disableCompanyInformation: clientConfig.disableCompanyInformation,
    useEmoji: clientConfig.useEmoji,
    requireChatDraggable: clientConfig.requireChatDraggable,
    requireChatResizing: clientConfig.requireChatResizing,
    logoColor: clientConfig.logoColor,
    alwaysOpen: clientConfig.alwaysOpen,
    disableInitialOpen: clientConfig.disableInitialOpen,
    clickablePhoneNumber: clientConfig.clickablePhoneNumber
})

export const getPromoSettings = (clientConfig: ParamValues): PromoValues => ({
    showPromo: clientConfig.showPromo,
    promoTimeoutMin: Time.msToMinutes(clientConfig.promoTimeout ?? 0),
    promoTimeoutSec: Time.msToSeconds(clientConfig.promoTimeout ?? 0) % 60,
    promoWidth: clientConfig.promoWidth ?? 0,
    promoHeight: clientConfig.promoHeight ?? 0,
    promoAllowedUrls: parseField(clientConfig.promoAllowedUrls, []),
    promoWelcomeMessage: clientConfig.promoWelcomeMessage
})

export const getPromoSettingsRequest = (values: PromoValues): ParamValues => ({
    showPromo: values.showPromo,
    promoWelcomeMessage: values.promoWelcomeMessage,
    promoWidth: values.promoWidth ?? 0,
    promoHeight: values.promoHeight ?? 0,
    promoTimeout: Time.minutesToMs(values.promoTimeoutMin ?? 0) + Time.secondsToMs(values.promoTimeoutSec ?? 0),
    promoAllowedUrls: JSON.stringify(values.promoAllowedUrls)
})

const getInitialMessageMode = (clientConfig: ParamValues): InitialMessageMode => {
    if (clientConfig.sendInitialMessageOnOpen) {
        return InitialMessageMode.sendInitialMessageOnOpen
    }
    if (clientConfig.sendInitialMessageOnStartDialog) {
        return InitialMessageMode.sendInitialMessageOnStartDialog
    }
    return InitialMessageMode.showInitialMessage
}

export const getInitialMessageSettings = (clientConfig: ParamValues): InitialMessageValues => ({
    mode: getInitialMessageMode(clientConfig),
    initialMessageOperatorName: clientConfig.initialMessageOperatorName,
    initialMessageText: clientConfig.initialMessageText,
    getInitialMessageFromWebhook: clientConfig.getInitialMessageFromWebhook,
    initialMessageWebhook: clientConfig.initialMessageWebhook
})
export const getInitialMessageSettingsRequest = (values: InitialMessageValues): ParamValues => {
    const params = {
        sendInitialMessageOnOpen: false,
        sendInitialMessageOnStartDialog: false,
        showInitialMessage: false,
        initialMessageOperatorName: values.initialMessageOperatorName,
        initialMessageText: values.initialMessageText,
        getInitialMessageFromWebhook: values.getInitialMessageFromWebhook,
        initialMessageWebhook: values.initialMessageWebhook
    }
    switch (values.mode) {
        case InitialMessageMode.sendInitialMessageOnOpen:
            return { ...params, sendInitialMessageOnOpen: true }
        case InitialMessageMode.sendInitialMessageOnStartDialog:
            return { ...params, sendInitialMessageOnStartDialog: true }
        case InitialMessageMode.showInitialMessage:
            return { ...params, showInitialMessage: true }
    }
}

export const getAuthAdvancedSettings = (mainConfig: ParamValues, clientConfig: ParamValues): AuthAdvancedValues => {
    let userFormFields = parseField(clientConfig.userFormFields, [])

    try {
        userFormFields = prettyJson(userFormFields)
    } catch (e) {}

    return {
        userFormFields,
        phoneNumberMask: clientConfig.phoneNumberMask,
        allowedSymbolsRegexp: clientConfig.allowedSymbolsRegexp,
        clearNonValidUserData: clientConfig.clearNonValidUserData,
        requireAuthData: parseField(mainConfig.requireAuthData, []),
        usePersonalDataAgreement: clientConfig.usePersonalDataAgreement ?? false,
        personalDataText: clientConfig.personalDataText,
        authButtonText: clientConfig.authButtonText
    }
}

export const getFileSettings = (mainConfig: ParamValues, clientConfig: ParamValues): FileValues => {
    const uploadSizeLimitValue = clientConfig.uploadSizeLimit.match(/\d+/)
    const uploadSizeLimitUnit = clientConfig.uploadSizeLimit.match(/\D+/)

    return {
        showImageDownloadLink: clientConfig.showImageDownloadLink,
        allowedFileNameRegex: mainConfig.allowedFileNameRegex,
        fileRegexErrorMessage: clientConfig.fileRegexErrorMessage,
        oversizedFileErrorMessage: clientConfig.oversizedFileErrorMessage,
        uploadSizeLimitValue: uploadSizeLimitValue ? uploadSizeLimitValue[0] : "",
        uploadSizeLimitUnit: uploadSizeLimitUnit ? uploadSizeLimitUnit[0] : "",
        useCaptcha: clientConfig.useCaptcha,
        captchaSecretKey: mainConfig.captchaSecretKey ? mainConfig.captchaSecretKey : "",
        captchaSiteKey: clientConfig.captchaSiteKey ? clientConfig.captchaSiteKey : "",
        captchaFileCount: mainConfig.captchaFileCount ? mainConfig.captchaFileCount : "",
        securedFileLinks: clientConfig.securedFileLinks === "true" ? true : false
    }
}

export const getDialogEvaluationSettings = (clientConfig: ParamValues): DialogEvaluationValues => {
    const ratingAliases = parseField(clientConfig.ratingAliases, [])
    const ratingAliasesEnabled = ratingAliases?.length === 5

    return {
        ratingAnswerText: clientConfig.ratingAnswerText,
        showRatingBox: clientConfig.showRatingBox,
        requireScoreRequest: clientConfig.requireScoreRequest,
        questionBeforeRatingDialog: clientConfig.questionBeforeRatingDialog,
        ratingAliasesEnabled,
        ratingAliases: ratingAliasesEnabled ? ratingAliases : Array(5).fill(""),
        requireDialogFinishWithEstimate: clientConfig.requireDialogFinishWithEstimate
    }
}

export const getBlockSettings = (params: ParamValues, clientConfig: ParamValues): BlockValues => ({
    blockMessage: clientConfig.blockMessage
})

export const getElement = (value: string, type: ElementType): string => {
    if (!value || !type) return ""

    switch (type) {
        case ElementType.id:
            return "#" + value
        case ElementType.class:
            return "." + value
        case ElementType.tag:
        case ElementType.custom:
            return value
    }
}

const defineElementType = (element: string): ElementType => {
    if (element.startsWith("#")) {
        return ElementType.id
    } else if (element.startsWith(".") || !element) {
        return ElementType.class
    } else if (/^[A-Za-z]+$/.test(element)) {
        return ElementType.tag
    }
    return ElementType.custom
}

export const getSystemSettings = (
    params: ParamValues,
    mainConfig: ParamValues,
    clientConfig: ParamValues
): SystemValues => {
    const iFrameMode =
        clientConfig.iFrameMode === IFrameMode.disabled || clientConfig.iFrameMode === IFrameMode.insecure
            ? clientConfig.iFrameMode
            : IFrameMode.specifiedHost
    const customTransport = parseField(clientConfig.customTransport, [])

    return {
        socketIOInitialLoad: clientConfig.socketIOInitialLoad,
        cobrowsingEnabled: clientConfig.cobrowsingEnabled,
        bottomPlacement: clientConfig.bottomPlacement,
        enableMobileVersion: clientConfig.enableMobileVersion,
        rootElementValue: clientConfig.rootElement.replace(/^[#.]/, ""),
        rootElementType: defineElementType(clientConfig.rootElement),
        openChatButtonSelectorValue: clientConfig.openChatButtonSelector.replace(/^[#.]/, ""),
        openChatButtonSelectorType: defineElementType(clientConfig.openChatButtonSelector),
        customCss: clientConfig.customCss,
        iFrameMode,
        iFrameHost: iFrameMode === IFrameMode.specifiedHost ? clientConfig.iFrameMode : "",
        debug: mainConfig.debug,
        customTransport: customTransport[0] ?? TransportType.websocket,
        chatAnnouncement: clientConfig.chatAnnouncement,
        useSentry: clientConfig.useSentry
    }
}

export const getMessageSettings = (params: ParamValues, clientConfig: ParamValues): MessageValues => ({
    sendUserIsTyping: clientConfig.sendUserIsTyping,
    userTypingInterval: Time.msToSeconds(clientConfig.userTypingInterval),
    forwardMessage: clientConfig.forwardMessage,
    showClientServiceMessage: clientConfig.showClientServiceMessage,
    linkOpeningBehaviour: clientConfig.linkOpeningBehaviour,
    locationMessage: clientConfig.locationMessage
})
