import { ProjectSettings } from "../../../models/projectSettings"
import { SystemSettingsValues } from "../../../models/projectSettingsValues"

export const defaultSystemProjectSettings = {
    Security: {
        InformationMaskingPatterns: {
            Values: []
        }
    },
    System: {
        UpdateClientDataFromChannel: true,
        ChannelOnDialogPanel: false,
        OperatorQuestionaryId: "",
        DialogSwitchNotificationWebhook: "",
        SwitchToBotWindowTitle: "",
        SwitchToBotWindowMessage: "",
        DisableMarkupModal: true,
        KnowledgeBaseConvertTemplate: {
            FileName: "",
            FileUrl: ""
        },
        EnableMessageSendBehaviour: false,
        AdditionalResendConfigDependsOnQueue: false
    }
}

export const getSystemSettingsInitialValues = (settings: ProjectSettings): SystemSettingsValues => ({
    Security: { ...defaultSystemProjectSettings.Security, ...settings.Security },
    System: { ...defaultSystemProjectSettings.System, ...settings.System }
})

export const buildUpdateSystemSettingsRequest = (values: SystemSettingsValues): ProjectSettings => {
    return {
        Security: {
            InformationMaskingPatterns: {
                Values: values.Security.InformationMaskingPatterns.Values.filter(Boolean)
            }
        },
        System: values.System
    }
}
