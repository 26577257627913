import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { DialogBadge } from "../../models/Dialogs/dialog"
import { GetUpdatedMessagesRequest, UpdatedMessage } from "../../models/Dialogs/message"
import { SystemError } from "../../core/error"
import { LegacyDialog } from "../../models/Dialogs/legacyDialog"
import { SurveyForm } from "../../models/Dialogs/surveyForm"
import { dialogsApi } from "../../api/controllers/dialogs"

export type DialogsSlice = Readonly<{
    operatorDialogs: AsyncState<DialogBadge[]>
    current: AsyncState<LegacyDialog>
    messages: AsyncState<UpdatedMessage[]>
    survey: AsyncState<SurveyForm>
    selectedDialogId?: string
    selectedOperatorClientId?: string
    messagesPaginationState?: GetUpdatedMessagesRequest
    dialogBadgesOriginal: DialogBadge[]
    incomingDialogBadgesOnSearchMode: DialogBadge[]
    searchCriterion?: string
}>

const initialState: DialogsSlice = {
    operatorDialogs: AsyncState.create(),
    current: AsyncState.create(),
    messages: AsyncState.create(),
    survey: AsyncState.create(),
    incomingDialogBadgesOnSearchMode: [],
    dialogBadgesOriginal: []
}

const dialogs = createSlice({
    name: "dialogs",
    initialState,
    reducers: {
        getOperatorDialogsProcess(state) {
            state.operatorDialogs = state.operatorDialogs.toProcess()
        },
        getOperatorDialogsSuccess(state, action: PayloadAction<DialogBadge[]>) {
            state.operatorDialogs = state.operatorDialogs.toSuccess(action.payload)
        },
        getOperatorDialogsFailed(state, action: PayloadAction<SystemError>) {
            state.operatorDialogs = state.operatorDialogs.toFailed(action.payload)
        },
        getCurrentDialogProcess(state) {
            state.current = state.current.toProcess()
        },
        getCurrentDialogSuccess(state, action: PayloadAction<LegacyDialog>) {
            state.current = state.current.toSuccess(action.payload)
        },
        getCurrentDialogFailed(state, action: PayloadAction<SystemError>) {
            state.current = state.current.toFailed(action.payload)
        },
        getDialogMessagesProcess(state) {
            state.messages = state.messages.toProcess()
        },
        getDialogMessagesSuccess(state, action: PayloadAction<UpdatedMessage[]>) {
            state.messages = state.messages.toSuccess(action.payload)
        },
        getDialogMessagesFailed(state, action: PayloadAction<SystemError>) {
            state.messages = state.messages.toFailed(action.payload)
        },
        setCurrentOperatorClientId(state, action: PayloadAction<string>) {
            state.selectedOperatorClientId = action.payload
        },
        unsetCurrentOperatorClientId(state) {
            state.selectedOperatorClientId = undefined
        },
        setCurrentDialogId(state, action: PayloadAction<string>) {
            state.selectedDialogId = action.payload
        },
        unsetCurrentDialogId(state) {
            state.selectedDialogId = undefined
        },
        setSearchCriterion(state, action: PayloadAction<string>) {
            state.searchCriterion = action.payload
        },
        setIncomingDialogBadgesOnSearchMode(state, action: PayloadAction<DialogBadge[]>) {
            state.incomingDialogBadgesOnSearchMode = action.payload
        },
        unsetIncomingDialogBadgesOnSearchMode(state) {
            state.incomingDialogBadgesOnSearchMode = []
        },
        setDialogBadgesOriginal(state, action: PayloadAction<DialogBadge[]>) {
            state.dialogBadgesOriginal = action.payload
        },
        unsetDialogBadgesOriginal(state) {
            state.dialogBadgesOriginal = []
        },
        getClientSurveyProcess(state) {
            state.survey = state.survey.toProcess()
        },
        getClientSurveySuccess(state, action: PayloadAction<SurveyForm>) {
            state.survey = state.survey.toSuccess(action.payload)
        },
        getClientSurveyFailed(state, action: PayloadAction<SystemError>) {
            state.survey = state.survey.toFailed(action.payload)
        },
        setMessagesPaginationState(state, action: PayloadAction<Partial<GetUpdatedMessagesRequest>>) {
            const newState = {
                ...state.messagesPaginationState,
                ...action.payload
            } as GetUpdatedMessagesRequest
            state.messagesPaginationState = newState
        }
    },
    extraReducers: builder => {
        if (!dialogsApi?.endpoints) {
            return
        }

        builder.addMatcher(dialogsApi.endpoints.getDialogBadges.matchFulfilled, (state, { payload, ...p }) => {
            if (!p.meta.baseQueryMeta) {
                return
            }

            state.dialogBadgesOriginal = payload
        })
    }
})

export default dialogs.reducer
export const { actions } = dialogs
