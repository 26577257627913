export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(
    myEnum: T,
    enumValue: string
): keyof T | null => {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue)
    return keys.length > 0 ? keys[0] : null
}

export const getEnumByEnumValueAsIndexed = <T extends { [index: number]: string }>(
    myEnum: T,
    enumValue: string
): keyof T | null => {
    const entity = Object.entries(myEnum)
        .map<[number, string]>(([k, v]) => [Number(k), v])
        .find(([x]) => myEnum[x] === enumValue)

    return entity ? entity[0] : null
}

export const getEnumByEnumValueAsAssotiative = <T extends { [index: string]: string }>(
    myEnum: T,
    enumValue: string
): keyof T | null => {
    const entity = Object.entries(myEnum).find(([x]) => myEnum[x] === enumValue)
    return entity ? entity[0] : null
}
