import { Formik, FormikProps } from "formik"
import { WithT } from "i18next"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Queue } from "../../models/queue"
import { isTask, MoveTaskRequest, TaskCommon } from "../../models/task"
import { selectMoveTaskState } from "../../store/tasks/selectors"
import { nameof } from "../../utility/common/nameof"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import * as Yup from "yup"
import { moveTask } from "../../store/tasks/thunks"
import { ClassProps } from "../../utility/common/props"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { OperatorSummary } from "../../models/operator"
import RouteTaskFormBase from "../RouteTaskFormBase/RouteTaskFormBase"

const tNamespace = "task:form."

enum RouteType {
    ToQueue = "ToQueue",
    ToOperator = "ToOperator"
}

interface RouteTaskValues {
    routeType: RouteType
    queueId: string
    operatorId: string
    operatorQueueId: string
}

interface Props extends ClassProps {
    task: TaskCommon
    queues: Queue[]
    operators: OperatorSummary[]
    onCancel: () => void
    onAfterRoute: () => void
}

const defaultValues = (): RouteTaskValues => ({
    routeType: RouteType.ToQueue,
    queueId: "",
    operatorId: "",
    operatorQueueId: ""
})

const getQueues = (ids: string[], queues: Queue[]) => {
    return queues.filter(v => ids.findIndex(i => v.Id === i) !== -1)
}

const getRemainingQueues = (currentQueueId: string | undefined, queues: Queue[]) => {
    return queues.filter(v => v.Id !== currentQueueId)
}

const FormikRouteTaskForm: React.FC<Props & FormikProps<RouteTaskValues> & WithT> = props => {
    const { className, t, setFieldValue, values, task, queues, operators, onCancel } = props

    const remainingQueues = useMemo(
        () => (isTask(task) ? getRemainingQueues(task.QueueId, queues) : queues),
        [task, queues]
    )

    const [operatorQueues, setOperatorQueues] = useState<Queue[]>([])
    useEffect(() => {
        if (!values.operatorId) return

        setFieldValue(nameof<RouteTaskValues>("operatorQueueId"), "", false)
        const operator = operators.find(v => v.Id === values.operatorId)
        setOperatorQueues(operator?.Queues ? getQueues(operator.Queues, queues) : [])
    }, [operators, queues, setFieldValue, values.operatorId])

    const toQueueSelected = values.routeType === RouteType.ToQueue
    const toOperatorSelected = values.routeType === RouteType.ToOperator

    const moveTaskState = useSelector(selectMoveTaskState)

    return (
        <RouteTaskFormBase state={moveTaskState} t={t} onCancel={onCancel} className={className}>
            <div className="route-task-form__option">
                <RadioButtonValidatableInput
                    value={RouteType.ToQueue}
                    id="routeType1"
                    name={nameof<RouteTaskValues>("routeType")}
                    label={t(`${tNamespace}to-queue`)}
                />
                <ValidatableInput
                    id="formTitle"
                    as="select"
                    type="text"
                    name={nameof<RouteTaskValues>("queueId")}
                    disabled={!toQueueSelected}
                >
                    <option hidden value="">
                        {t(`${tNamespace}select-queue`)}
                    </option>
                    {remainingQueues.map(q => (
                        <option value={q.Id} key={q.Id}>
                            {q.Name}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
            <div className="route-task-form__option">
                <RadioButtonValidatableInput
                    value={RouteType.ToOperator}
                    id="routeType2"
                    name={nameof<RouteTaskValues>("routeType")}
                    label={t(`${tNamespace}to-operator`)}
                />
                <ValidatableInput
                    id="formTitle"
                    as="select"
                    type="text"
                    name={nameof<RouteTaskValues>("operatorId")}
                    disabled={!toOperatorSelected}
                >
                    <option hidden value="">
                        {t(`${tNamespace}select-operator`)}
                    </option>
                    {operators.map(v => (
                        <option value={v.Id} key={v.Id}>{`${v.FirstName} ${v.LastName}`}</option>
                    ))}
                </ValidatableInput>
                <ValidatableInput
                    id="formTitle"
                    as="select"
                    type="text"
                    name={nameof<RouteTaskValues>("operatorQueueId")}
                    disabled={!toOperatorSelected || operatorQueues.length === 0}
                    label={t(`${tNamespace}to-operator-queue`)}
                >
                    <option hidden value="">
                        {t(`${tNamespace}select-queue`)}
                    </option>
                    {operatorQueues.map(q => (
                        <option value={q.Id} key={q.Id}>
                            {q.Name}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
        </RouteTaskFormBase>
    )
}

const createMoveRequest = (task: TaskCommon, values: RouteTaskValues): MoveTaskRequest => {
    switch (values.routeType) {
        case RouteType.ToQueue:
            return { TaskId: task.Id, QueueId: values.queueId }
        case RouteType.ToOperator:
            return {
                TaskId: task.Id,
                QueueId: values.operatorQueueId,
                OperatorId: values.operatorId
            }
    }
}

const RouteTaskForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { task, onAfterRoute } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={defaultValues()}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={() => {
                return Yup.object().shape({
                    queueId: Yup.string().when("routeType", {
                        is: RouteType.ToQueue,
                        then: Yup.string().requiredExcludeEmpty(`${tNamespace}queue-required`)
                    }),
                    operatorId: Yup.string().when("routeType", {
                        is: RouteType.ToOperator,
                        then: Yup.string().requiredExcludeEmpty(`${tNamespace}operator-required`)
                    }),
                    operatorQueueId: Yup.string().when("routeType", {
                        is: RouteType.ToOperator,
                        then: Yup.string().requiredExcludeEmpty(`${tNamespace}queue-required`)
                    })
                })
            }}
            onSubmit={(values: RouteTaskValues) => {
                dispatch(moveTask(task.TenantId, createMoveRequest(task, values), onAfterRoute))
            }}
        >
            {formikProps => <FormikRouteTaskForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default RouteTaskForm
