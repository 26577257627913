import React, { useContext, useCallback, useEffect, useRef, useState } from "react"
import "./ProjectUsers.scss"
import { useTranslation } from "react-i18next"
import { CellProps, Column } from "react-table"
import SettingsButton from "../SettingsButton/SettingsButton"
import UserCard from "../UserCard/UserCard"
import { formTranslation } from "../../locales/form"
import { Role } from "../../models/role"
import { Admin, InviteUsersToProject, ModifyUsers } from "../../permissions"
import { ProjectUser } from "../../models/projectUser"
import Can from "../Can/Can"
import { faBan, faPen } from "@fortawesome/pro-light-svg-icons"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import AddButton from "../AddButton/AddButton"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import { testId } from "../../utility/tests/testId"
import ConfigContext from "../ConfigContext/ConfigContext"
import SettingsTableWithPagination from "../SettingsTable/SettingsTableWithPagination/SettingsTableWithPagination"
import { invitePath } from "../../routerPaths"
import { useParams } from "react-router-dom"
import { FormPathParams } from "../../utility/common/urlFormHelper"
import { FormChangeCallback } from "../../pages/ProjectSettings/ProjectSettings"
import { removeFirstSlash } from "../../utility/common/urlHelper"
import { ITEMS_PER_PAGE } from "../../models/pagination"
import { getSystemErrorMessage } from "../../core/error"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import SearchInput from "../SearchInput/SearchInput"
import { useDispatch, useSelector } from "react-redux"
import { selectProjectUsersState } from "../../store/users/selectors"
import { getUsersByProject } from "../../store/users/thunks"

const tProjectsNamespace = "project:"

interface Props {
    projectId: string
    title: string
    users?: ProjectUser[]
    roles: Role[]
    onInviteClick: (roles: Role[]) => void
    onSettingsClick: (user: ProjectUser) => void
    onBlockClick: (user: ProjectUser) => void
    onFormChange: FormChangeCallback
}

const ProjectUsers: React.FC<Props> = props => {
    const { projectId, title, roles, onInviteClick, onSettingsClick, onBlockClick, onFormChange } = props
    const pageState = useSelector(selectProjectUsersState)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        dispatch(
            getUsersByProject(projectId, {
                PageFrom: currentPageIndex,
                PageSize: ITEMS_PER_PAGE,
                Search: searchValue
            })
        )
    }, [searchValue, currentPageIndex, projectId, dispatch])

    const handlePageChange = useCallback((pageIndex: number, searchValue = "") => {
        setCurrentPageIndex(pageIndex)
        setSearchValue(searchValue)
    }, [])

    const {
        WebConfig: {
            appSettings: { signUpEnabled }
        }
    } = useContext(ConfigContext)

    const {
        current: { formId }
    } = useRef(useParams<FormPathParams>())

    const [userToBlock, setUserToBlock] = useState<ProjectUser | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tProjectsNamespace}block-user-confirmation.title`)}
            message={t(`${tProjectsNamespace}block-user-confirmation.message`)}
            submitBtnText={t(formTranslation.block)}
            onClose={closeModal}
            onSubmit={() => {
                userToBlock && onBlockClick(userToBlock)
            }}
            variant="danger"
            onExited={onExited}
        />
    ))

    const getItems = (user: ProjectUser) => {
        return (
            <>
                <Can permission={ModifyUsers}>
                    <SettingsContextMenuItem
                        id="project-user-edit"
                        icon={faPen}
                        text={t(formTranslation.edit)}
                        onClick={() => onSettingsClick(user)}
                    />
                </Can>
                <Can permission={Admin}>
                    <SettingsContextMenuItem
                        id="project-user-block"
                        icon={faBan}
                        text={t(formTranslation.block)}
                        useSeparator={true}
                        danger={true}
                        onClick={() => {
                            setUserToBlock(user)
                            openModal()
                        }}
                    />
                </Can>
            </>
        )
    }

    const columns: Array<Column<ProjectUser>> = [
        {
            Header: t(formTranslation.user).toString(),
            id: "User",
            accessor: original => original.FirstName + " " + original.LastName,
            Cell: ({ row: { original } }: CellProps<ProjectUser>) => (
                <UserCard firstName={original.FirstName} lastName={original.LastName} picture={original.Picture} />
            )
        },
        {
            Header: t(formTranslation.group).toString(),
            id: "Group",
            accessor: original => {
                const currentRole = roles.find(r => r.Id === original.ProjectSettings.CurrentRoleId)
                return currentRole && currentRole.Title
            }
        },
        {
            Header: t(formTranslation.login).toString(),
            accessor: "Login"
        },
        {
            id: "Settings",
            disableSortBy: true,
            width: "50px",
            Cell: (props: CellProps<ProjectUser>) => (
                <Can permission={ModifyUsers}>
                    <SettingsContextMenu items={getItems(props.row.original)}>
                        <SettingsButton />
                    </SettingsContextMenu>
                </Can>
            )
        }
    ]

    const handleInviteClick = useCallback(() => onInviteClick(roles), [onInviteClick, roles])

    useEffect(() => {
        if (formId === removeFirstSlash(invitePath)) {
            handleInviteClick()
        }
    }, [formId, handleInviteClick])

    if (pageState.error) {
        return <ErrorMessage text={getSystemErrorMessage(pageState.error, t)} />
    }

    return (
        <Can permission={ModifyUsers}>
            {(canModify: boolean) => (
                <div className="project-users">
                    <div className="project-users__header">
                        <span className="project-users__title">{title}</span>
                        {signUpEnabled && (
                            <Can permission={InviteUsersToProject}>
                                <AddButton
                                    variant="outline-primary"
                                    className="project-users__btn-add"
                                    text={t(`${tProjectsNamespace}invite-users`)}
                                    onClick={() => {
                                        handleInviteClick()
                                        onFormChange(invitePath)
                                    }}
                                    data-test-id={testId.inviteUsers}
                                />
                            </Can>
                        )}
                    </div>
                    <SearchInput onChange={searchValue => handlePageChange(0, searchValue)} />
                    <SettingsTableWithPagination
                        columns={columns}
                        data={pageState.data ? pageState.data.Items : []}
                        recordsTotal={pageState.data ? pageState.data.ItemsTotal : 0}
                        pageSize={ITEMS_PER_PAGE}
                        pageIndex={currentPageIndex}
                        onRowClick={canModify ? onSettingsClick : undefined}
                        onPageIndexChange={handlePageChange}
                        hideSearch
                    />
                </div>
            )}
        </Can>
    )
}

export default ProjectUsers
