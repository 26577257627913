export const getFrameUrl = () => {
    return window.origin + `/clients/cloud`
}

export interface RouteMessage {
    menuId?: string
    language?: string
}

export const convertPathToOldFormat = (path: string) => {
    switch (path) {
        case "dialogs-old":
            return "dialogs"
        case "history":
            return "dialogs-search"
        case "knowledge-base-old":
            return "knowledgebase-editor"
        case "stats":
            return "new-stats"
        default:
            return path
    }
}

export const getChangeRouteMessage = (menuId: string): RouteMessage => {
    return { menuId: convertPathToOldFormat(menuId) }
}

export const getLanguageMessage = (language: string): RouteMessage => {
    return { language }
}
