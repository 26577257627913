import React from "react"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import styles from "./Tag.module.scss"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"

export interface TagProps {
    className?: string
    title: string
    index: number
    onDelete?: (index: number) => void
    isInvalid?: boolean
    disabled?: boolean
}

const titleMaxLength = 12
const tooltipDelay = { show: 300, hide: 0 }

const Tag: React.FC<TagProps> = props => {
    const { className, title, index, onDelete, isInvalid, disabled } = props

    return (
        <div
            className={cn(
                styles.tag,
                isInvalid && styles["tag_is-invalid"],
                disabled && styles.tag_disabled,
                className
            )}
        >
            <TooltipTrigger
                id="tag-tooltip"
                placement="top"
                content={title}
                condition={title.length >= titleMaxLength}
                delay={tooltipDelay}
            >
                <div className={styles.tag__title}>{title}</div>
            </TooltipTrigger>
            {onDelete && (
                <div className={styles.tag__close} onClick={() => onDelete(index)}>
                    <FontAwesomeIcon size="sm" icon={faTimes} className={styles.tag__icon} />
                </div>
            )}
        </div>
    )
}

export default Tag
