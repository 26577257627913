import React, { RefObject, useContext } from "react"
import { Article as KnowledgeBaseArticle, ArticleEditFormValues, ArticleStatus as Status } from "../../models/article"
import { useTranslation } from "react-i18next"
import { Scenario } from "../../models/scenario"
import { graphToJson } from "../../utility/scenario/scenario"
import ScenarioEditor from "../ScenarioEditor/ScenarioEditor"
import "./ScenarioArticle.scss"
import { ScenarioContext } from "../ScenarioEditor/ScenarioContext"
import ArticleEditForm from "../ArticleEditForm/ArticleEditForm"
import { ArticleType } from "../ArticleType/ArticleType"
import { ArticleTags } from "../ArticleTags/ArticleTags"
import ArticleUserViewsPopoverContainer from "../ArticleUserViewsPopover/ArticleUserViewsPopoverContainer"
import Article from "../Article/Article"
import ArticleStatus from "../ArticleStatus/ArticleStatus"
import ArticleCommentsViewContainer from "../ArticleCommentsView/ArticleCommentsViewContainer"
import { FormikProps } from "formik/dist/types"
import { ReactFlowProvider } from "react-flow-renderer"
import { useSelector } from "react-redux"
import { selectArticleExpanded, selectArticleFormQuestions } from "../../store/knowledgeBase/selectors"
import ArticleToolbar from "../ArticleToolbar/ArticleToolbar"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"
import CurrentBranchContainer from "../CurrentBranch/CurrentBranchContainer"
import { TreeItem } from "@atlaskit/tree/types"

const tNamespace = "knowledgeBase:"

export interface Props {
    article: KnowledgeBaseArticle
    formRef?: RefObject<FormikProps<ArticleEditFormValues>>
    isEdit: boolean
    canEdit: boolean
    transformToScenario: boolean
    onUpdate?: (articleFormValues: ArticleEditFormValues, scenario: Scenario, versionId: string) => void
    onRelatedArticleClick: (category: TreeItem) => void
    actions?: JSX.Element
}

const ScenarioArticle: React.FC<Props> = props => {
    const { article, formRef, isEdit, canEdit, onUpdate, transformToScenario, onRelatedArticleClick, actions } = props

    const { t } = useTranslation()
    const { instance, scenarioTouched } = useContext(ScenarioContext)
    const { onOpenQuestions } = useContext(ArticleContext)
    const questions = useSelector(selectArticleFormQuestions)
    const articleExpanded = useSelector(selectArticleExpanded)

    const handleUpdateScenario = (values: ArticleEditFormValues) => {
        if (onUpdate) {
            const elements = instance?.toObject().elements || []
            const scenario = graphToJson(elements)
            onUpdate(values, scenario, article.Id)
        }
    }

    const handleOpenQuestions = canEdit ? () => onOpenQuestions(false) : undefined

    return (
        <div className="scenario-article">
            <div className="scenario-article__header">
                <CurrentBranchContainer onParentClick={onRelatedArticleClick} />
                {actions}
            </div>
            <div className="scenario-article__body">
                {isEdit && formRef ? (
                    <ArticleEditForm
                        article={article}
                        formRef={formRef}
                        onSubmit={handleUpdateScenario}
                        transformToScenario={transformToScenario}
                        questionsCount={questions.length}
                        scenarioTouched={scenarioTouched}
                        isArticleExpanded={articleExpanded}
                    />
                ) : (
                    <>
                        <Article
                            symbolCode={article.SymbolCode}
                            headerTop={
                                <>
                                    <ArticleType type={article.Type} />
                                    <ArticleTags tags={article.Tags} />
                                </>
                            }
                            headerRight={
                                <>
                                    <ArticleStatus status={article.Status} />
                                    {article.Status === Status.Active && (
                                        <ArticleUserViewsPopoverContainer currentArticleCode={article.SymbolCode} />
                                    )}
                                </>
                            }
                            title={article.Title ? article.Title : t(`${tNamespace}untitled`)}
                            widgets={
                                <ArticleToolbar
                                    onOpenQuestions={handleOpenQuestions}
                                    questionsCount={questions.length}
                                />
                            }
                            isExpanded={articleExpanded}
                        />
                    </>
                )}
                <div className="scenario-article__editor" key={`scenario-article-editor-${isEdit}`}>
                    <ReactFlowProvider>
                        <ScenarioEditor isEditing={isEdit} scenario={article.Scenario} />
                    </ReactFlowProvider>
                </div>
                {!isEdit && (
                    <ArticleCommentsViewContainer articleCode={article.SymbolCode} articleTitle={article.Title} />
                )}
            </div>
        </div>
    )
}

export default ScenarioArticle
