import { ClassifierStatus } from "./classifier"

export interface ClassifierEventDto {
    EventId: string
    ClassifierId: string
    ClassifierConfigId: string
    ProjectId: string
    EventType: ClassifierEventType
    NewStatus?: ClassifierStatus
    Metrics?: ModelMetrics
    Error?: string
    TriggeredByUser?: Initiator
    ReceivedAt: string
}

export interface ModelMetrics {
    Accuracy: number
    Precision: number
    Recall: number
    F1: number
    NumIntents: number
    NumSamples: number
    ConfusionOther: string
    ConfusionNoOther: string
}

export interface Initiator {
    Login: string
    FirstName: string
    LastName: string
}

export enum ClassifierEventType {
    StartingRequested = "StartingRequested",
    RollbackRequested = "RollbackRequested",
    Starting = "Starting",
    StartingFailed = "StartingFailed",
    ActivationRequested = "ActivationRequested",
    Activated = "Activated",
    DeactivationRequested = "DeactivationRequested",
    Deactivated = "Deactivated",
    Loading = "Loading",
    LoadingFailed = "LoadingFailed",
    Training = "Training",
    TrainingFailed = "TrainingFailed",
    Metrics = "Metrics",
    MetricsFailed = "MetricsFailed",
    Done = "Done",
    DoneFailed = "DoneFailed"
}

export const isClassifierEventDto = (value: unknown): value is ClassifierEventDto => {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const event = value as Partial<ClassifierEventDto>

    return (
        typeof event.EventId === "string" &&
        typeof event.ClassifierId === "string" &&
        typeof event.ClassifierConfigId === "string" &&
        typeof event.ProjectId === "string" &&
        isClassifierEventType(event.EventType) &&
        (typeof event.NewStatus === "undefined" || isClassifierStatus(event.NewStatus)) &&
        (typeof event.Metrics === "undefined" || isModelMetrics(event.Metrics)) &&
        (typeof event.Error === "undefined" || typeof event.Error === "string") &&
        (typeof event.TriggeredByUser === "undefined" || isInitiator(event.TriggeredByUser)) &&
        typeof event.ReceivedAt === "string"
    )
}

const isClassifierEventType = (value: unknown): value is ClassifierEventType => {
    return Object.values(ClassifierEventType).includes(value as ClassifierEventType)
}

const isClassifierStatus = (value: unknown): value is ClassifierStatus => {
    return Object.values(ClassifierStatus).includes(value as ClassifierStatus)
}

const isModelMetrics = (value: unknown): value is ModelMetrics => {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const metrics = value as Partial<ModelMetrics>

    return (
        typeof metrics.Accuracy === "number" &&
        typeof metrics.Precision === "number" &&
        typeof metrics.Recall === "number" &&
        typeof metrics.F1 === "number" &&
        typeof metrics.NumIntents === "number" &&
        typeof metrics.NumSamples === "number" &&
        typeof metrics.ConfusionOther === "string" &&
        typeof metrics.ConfusionNoOther === "string"
    )
}

const isInitiator = (value: unknown): value is Initiator => {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const initiator = value as Partial<Initiator>

    return (
        typeof initiator.Login === "string" &&
        typeof initiator.FirstName === "string" &&
        typeof initiator.LastName === "string"
    )
}

export interface ClassifierModelDto {
    ClassifierId: string
    CustomerId: string
    LearnedAt: string
    LearningStartedAt: string
    LearningStartedByUser?: Initiator
    Metrics?: ModelMetrics
    UsedConfigs: UsedConfig[]
}

export interface ClassifierModels {
    [configId: string]: ClassifierModelDto[]
}

export interface GetClassifierModelsSuccessPayload {
    configId: string
    models: ClassifierModelDto[]
}

interface UsedConfig {
    ConfigId: string
}

export interface UpdateClassifierStatusPayload {
    ClassifierConfigId: string
    NewStatus: ClassifierStatus
}
