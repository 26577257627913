import React from "react"
import "./ArticleType.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import { ArticleEditFormValues } from "../../models/article"
import { nameof } from "../../utility/common/nameof"
import { useSelector } from "react-redux"
import { selectArticleTypes } from "../../store/knowledgeBase/selectors"
import SidebarContextMenu from "../SIdebarContextMenu/SidebarContextMenu"
import MenuItemRow from "../MenuItemRow/MenuItemRow"

const tNamespace = "knowledgeBase:article-edit-form."
const NO_SELECTED_ITEM = "no-selected"

interface Props {
    type: string
}

const ArticleType: React.FC<Props> = ({ type }) => {
    const articleTypesResponse = useSelector(selectArticleTypes)
    const articleTypes = articleTypesResponse ? articleTypesResponse.Types : []
    const selectedType = articleTypes.find(t => t.Id === type)
    return <div className="article-type">{selectedType?.Title}</div>
}

const FormikArticleTypeSelect: React.FC = () => {
    const { t } = useTranslation()
    const articleTypesResponse = useSelector(selectArticleTypes)

    const articleTypes = articleTypesResponse?.Types?.length
        ? [...articleTypesResponse.Types, { Id: NO_SELECTED_ITEM, Title: t(`${tNamespace}select-type`) }]
        : [{ Id: "", Title: t(`${tNamespace}no-types`) }]
    const { values, setFieldValue } = useFormikContext<ArticleEditFormValues>()
    const articleTypesNative = articleTypes.filter(x => x.Id !== NO_SELECTED_ITEM)
    const selectedType = articleTypes.find(t => t.Id === values.Type)

    const handleSelect = (eventKey: string | null) => {
        if (!eventKey) {
            return
        }

        if (eventKey === NO_SELECTED_ITEM) {
            eventKey = ""
        }

        setFieldValue(nameof<ArticleEditFormValues>("Type"), eventKey, false)
    }

    const placeholder = articleTypesNative.length ? t(`${tNamespace}select-type`) : t(`${tNamespace}no-types`)

    return (
        <SidebarContextMenu
            placement="bottom-start"
            containerClassName="article-type-select"
            className="article-type-select__menu"
            items={articleTypes.map(
                type =>
                    type.Id !== values.Type && (
                        <MenuItemRow
                            key={type.Id}
                            className="article-type-select__item"
                            title={type.Title}
                            onClick={() => type.Id && handleSelect(type.Id)}
                        />
                    )
            )}
        >
            <div className="article-type-select__toggle">
                <span className="article-type-select__text">{selectedType?.Title || placeholder}</span>
                <FontAwesomeIcon icon={faAngleDown} className="article-type-select__icon" />
            </div>
        </SidebarContextMenu>
    )
}

export { FormikArticleTypeSelect, ArticleType }
