import React, { RefObject, useContext, useEffect } from "react"
import {
    Article as KnowledgeBaseArticle,
    ArticleAnswer,
    ArticleEditFormValues,
    ArticleStatus as Status
} from "../../models/article"
import ArticleEditForm from "../ArticleEditForm/ArticleEditForm"
import Article from "../Article/Article"
import { useTranslation } from "react-i18next"
import { ArticleParameters } from "../ArticleParameters/ArticleParameters"
import { ArticleType } from "../ArticleType/ArticleType"
import { ArticleTags } from "../ArticleTags/ArticleTags"
import ArticleStatus from "../ArticleStatus/ArticleStatus"
import ArticleUserViewsPopoverContainer from "../ArticleUserViewsPopover/ArticleUserViewsPopoverContainer"
import ArticleCommentsViewContainer from "../ArticleCommentsView/ArticleCommentsViewContainer"
import { FormikProps } from "formik/dist/types"
import ArticleReadConfirmationCardContainer from "../ArticleReadConfirmationCard/ArticleReadConfirmationCardContainer"
import "./ArticleView.scss"
import { Route, RouteComponentProps } from "react-router"
import { useParams, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
    selectAnswersFrom,
    selectArticleExpanded,
    selectArticleFormQuestions,
    selectCurrentAnswer
} from "../../store/knowledgeBase/selectors"
import ArticleToolbar from "../ArticleToolbar/ArticleToolbar"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"
import SubArticlesContainer from "../SubArticles/SubArticlesContainer"
import CurrentBranchContainer from "../CurrentBranch/CurrentBranchContainer"
import type { TreeItem } from "@atlaskit/tree/types"
import { getSlots } from "../../store/slots/thunks"
import { selectSlots } from "../../store/slots/selectors"
import ArticleContentEditor from "../ArticleContentEditor/ArticleContentEditor"
import { testId } from "../../utility/tests/testId"

const tNamespace = "knowledgeBase:"

export interface ArticleViewProps {
    article: KnowledgeBaseArticle
    formRef?: RefObject<FormikProps<ArticleEditFormValues>>
    isEdit: boolean
    canEdit: boolean
    notificationExists: boolean
    onUpdateArticle?: (values: ArticleEditFormValues) => void
    onTransformToScenario?: () => void
    onSelectAnswer: (answerId: string) => void
    onUpdateAnswer?: (answer: ArticleAnswer) => void
    onChangeCurrentAnswer: (answerId: string) => void
    onRelatedArticleClick: (category: TreeItem) => void
    actions?: JSX.Element
}

const ArticleView: React.FC<ArticleViewProps> = props => {
    const { path } = useRouteMatch()

    return <Route path={`${path}/:answerId?`} render={routeProps => <ArticleViewRoute {...routeProps} {...props} />} />
}

export type ArticleViewMatchPath = {
    answerId?: string
}

type ArticleViewRouteProps = ArticleViewProps & RouteComponentProps<ArticleViewMatchPath>

export const ArticleViewRoute: React.FC<ArticleViewRouteProps> = props => {
    const {
        article,
        formRef,
        isEdit,
        canEdit,
        notificationExists,
        onUpdateArticle,
        onTransformToScenario,
        onSelectAnswer,
        onRelatedArticleClick,
        onUpdateAnswer,
        onChangeCurrentAnswer,
        actions
    } = props

    const { t } = useTranslation()
    const { answerId } = useParams<ArticleViewMatchPath>()
    const { onChooseAnswer, onOpenQuestions } = useContext(ArticleContext)
    const answersForm = useSelector(selectAnswersFrom)
    const currentAnswer = useSelector(selectCurrentAnswer)
    const questions = useSelector(selectArticleFormQuestions)
    const slots = useSelector(selectSlots)
    const dispatch = useDispatch()
    const articleExpanded = useSelector(selectArticleExpanded)

    useEffect(() => {
        if (slots.length === 0 && article && article.ProjectId) {
            dispatch(getSlots(article.ProjectId))
        }
    }, [article, dispatch, slots.length])

    useEffect(() => {
        if (answersForm.Answers.length === 0) {
            return
        }
        const answers = answersForm.Answers
        if (!answerId) {
            onSelectAnswer(answers[0].Id)
        } else {
            if (currentAnswer?.Id !== answerId) {
                onChangeCurrentAnswer(answerId)
            }
        }
    }, [answerId, answersForm, currentAnswer, onChangeCurrentAnswer, onSelectAnswer])

    const handleOpenAnswers = () => onChooseAnswer(false, false, onSelectAnswer)
    const handleOpenQuestions = canEdit ? () => onOpenQuestions(false) : undefined

    return (
        <div className="article-view">
            <div className="article-view__header">
                <CurrentBranchContainer onParentClick={onRelatedArticleClick} />
                {actions}
            </div>
            <div className="article-view__body">
                {isEdit && onUpdateArticle && formRef ? (
                    <ArticleEditForm
                        article={article}
                        answersForm={answersForm}
                        currentAnswer={currentAnswer}
                        formRef={formRef}
                        onSubmit={onUpdateArticle}
                        onTransformToScenario={onTransformToScenario}
                        onUpdateAnswer={onUpdateAnswer}
                        onSelectAnswer={onSelectAnswer}
                        questionsCount={questions.length}
                        isArticleExpanded={articleExpanded}
                    />
                ) : (
                    <>
                        <Article
                            symbolCode={article.SymbolCode}
                            headerTop={
                                <>
                                    <ArticleType type={article.Type} />
                                    <ArticleTags tags={article.Tags} />
                                </>
                            }
                            headerRight={
                                article.Status === Status.Active ? (
                                    <ArticleUserViewsPopoverContainer currentArticleCode={article.SymbolCode} />
                                ) : (
                                    <ArticleStatus status={article.Status} />
                                )
                            }
                            title={article.Title ? article.Title : t(`${tNamespace}untitled`)}
                            widgets={
                                <>
                                    <ArticleToolbar
                                        onOpenAnswers={handleOpenAnswers}
                                        answersCount={answersForm.Answers.length}
                                        onOpenQuestions={handleOpenQuestions}
                                        questionsCount={questions.length}
                                    />
                                    <ArticleParameters parameters={article.Parameters} />
                                </>
                            }
                            body={<ArticleContentEditor content={currentAnswer?.Text ?? ""} isEditable={false} t={t} />}
                            isExpanded={articleExpanded}
                            testId={testId.article}
                        />
                        {!isEdit && notificationExists && (
                            <div className="article-view__read-confirmation">
                                <ArticleReadConfirmationCardContainer articleCode={article.SymbolCode} />
                            </div>
                        )}
                        <SubArticlesContainer onSubArticleClick={onRelatedArticleClick} />
                        <ArticleCommentsViewContainer articleCode={article.SymbolCode} articleTitle={article.Title} />
                    </>
                )}
            </div>
        </div>
    )
}

export default ArticleView
