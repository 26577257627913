import { KnowledgeBasePermittedAction } from "../../models/knowledgeBasePermission"
import { ArticleKind, ArticleShareFormValues } from "../../models/article"
import { createContext, Dispatch, SetStateAction } from "react"

export interface ArticleViewContextType {
    permittedAction?: KnowledgeBasePermittedAction
    availableKinds: ArticleKind[]
    connected: boolean
    onShare: (values: ArticleShareFormValues, hidePopover: () => void) => void
    markedMessagesCount: number
    setMarkedMessagesCount: Dispatch<SetStateAction<number>>
}

const ArticleViewContext = createContext<ArticleViewContextType>({
    permittedAction: KnowledgeBasePermittedAction.View,
    availableKinds: [],
    connected: false,
    onShare: () => void 0,
    markedMessagesCount: 0,
    setMarkedMessagesCount: () => void 0
})

export default ArticleViewContext
