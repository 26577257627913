import React, { memo, useCallback, useContext, useEffect, useState } from "react"
import { onOpenSharingCatalogFunc } from "../../../types/knowledgeBaseFunctions"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/rootReducer"
import {
    selectCatalog,
    selectCreateCategoryState,
    selectCurrentBranch,
    selectMoveCategoryState
} from "../../../store/knowledgeBase/selectors"
import AsyncState from "../../../core/asyncState"
import type { ItemId, TreeItem } from "@atlaskit/tree/types"
import type { TreeDestinationPosition, TreeSourcePosition } from "@atlaskit/tree"
import { isModifyPermitted } from "../../../utility/knowledgeBase/useModifyPermitted"
import { mutateCurrentBranch, toggleCategoryExpanded } from "../../../store/knowledgeBase/slice"
import { createCategory, getCategories, moveCategory } from "../../../store/knowledgeBase/thunks"
import { push } from "connected-react-router"
import { buildArticleActionUrl } from "../../../utility/knowledgeBase/articleUrlHelper"
import { ArticleAction } from "../../../models/article"
import KnowledgeBasePermissionsProvider from "../../KnowledgeBasePermissionsProvider/KnowledgeBasePermissionsProvider"
import CatalogPopoverContainer from "../../CatalogPopover/CatalogPopoverContainer"
import CategoryAction from "../../CategoryAction/CategoryAction"
import { faPlus, faShareAlt } from "@fortawesome/pro-light-svg-icons"
import useRefCheck from "../../../utility/common/useRefCheck"
import { useTranslation } from "react-i18next"
import { moveItem } from "../../../utility/common/tree"
import { KnowledgeBasePermittedAction } from "../../../models/knowledgeBasePermission"
import KnowledgeBaseContext from "../../KnowledgeBaseProvider/KnowledgeBaseContext"
import LazyCatalog from "../LazyCatalog"
import { SHARING_ENABLED } from "../../../utility/common/flags"

const tNamespace = "knowledgeBase:"

interface EditableCatalogProps {
    projectId: string
    id: string
    url: string
    canModifyKnowledgeBase: boolean
    onEditCatalogClick: () => void
    onCancelEditCatalogClick: () => void
    onOpenSharingCatalog?: onOpenSharingCatalogFunc
}

const EditableCatalog: React.FC<EditableCatalogProps> = props => {
    const { projectId, id, url, onEditCatalogClick, onCancelEditCatalogClick, onOpenSharingCatalog } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { canModifyKnowledgeBase } = useContext(KnowledgeBaseContext)
    const tree = useSelector((state: RootState) => selectCatalog(state, id), shallowEqual)
    const { CurrentCatalogCode, CurrentCategoryId } = useSelector(selectCurrentBranch, shallowEqual)
    const apiState = AsyncState.merge2(useSelector(selectMoveCategoryState), useSelector(selectCreateCategoryState))
    const [draggableId, setDraggableId] = useState<ItemId | undefined>(undefined)
    const [editingId, setEditingId] = useState<string>()
    const isSharingEnabled = useRefCheck(SHARING_ENABLED)

    const rootItem = tree.items[tree.rootId]

    const handleDragStart = (draggableId: ItemId) => {
        setDraggableId(draggableId)
    }

    const handleDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
        const itemId = draggableId
        setDraggableId(undefined)

        if (apiState.inProcess) {
            return
        }

        if (!destination || !itemId) {
            return
        }

        if (source.index === destination.index && source.parentId === destination.parentId) {
            return
        }

        const {
            data: { permittedAction: parentPermittedAction, catalogCode: parentCatalogCode }
        } = tree.items[destination.parentId]

        if (!isModifyPermitted(parentPermittedAction)) {
            return
        }

        const { newTree, draggableItem, sourceItem, destinationItem, prevItem, nextItem } = moveItem(
            itemId,
            tree,
            source,
            destination
        )

        if (CurrentCatalogCode !== parentCatalogCode) {
            dispatch(
                mutateCurrentBranch({
                    CurrentCatalogCode: parentCatalogCode
                })
            )
        }

        dispatch(moveCategory(projectId, newTree, sourceItem, destinationItem, draggableItem, prevItem, nextItem))
    }

    const handleCategoryTitleClick = useCallback(
        (categoryItem: TreeItem, catalogCode?: string, categoryCode?: string) => {
            if (categoryCode && categoryItem.id !== CurrentCategoryId) {
                dispatch(
                    mutateCurrentBranch({
                        CurrentCategoryId: categoryItem.id as string,
                        CurrentCatalogCode: catalogCode
                    })
                )
                dispatch(push(buildArticleActionUrl(url, ArticleAction.View, categoryCode)))
            }
        },
        [dispatch, url, CurrentCategoryId]
    )

    const handleAddCategory = useCallback(
        (item: TreeItem) => {
            const children = item.children
            const firstChildrenId = children.length ? children[0] : undefined
            const firstChildren = firstChildrenId ? tree.items[firstChildrenId] : undefined
            dispatch(createCategory(projectId, item, url, firstChildren))
        },
        [projectId, dispatch, url, tree.items]
    )

    useEffect(() => {
        if (editingId === undefined) {
            return
        }

        if (!editingId) {
            onCancelEditCatalogClick()
        } else {
            onEditCatalogClick()
        }
    }, [editingId, onCancelEditCatalogClick, onEditCatalogClick])

    const handleDragEnabled = (item: TreeItem) => {
        const {
            data: { permittedAction }
        } = item

        return !apiState.inProcess && isModifyPermitted(permittedAction)
    }

    const handleSharingClick = () => {
        onOpenSharingCatalog?.(id)
    }

    const handleToggle = (itemId: ItemId, isExpanded: boolean) => {
        dispatch(
            toggleCategoryExpanded({
                CatalogId: tree.rootId,
                CategoryId: itemId,
                IsExpanded: isExpanded
            })
        )
    }

    const handleExpandCategory = useCallback(
        (itemId: ItemId, onExpand: (itemId: ItemId) => void) => {
            const { children, isChildrenLoading } = tree.items[itemId]

            if (!apiState.inProcess) {
                onExpand(itemId)

                if (!isChildrenLoading && children.length === 0) {
                    dispatch(getCategories(projectId, itemId, tree.rootId))
                }
            }
        },
        [tree.items, tree.rootId, apiState.inProcess, dispatch, projectId]
    )

    const handleCanEdit = (permittedAction?: KnowledgeBasePermittedAction) => {
        return canModifyKnowledgeBase && isModifyPermitted(permittedAction)
    }

    const getAddCategoryAction = (item: TreeItem) => (
        <CategoryAction
            id={id as string}
            key={`${id}-add-article`}
            tooltipText={t(`${tNamespace}add-article`)}
            icon={faPlus}
            onClick={() => handleAddCategory(item)}
            loading={item.isChildrenLoading || apiState.inProcess}
        />
    )

    const handleGetItemExtensions = (item: TreeItem) => [getAddCategoryAction(item)]

    const getExtensions = () => {
        const result = [
            <KnowledgeBasePermissionsProvider key={`${id}-settings`} permissions={rootItem.data.permissions}>
                <CatalogPopoverContainer
                    projectId={projectId}
                    catalogCode={rootItem.data.catalogCode}
                    catalogTitle={rootItem.data.title}
                    onEditClick={id => setEditingId(prevId => (prevId === id ? "" : id))}
                    onCancelEditClick={() => setEditingId("")}
                    isEdit
                />
            </KnowledgeBasePermissionsProvider>,
            getAddCategoryAction(rootItem)
        ]
        if (onOpenSharingCatalog && isSharingEnabled) {
            result.unshift(
                <CategoryAction
                    key={`${id}-share-category`}
                    id="share-category"
                    icon={faShareAlt}
                    tooltipText={t(`${tNamespace}article-actions.share`)}
                    onClick={handleSharingClick}
                />
            )
        }
        return result
    }

    return (
        <LazyCatalog
            tree={tree}
            editingId={editingId}
            extensions={getExtensions()}
            onGetItemExtensions={handleGetItemExtensions}
            onTitleClick={handleCategoryTitleClick}
            onCanEdit={handleCanEdit}
            onExpandCategory={handleExpandCategory}
            onToggle={handleToggle}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            isDragEnabled={handleDragEnabled}
        />
    )
}

export default memo(EditableCatalog)
