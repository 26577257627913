import React, { useCallback, useContext } from "react"
import {
    useGetAllowedFileExtensions,
    useGetAllowedVideoExtensions
} from "../../utility/knowledgeBase/articleRedactorEmbeds"
import { WithT } from "i18next"
import "./ArticleContentEditor.scss"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"
import { buildArticleActionUrl, buildSharedArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import { ArticleAction } from "../../models/article"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import { clearCurrentBranch } from "../../store/knowledgeBase/slice"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import LazyContentEditor from "../ContentEditor/LazyContentEditor"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"

export interface ArticleContentEditorProps extends WithT {
    content: string
    onChange?: (content: string) => void
    isEditable?: boolean
}

const ArticleContentEditor: React.FC<ArticleContentEditorProps> = props => {
    const { content, onChange, isEditable, t } = props
    const dispatch = useDispatch()
    const { url, projectId, shareId } = useContext(KnowledgeBaseContext)

    const handleOpenArticle = useCallback(
        (articleCode: string) => {
            dispatch(clearCurrentBranch())
            if (shareId) {
                dispatch(push(buildSharedArticleActionUrl(url, articleCode)))
            } else {
                dispatch(push(buildArticleActionUrl(url, ArticleAction.View, articleCode)))
            }
        },
        [dispatch, shareId, url]
    )

    const handleContentChange = useCallback(
        (getContent: () => string) => {
            if (!onChange) {
                return
            }

            onChange(getContent())
        },
        [onChange]
    )

    const handleLocationChange = useCallback(
        (path: string) => {
            dispatch(push(`${url.replace(new RegExp(`${definedMenuItems.KnowledgeBase.id}$`), "")}${path}`))
        },
        [dispatch, url]
    )

    const allowedFileExtensions = useGetAllowedFileExtensions()
    const allowedVideoExtensions = useGetAllowedVideoExtensions()

    return (
        <ErrorBoundary local>
            <LazyContentEditor
                className="article-content-editor"
                allowedFileExtensions={allowedFileExtensions}
                allowedVideoExtensions={allowedVideoExtensions}
                content={content}
                onChange={handleContentChange}
                projectId={projectId}
                t={t}
                isEditable={isEditable}
                onOpenArticle={handleOpenArticle}
                onChangeLocation={handleLocationChange}
                onDispatch={dispatch}
            />
        </ErrorBoundary>
    )
}

export default ArticleContentEditor
