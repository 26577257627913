import React from "react"
import "./ArticleAddFunctionsButton.scss"
import { WithT } from "i18next"
import { formTranslation } from "../../locales/form"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import IconButton from "../IconButton/IconButton"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"

export interface ArticleAddFunctionsButtonProps extends WithT {
    onCreateAnswer?: () => void
    onCreateForm?: () => void
    onCreateQuestionExamples?: () => void
    onCreateParameters?: () => void
}

const tNamespace = "knowledgeBase:article-add-functions."

const ArticleAddFunctionsButton: React.FC<ArticleAddFunctionsButtonProps> = props => {
    const { t, onCreateAnswer, onCreateForm, onCreateParameters, onCreateQuestionExamples } = props

    const getSettings = () => {
        const result: JSX.Element[] = []
        onCreateAnswer &&
            result.push(
                <SettingsContextMenuItem
                    key="answers"
                    id="answers"
                    text={t(tNamespace + "answers-set")}
                    onClick={onCreateAnswer}
                />
            )
        onCreateForm &&
            result.push(
                <SettingsContextMenuItem key="form" id="form" text={t(tNamespace + "forms")} onClick={onCreateForm} />
            )
        onCreateQuestionExamples &&
            result.push(
                <SettingsContextMenuItem
                    key="questions"
                    id="questions"
                    text={t(tNamespace + "question-examples")}
                    onClick={onCreateQuestionExamples}
                />
            )
        onCreateParameters &&
            result.push(
                <SettingsContextMenuItem
                    key="parameters"
                    id="parameters"
                    text={t(tNamespace + "parameters")}
                    onClick={onCreateParameters}
                />
            )
        return result
    }

    return (
        <SettingsContextMenu items={getSettings()} className="article-add-functions-button">
            <IconButton icon={faPlus} variant="link" id="add-function">
                {t(formTranslation.add)}
            </IconButton>
        </SettingsContextMenu>
    )
}

export default ArticleAddFunctionsButton
