import React, { ReactNode, useContext } from "react"
import "./CommonNode.scss"
import { Handle, Position } from "react-flow-renderer"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { handleRemoveNode } from "../../../../utility/scenario/scenario"
import { ScenarioContext, SetElementsType } from "../../../ScenarioEditor/ScenarioContext"
import { ClassProps } from "../../../../utility/common/props"
import { ScenarioBlockType } from "../../../../models/scenario"
import CommonNodeHeader from "./CommonNodeHeader"

const tNamespace = "scenarioEditor:"

interface Props extends ClassProps {
    id: string
    children: ReactNode
    headerClassName?: string
    type: ScenarioBlockType
    isConnectable: boolean
}

const CommonNode: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { id, className, headerClassName, type, isConnectable, children } = props
    const { setElements, selectedNode, closeSidebar } = useContext(ScenarioContext)

    const handleRemoveNodeAndCloseSidebar = (id: string, setElements: SetElementsType) => () => {
        handleRemoveNode(id, setElements)()
        closeSidebar()
    }

    return (
        <div className={cn("common-node", className, selectedNode === id && "common-node_selected")}>
            <Handle
                id={id}
                type="target"
                position={Position.Left}
                className="common-node__target-handle"
                isConnectable={isConnectable}
            />
            <CommonNodeHeader
                id={id}
                type={type}
                title={t(`${tNamespace}block-title.${type}`)}
                className={headerClassName}
                onClick={handleRemoveNodeAndCloseSidebar(id, setElements)}
            />
            {children}
        </div>
    )
}

export default CommonNode
