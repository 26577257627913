import { SimpleUser } from "./user"
import { SimpleRole } from "./role"
import { UserAccessPrimitive } from "./knowledgeBaseUserAccess"

export enum KnowledgeBaseAccessType {
    Login = "Login",
    Role = "Role",
    All = "All"
}

export enum KnowledgeBasePermittedAction {
    View = "View",
    Edit = "Edit"
}

export enum KnowledgeBasePermissionWeight {
    Login = 3,
    Role = 2,
    All = 1
}

export interface KnowledgeBasePermission {
    Type: KnowledgeBaseAccessType
    Action: KnowledgeBasePermittedAction
    Value: string
    ProjectId: string
    SearchValue: string
    Weight: KnowledgeBasePermissionWeight
}

export const isKnowledgeBasePermission = (value: unknown): value is KnowledgeBasePermission => {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const permission = value as Partial<KnowledgeBasePermission>

    return (
        isKnowledgeBaseAccessType(permission.Type) &&
        isKnowledgeBasePermittedAction(permission.Action) &&
        typeof permission.Value === "string" &&
        typeof permission.ProjectId === "string" &&
        typeof permission.SearchValue === "string" &&
        isKnowledgeBasePermissionWeight(permission.Weight)
    )
}

export const isKnowledgeBaseAccessType = (value: unknown): value is KnowledgeBaseAccessType => {
    return Object.values(KnowledgeBaseAccessType).includes(value as KnowledgeBaseAccessType)
}

const isKnowledgeBasePermittedAction = (value: unknown): value is KnowledgeBasePermittedAction => {
    return Object.values(KnowledgeBasePermittedAction).includes(value as KnowledgeBasePermittedAction)
}

const isKnowledgeBasePermissionWeight = (value: unknown): value is KnowledgeBasePermissionWeight => {
    return Object.values(KnowledgeBasePermissionWeight).includes(value as KnowledgeBasePermissionWeight)
}

export interface SearchUsersAndRolesResponse {
    Users: SimpleUser[]
    Roles: SimpleRole[]
}

export interface GetUsersAndRolesByPermissionsRequest {
    AccessItems: UserAccessPrimitive[]
}

export type GetUsersAndRolesByPermissionsResponse = SearchUsersAndRolesResponse

export interface KnowledgeBasePermittedActionSelectItem {
    Action: KnowledgeBasePermittedAction
    Title: string
}
