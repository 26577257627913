import { KnowledgeBaseDefaultEntity } from "./knowledgeBaseDefaultEntity"
import {
    isKnowledgeBasePermission,
    KnowledgeBasePermission,
    KnowledgeBasePermittedAction
} from "./knowledgeBasePermission"
import { Article, ArticleStatus } from "./article"
import { isArrayOfType } from "../types/isArrayOfType"

export interface Category extends KnowledgeBaseDefaultEntity {
    ParentId: string
    CatalogCode: string
    IsOpen: boolean
    Permissions: KnowledgeBasePermission[]
    PermittedAction?: KnowledgeBasePermittedAction
    Status: ArticleStatus
    HasChildren: boolean
    Sort?: string
}

export interface CategoryMutation {
    Id: string
    CatalogCode: string
    SymbolCode: string
    ParentId?: string
    HasChildren?: boolean
    Sort?: string
    Title?: string
    Status?: ArticleStatus
    Permissions?: KnowledgeBasePermission[]
    PermittedAction?: KnowledgeBasePermittedAction
}

export const isCategoryMutation = (value: unknown): value is CategoryMutation => {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const mutation = value as Partial<CategoryMutation>

    return (
        typeof mutation.Id === "string" &&
        typeof mutation.CatalogCode === "string" &&
        typeof mutation.SymbolCode === "string" &&
        (typeof mutation.ParentId === "undefined" || typeof mutation.ParentId === "string") &&
        (typeof mutation.HasChildren === "undefined" || typeof mutation.HasChildren === "boolean") &&
        (typeof mutation.Sort === "undefined" || typeof mutation.Sort === "string") &&
        (typeof mutation.Title === "undefined" || typeof mutation.Title === "string") &&
        (typeof mutation.Status === "undefined" || isArticleStatus(mutation.Status)) &&
        (typeof mutation.Permissions === "undefined" ||
            isArrayOfType(mutation.Permissions, isKnowledgeBasePermission)) &&
        (typeof mutation.PermittedAction === "undefined" || isKnowledgeBasePermittedAction(mutation.PermittedAction))
    )
}

const isArticleStatus = (value: unknown): value is ArticleStatus => {
    return Object.values(ArticleStatus).includes(value as ArticleStatus)
}

const isKnowledgeBasePermittedAction = (value: unknown): value is KnowledgeBasePermittedAction => {
    return Object.values(KnowledgeBasePermittedAction).includes(value as KnowledgeBasePermittedAction)
}

export const isCategoryMutations = (raw: unknown[]): raw is CategoryMutation[] => {
    return isArrayOfType(raw, isCategoryMutation)
}

export interface GetCategoriesRequest {
    CatalogCode: string
    ParentCode: string
}

export interface GetCategoriesResponse {
    Categories: Category[]
}

export interface GetRelatedCategoriesRequest {
    Id?: string
    CategoryCode?: string
    CatalogCode?: string
}

export interface GetRelatedCategoriesResponse {
    Id: string
    ParentCategory: Category
    ChildCategories: Category[]
}

export interface CategoryPlacement {
    Id: string
    ParentId: string
    CatalogCode: string
}

export interface CreateCategoryRequest {
    ParentId: string
    Permissions: KnowledgeBasePermission[]
    NextItem?: CategoryPlacement
}

export interface CreateCategoryResponse {
    CategoryId: string
    Article: Article
}

export interface CategoryAddedEvent {
    Category: Category
    ParentItemId: string
    EventSenderLogin: string
}

export interface MoveCategoryRequest {
    SourceParent: CategoryPlacement
    DestinationParent: CategoryPlacement
    DraggableItem: CategoryPlacement
    PrevItem?: CategoryPlacement
    NextItem?: CategoryPlacement
}

export interface MoveCategoryResponse {
    DraggableId: string
    Mutations: CategoryMutation[]
    ParentCategory?: Category
}
