import { useEffect } from "react"

const NOTIFICATION_TYPE = {
    DIALOG_ROUTED: "dialogRoutedNotification",
    MESSAGE: "messageNotification"
}

function useTabNotification(imgSource: string, deps?: React.DependencyList): void {
    let isNewDialog = false
    let isNewMessage = false

    useEffect(() => {
        const messageListener = (e: MessageEvent) => {
            if (e.data.notificationType) {
                if (
                    e.data.notificationType === NOTIFICATION_TYPE.DIALOG_ROUTED &&
                    document.visibilityState === "hidden"
                ) {
                    const faviconSize = 32
                    const canvas = document.createElement("canvas")
                    canvas.width = faviconSize
                    canvas.height = faviconSize

                    const context = canvas.getContext("2d")
                    if (!context) {
                        return
                    }

                    const img = new Image()
                    img.src = imgSource

                    img.onload = () => {
                        context.drawImage(img, 0, 0, faviconSize, faviconSize)
                        context.beginPath()
                        context.arc(
                            canvas.width - faviconSize / 6,
                            canvas.height - faviconSize / 6,
                            faviconSize / 5,
                            0,
                            2 * Math.PI
                        )
                        context.fillStyle = "#0067AD"
                        context.fill()

                        const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement
                        favicon.href = canvas.toDataURL("image/png")
                    }

                    isNewDialog = true
                }

                if (
                    e.data.notificationType === NOTIFICATION_TYPE.MESSAGE &&
                    e.data.messageCounter > 0 &&
                    document.visibilityState === "hidden"
                ) {
                    document.title = document.title.includes("‧")
                        ? `${e.data.messageCounter} ‧ ${document.title.split(" ‧ ")[1]}`
                        : `${e.data.messageCounter} ‧ ${document.title}`

                    isNewMessage = true
                }
            }
        }

        const visibilityListener = () => {
            if (document.visibilityState === "visible") {
                if (isNewDialog) {
                    const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement
                    favicon.href = imgSource

                    isNewDialog = false
                }

                if (isNewMessage) {
                    document.title = document.title.split(" ‧ ")[1]

                    isNewMessage = false
                }
            }
        }

        window.addEventListener("message", messageListener)
        document.addEventListener("visibilitychange", visibilityListener)

        return () => {
            window.removeEventListener("message", messageListener, false)
            document.removeEventListener("visibilitychange", visibilityListener, false)
        }
    }, [imgSource])
}

export default useTabNotification
