import { getEnumByEnumValueAsAssotiative, getEnumByEnumValueAsIndexed } from "../../../helpers/enum"
import { ChannelType, ChannelTypeString } from "../../../models/channel"

export const convertChannelEnumNameToEnumIndex = (input: ChannelTypeString): ChannelType => {
    const channelTypeStringKey = getEnumByEnumValueAsAssotiative(ChannelTypeString, input)

    if (channelTypeStringKey) {
        const channelTypeKey = getEnumByEnumValueAsIndexed(ChannelType, channelTypeStringKey)

        if (channelTypeKey !== null) {
            return ChannelType[channelTypeKey]
        }
    }

    return ChannelType.Email
}
