import React from "react"
import CodeBlockEditor from "../../../CodeBlockEditor/CodeBlockEditor"
import Node from "rich-markdown-editor/dist/nodes/Node"
import Keyboard from "./Keyboard/Keyboard"
import { Node as ProseMirrorNode, NodeType, Schema } from "prosemirror-model"
import { EditorState, NodeSelection, Transaction } from "prosemirror-state"
import { MarkdownButtonBlock } from "../../../../models/markdownButton"
import {
    dataToParamString,
    defaultButtonsParameters,
    getKeyboard,
    getLanguageWithParameters,
    isCommaUsedToSeparate,
    isQuotesRequired,
    keyboardToString,
    markdownButtonDefault
} from "../../../../utility/articleContentEditor/markdownButtons"
import { MarkdownButton } from "../../../../utility/articleContentEditor/buttonsParser/buttonsParser"
import { textblockTypeInputRule } from "prosemirror-inputrules"
import NextAnswer from "../../../NextAnswer/NextAnswer"
import QuickActions from "../QuickActions/QuickActions/QuickActions"
import WidgetBlock from "../Widget/Widget/WidgetBlock"
import { defaultWidget, Widget } from "../Widget/MarkdownWidgetForm/MarkdownWidgetForm"
import { MarkdownCustomLanguages } from "../../../../utility/articleContentEditor/markdownCustomLanguages"
import { v4 as uuidV4 } from "uuid"
import { getDefaultQuickActions, getQuickActions } from "../../../../utility/articleContentEditor/markdownQuickActions"
import { TFunction } from "react-i18next"
import Token from "../../../../types/markdownToken"
import { MarkdownSerializerState } from "prosemirror-markdown"

const defaultLanguage = ""

const markdownLanguages: string[] = [MarkdownCustomLanguages.QuickActions, MarkdownCustomLanguages.Widget]
const isMarkdownLanguage = (language: string) => markdownLanguages.includes(language)

export enum CodeFenceNodeMeta {
    Buttons = "buttons",
    Code = "code"
}

interface CommandOptions {
    schema: Schema
    type: NodeType
}

export const getDefaultParameters = (language: string) => {
    switch (language) {
        case MarkdownCustomLanguages.Buttons:
            return defaultButtonsParameters
        case MarkdownCustomLanguages.Widget:
            return {
                id: uuidV4(),
                title: "",
                description: ""
            }
        default:
            return {}
    }
}

const getDefaultMarkdown = (language: string, t: TFunction) => {
    switch (language) {
        case MarkdownCustomLanguages.QuickActions:
            return getDefaultQuickActions(t)
        case MarkdownCustomLanguages.Widget:
            return ""
        default:
            return {}
    }
}

class KeyboardNode extends Node {
    get name() {
        return "code_fence"
    }

    get schema() {
        return {
            attrs: {
                language: {
                    default: defaultLanguage
                },
                parameters: {
                    default: {}
                },
                keyboard: {
                    default: {}
                },
                markdown: {
                    default: ""
                }
            },
            content: "text*",
            marks: "",
            group: "block",
            code: true,
            defining: true,
            draggable: false,
            parseDOM: [
                {
                    tag: "pre",
                    preserveWhitespace: "full",
                    getAttrs: (dom: HTMLDivElement) => ({
                        ...defaultAttrs,
                        language: dom.dataset.language ?? defaultLanguage,
                        initial: true
                    })
                },
                {
                    tag: ".code-block",
                    preserveWhitespace: "full",
                    contentElement: "code",
                    getAttrs: (dom: HTMLDivElement) => ({
                        ...defaultAttrs,
                        language: dom.dataset.language ?? defaultLanguage,
                        initial: true
                    })
                }
            ],
            toDOM: () => {
                return ["div", ["pre", ["code", { spellCheck: false }, 0]]]
            }
        }
    }

    commands(options: CommandOptions) {
        const { type } = options
        return (attrs: { language: string }) => {
            return (state: EditorState, dispatch: (tr: Transaction) => void) => {
                const { language } = attrs
                const tr = state.tr
                    .replaceSelectionWith(
                        type.create({
                            language,
                            parameters: getDefaultParameters(language),
                            keyboard:
                                language === MarkdownCustomLanguages.Buttons
                                    ? { "1": [{ ...markdownButtonDefault(this.options.t) }] }
                                    : {},
                            markdown: getDefaultMarkdown(language, this.options.t),
                            initial: true
                        })
                    )
                    .scrollIntoView()
                dispatch(tr)
            }
        }
    }

    handleUpdateButtons = (keyboard: { [rowIdx: number]: MarkdownButton[] }, block: MarkdownButtonBlock): void => {
        const { view } = this.editor
        const { state, dispatch } = view

        dispatch(
            state.tr
                .replaceSelectionWith(
                    state.schema.nodes.code_fence.create({
                        ...defaultAttrs,
                        language: MarkdownCustomLanguages.Buttons,
                        parameters: block,
                        keyboard
                    })
                )
                .scrollIntoView()
        )
    }

    handleRemoveNode = () => {
        const { view } = this.editor
        const { state, dispatch } = view
        dispatch(state.tr.deleteSelection().scrollIntoView())
    }

    handleSelect = (props: { node: ProseMirrorNode; isEditable: boolean; getPos: () => number }) => () => {
        const {
            view: { state, dispatch }
        } = this.editor
        const $pos = state.doc.resolve(props.getPos())
        const transaction = state.tr.setSelection(new NodeSelection($pos))
        dispatch(transaction)
    }

    handleCodeChanged = (code: string) => {
        const {
            view: { dispatch, state }
        } = this.editor

        const node = state.schema.nodes.code_fence.createAndFill(defaultAttrs, state.schema.text(code))

        if (node) {
            dispatch(
                state.tr.replaceSelectionWith(node).setMeta("codeFenceSubType", CodeFenceNodeMeta.Code).scrollIntoView()
            )
        }
    }

    handleWidgetChanged = (widget: Widget) => {
        const {
            view: { dispatch, state }
        } = this.editor

        dispatch(
            state.tr
                .replaceSelectionWith(
                    state.schema.nodes.code_fence.create({
                        ...defaultAttrs,
                        language: MarkdownCustomLanguages.Widget,
                        parameters: { id: widget.id, title: widget.title, description: widget.description },
                        markdown: widget.json
                    })
                )
                .scrollIntoView()
        )
    }

    component = (props: { node: ProseMirrorNode; isEditable: boolean; isSelected: boolean; getPos: () => number }) => {
        const {
            node: {
                attrs: { language, keyboard, parameters, initial, markdown },
                textContent
            },
            isEditable,
            isSelected
        } = props

        switch (language) {
            case MarkdownCustomLanguages.Buttons:
                return (
                    <Keyboard
                        projectId={this.options.projectId}
                        isEditable={isEditable}
                        buttonRows={keyboard}
                        block={parameters}
                        handleUpdateKeyboard={this.handleUpdateButtons}
                        onDeleteKeyboard={this.handleRemoveNode}
                        handleSelect={this.handleSelect(props)}
                        handleOpenArticle={this.options.onOpenArticle}
                        dispatch={this.options.dispatch}
                    />
                )
            case MarkdownCustomLanguages.NextAnswer:
                return <NextAnswer isSelected={isSelected} onSelect={this.handleSelect(props)} />
            case MarkdownCustomLanguages.Widget:
                return (
                    <WidgetBlock
                        widget={{ ...defaultWidget, ...parameters, json: markdown }}
                        isEditable={isEditable}
                        onSelect={this.handleSelect(props)}
                        onChange={this.handleWidgetChanged}
                        onDelete={this.handleRemoveNode}
                    />
                )
            case MarkdownCustomLanguages.QuickActions:
                return (
                    <QuickActions
                        onClick={this.handleSelect(props)}
                        actions={getQuickActions(markdown)}
                        isEditable={isEditable}
                        onLocationChange={this.options.handleLocationChange}
                    />
                )
            default:
                return (
                    <CodeBlockEditor
                        initialContent={textContent}
                        initial={initial}
                        isEditable={isEditable}
                        onSelect={this.handleSelect(props)}
                        onChange={this.handleCodeChanged}
                        onRemove={this.handleRemoveNode}
                    />
                )
        }
    }

    toMarkdown(state: MarkdownSerializerState, node: ProseMirrorNode) {
        const { parameters, language, keyboard, markdown } = node.attrs
        const params = dataToParamString(parameters, {
            separateByComma: isCommaUsedToSeparate(language),
            putInQuotes: isQuotesRequired(language)
        })

        state.write(`\`\`\`${language || ""}${params}\n`)

        if (language === MarkdownCustomLanguages.Buttons) {
            state.text(keyboardToString(keyboard), false)
        } else if (isMarkdownLanguage(language)) {
            state.text(markdown, false)
        } else {
            state.text(node.textContent, false)
        }

        state.ensureNewLine()
        state.write("```")
        state.closeBlock(node)
    }

    get markdownToken() {
        return "fence"
    }

    inputRules({ type }: { type: NodeType }) {
        return [
            textblockTypeInputRule(/^```$/, type, () => {
                return {
                    language: defaultLanguage,
                    parameters: {},
                    keyboard: {},
                    markdown: "",
                    initial: true
                }
            })
        ]
    }

    parseMarkdown() {
        return {
            block: "code_fence",
            getAttrs: (tok: Token) => {
                const { language, parameters } = getLanguageWithParameters(tok.info)
                return {
                    ...defaultAttrs,
                    language,
                    parameters: parameters ?? {},
                    keyboard: language === MarkdownCustomLanguages.Buttons ? getKeyboard(tok.content) : {},
                    markdown: isMarkdownLanguage(language) ? tok.content : ""
                }
            }
        }
    }
}

const defaultAttrs = { language: defaultLanguage, parameters: {}, keyboard: {}, markdown: "", initial: false }

export default KeyboardNode

export class CodeBlock extends KeyboardNode {
    get name() {
        return "code_block"
    }

    get markdownToken() {
        return "code_block"
    }
}

export class MarkdownNode extends KeyboardNode {
    get name() {
        return MarkdownCustomLanguages.Markdown
    }

    get markdownToken() {
        return MarkdownCustomLanguages.Markdown
    }
}

export class QuickActionsNode extends KeyboardNode {
    get name() {
        return MarkdownCustomLanguages.QuickActions
    }

    get markdownToken() {
        return MarkdownCustomLanguages.QuickActions
    }
}
