import React from "react"
import { nameof2 } from "../../../utility/common/nameof"
import { DialogEvaluationValues, WebChatChannelValues } from "../../../models/webChatValues"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import { FieldArray, useFormikContext } from "formik"
import { ParamValues } from "../../../models/parameterDeclaration"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import { formTranslation } from "../../../locales/form"

const tNamespace = "channel:form."

const WebChatDialogEvaluationSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values } = useFormikContext<WebChatChannelValues>()

    return (
        <FormAccordion title={t(`${tNamespace}rating-dialog`)}>
            <DebouncedCheckBox
                id="formShowRatingBox"
                name={nameof2<WebChatChannelValues, DialogEvaluationValues>(
                    "DialogEvaluationSettings",
                    "showRatingBox"
                )}
                label={t(`${tNamespace}show-call-rating`)}
            />
            <div className="channel-form__subfield">
                <DebouncedValidatableInput
                    id="formRatingAnswerText"
                    type="text"
                    name={nameof2<WebChatChannelValues, DialogEvaluationValues>(
                        "DialogEvaluationSettings",
                        "ratingAnswerText"
                    )}
                    label={t(`${tNamespace}message`)}
                    disabled={!values.DialogEvaluationSettings.showRatingBox}
                    placeholder={t(formTranslation.enterText)}
                />
            </div>
            <DebouncedCheckBox
                id="formRequireScoreRequest"
                name={nameof2<WebChatChannelValues, DialogEvaluationValues>(
                    "DialogEvaluationSettings",
                    "requireScoreRequest"
                )}
                label={t(`${tNamespace}request-score-when-closing-chat`)}
            />
            <DebouncedCheckBox
                id="formRequireDialogFinishWithEstimate"
                name={nameof2<WebChatChannelValues, DialogEvaluationValues>(
                    "DialogEvaluationSettings",
                    "requireDialogFinishWithEstimate"
                )}
                label={t(`${tNamespace}finish-dialog-after-evaluation`)}
            />
            <DebouncedCheckBox
                id="formQuestionBeforeRatingDialog"
                name={nameof2<WebChatChannelValues, DialogEvaluationValues>(
                    "DialogEvaluationSettings",
                    "questionBeforeRatingDialog"
                )}
                label={t(`${tNamespace}question-before-rating-dialog`)}
            />
            <DebouncedCheckBox
                id="formRatingAliases"
                name={nameof2<WebChatChannelValues, DialogEvaluationValues>(
                    "DialogEvaluationSettings",
                    "ratingAliasesEnabled"
                )}
                label={t(`${tNamespace}set-rating-aliases`)}
            />
            <div className="extra-settings">
                <FieldArray
                    name={nameof2<WebChatChannelValues, DialogEvaluationValues>(
                        "DialogEvaluationSettings",
                        "ratingAliases"
                    )}
                    render={({ name }) =>
                        values.DialogEvaluationSettings.ratingAliases.map((_, index) => (
                            <DebouncedValidatableInput
                                key={index}
                                id={`formRatingAlias${index}`}
                                type="text"
                                name={`${name}.${index}`}
                                label={`${t(`${tNamespace}rating`)} ${index + 1}`}
                                labelCol={{ md: 3 }}
                                inputCol={{ md: 9 }}
                                disabled={!values.DialogEvaluationSettings.ratingAliasesEnabled}
                                placeholder={t(formTranslation.enterText)}
                            />
                        ))
                    }
                />
            </div>
            <DebouncedCheckBox
                id="formNotSendFinishMessage"
                name={nameof2<WebChatChannelValues, ParamValues>(
                    "Params",
                    "do_not_send_finish_message_when_dialog_is_not_effective"
                )}
                label={t(`${tNamespace}not-send-finish-message-if-ineffective-dialog`)}
            />
        </FormAccordion>
    )
}

export default WebChatDialogEvaluationSettings
