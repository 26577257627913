import { useCallback, useEffect } from "react"
import { useActions } from "../../hooks/useAction"
import { MessageAttachment } from "../../models/Dialogs/message"
import { testId } from "../../utility/tests/testId"
import AttachmentItem from "../AttachmentItem/AttachmentItem"
import DialogSystem from "../DialogSystem/DialogSystem"
import styles from "./GalleryBox.module.scss"
import { GALLERY_VIEW_DIALOG_ID } from "../GalleryPreviewer/GalleryPreviewer"

export const GALLERY_BOX_DIALOG_ID = "GALLERY_BOX_DIALOG_ID"

export interface IGalleryBoxDialogProps extends Partial<React.ComponentPropsWithRef<typeof DialogSystem>> {
    attachments: MessageAttachment[]
    setAttachments: React.Dispatch<React.SetStateAction<MessageAttachment[]>>
}

export type TGalleryBoxDialogStateMeta = {
    attachments: MessageAttachment[]
}

export const GalleryBoxDialog: React.FC<IGalleryBoxDialogProps> = ({ attachments, setAttachments, ...props }) => {
    const { hideDialog, showDialog } = useActions()

    useEffect(() => {
        if (!attachments.length) {
            hideDialog({
                dialogId: GALLERY_BOX_DIALOG_ID
            })
        }
    }, [attachments, hideDialog])

    const handleAttachmentPreviewClosePress = useCallback(
        (url: string) => setAttachments([...attachments].filter(x => x.Url !== url)),
        [attachments, setAttachments]
    )

    const handleAttachmentImagePress = useCallback(
        (attachment: MessageAttachment) =>
            showDialog({
                dialogId: GALLERY_VIEW_DIALOG_ID,
                dialogMeta: {
                    name: attachment.Name,
                    url: attachment.Url,
                    mime: attachment.ContentType,
                    items: attachments.map(x => ({
                        name: x.Name,
                        url: x.Url,
                        mime: x.ContentType
                    }))
                }
            }),
        [attachments, showDialog]
    )

    const handleAttachmentClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation(), [])

    return (
        <DialogSystem {...props} dialogId={GALLERY_BOX_DIALOG_ID}>
            <div
                data-testid={testId.galleryBox}
                className={styles.galleryBox}
                onClick={() =>
                    hideDialog({
                        dialogId: GALLERY_BOX_DIALOG_ID
                    })
                }
            >
                <div className={styles.galleryBox__wrapper} onClick={handleAttachmentClick}>
                    {attachments.map(props => (
                        <AttachmentItem
                            key={props.Name}
                            containerClassName={styles.galleryBox__attachment}
                            title={props.Name}
                            url={props.Url}
                            size={props.Size}
                            onPressCloseButton={() => handleAttachmentPreviewClosePress(props.Url)}
                            onPressItem={() => handleAttachmentImagePress(props)}
                        />
                    ))}
                </div>
            </div>
        </DialogSystem>
    )
}
