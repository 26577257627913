import React from "react"
import { ClassProps } from "../../utility/common/props"
import ContextMenu from "../ContextMenu/ContextMenu"
import styles from "./SettingsContextMenu.module.scss"
import cn from "classnames"

export interface SettingsContextMenuProps extends ClassProps {
    children: React.ReactNode
    items: React.ReactNode
    onHide?: () => void
}

const SettingsContextMenu: React.FC<SettingsContextMenuProps> = props => {
    const { children, items, onHide, className } = props

    return (
        <ContextMenu
            items={items}
            placement={"bottom"}
            className={styles.settingsContextMenu}
            containerClassName={cn(styles.settingsContextMenu__container, className)}
            onHide={onHide}
            popperConfig={{
                modifiers: [
                    {
                        name: "flip",
                        enabled: true
                    }
                ]
            }}
        >
            {children}
        </ContextMenu>
    )
}

export default SettingsContextMenu
