import { IHub } from "./interfaces/IHub"
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr"
import { logError } from "../utility/common/logError"

const nextRetryDelayInMilliseconds = 5e3

export class Hub implements IHub {
    private readonly _connection: HubConnection
    private _reconnectingCallback?: () => void
    private _reconnectedCallback?: () => void
    private _startConnectTimeout?: NodeJS.Timeout

    constructor(url: string, useAllTransportSignalR?: boolean, useInfinityReconnect?: boolean) {
        let builder = new HubConnectionBuilder()
        builder = useAllTransportSignalR
            ? builder.withUrl(url)
            : builder.withUrl(url, { skipNegotiation: true, transport: HttpTransportType.WebSockets })
        builder = useInfinityReconnect
            ? builder.withAutomaticReconnect({
                  nextRetryDelayInMilliseconds: _ => nextRetryDelayInMilliseconds
              })
            : builder.withAutomaticReconnect()
        this._connection = builder.build()

        this._connection.onclose(() => {
            if (useInfinityReconnect) {
                this._reconnectingCallback?.()
                this._startConnectTimeout = setTimeout(this.connect, 3000)
            } else {
                if (this._startConnectTimeout) {
                    clearTimeout(this._startConnectTimeout)
                }
            }
        })
        this._connection.onreconnecting(_ => {
            this._reconnectingCallback?.()
        })
        this._connection.onreconnected(_ => {
            this._reconnectedCallback?.()
        })
    }

    public get isConnected() {
        return this._connection.state === HubConnectionState.Connected
    }

    public set reconnectingCallback(reconnectingCallback: () => void) {
        this._reconnectingCallback = reconnectingCallback
    }

    public set reconnectedCallback(reconnectedCallback: () => void) {
        this._reconnectedCallback = reconnectedCallback
    }

    async connect() {
        try {
            if (this._connection.state === HubConnectionState.Disconnected) {
                await this._connection.start()
            }
        } catch (e) {
            logError(e)
            this._startConnectTimeout = setTimeout(this.connect, 3000)
        }
    }

    async disconnect() {
        try {
            if (this._startConnectTimeout) {
                clearTimeout(this._startConnectTimeout)
            }
            const shouldStop =
                this._connection.state === HubConnectionState.Connected ||
                this._connection.state === HubConnectionState.Connecting
            if (shouldStop) {
                await this._connection.stop()
            }
        } catch (e) {
            logError(e)
        }
    }

    async invoke(methodName: string, ...args: unknown[]) {
        await this._connection.invoke(methodName, ...args)
    }

    async subscribe(methodName: string, ...args: unknown[]) {
        try {
            await this.connect()
            await this._connection.invoke(methodName, ...args)
        } catch (e) {
            logError(e)
        }
    }

    async unsubscribe(methodName: string, ...args: unknown[]) {
        try {
            await this._connection.invoke(methodName, ...args)
        } catch (e) {
            logError(e)
        }
    }

    registerEvent(methodName: string, callback: (...args: string[]) => void) {
        this._connection.on(methodName, callback)
    }

    unregisterEvent(methodName: string, callback: (...args: string[]) => void) {
        this._connection.off(methodName, callback)
    }

    registerEventJson(methodName: string, callback: (...args: unknown[]) => void) {
        this._connection.on(methodName, callback)
    }
}
