import { SimpleChannel } from "./channel"
import { TaskClient } from "./client"

export interface RouteTaskRequest {
    TaskId: string
    QueueId: string
    Type: string
    PreferredOperatorId?: string
    Channel: SimpleChannel
    Client: TaskClient
    CreatedAt: number
}

export interface AssignTaskRequest {
    TaskId: string
    OperatorId: string
    Type: string
    Channel: SimpleChannel
    Client: TaskClient
    CreatedAt: number
}

export interface MoveTaskRequest {
    TaskId: string
    QueueId: string
    OperatorId?: string
    CreatedAt?: number
}

export interface RerouteTaskRequest {
    TaskId: string
    OperatorId: string
    QueueId: string
    Message?: string
}

export interface SwitchTaskRequest {
    TaskId: string
    OperatorId: string
    QueueId: string
}

export enum TaskStatus {
    Pending,
    Assigned
}

export interface Task {
    Id: string
    TenantId: string
    QueueId?: string
    Type: string
    Status: TaskStatus
    ShortId?: string
    PreferredOperatorId?: string
    Channel: SimpleChannel
    Client: TaskClient
    CreatedAt: number
    AssignedAt: number
}

export interface TaskQueuedMsg {
    Task: Task
    QueueId: string
    ToIndividual: boolean
    CategoryId: string
}

export const isTaskQueuedMsg = (value: unknown): value is TaskQueuedMsg => {
    if (typeof value !== "object" || value === null) {
        return false
    }
    return (
        "Task" in value &&
        isTask((value as TaskQueuedMsg).Task) &&
        "QueueId" in value &&
        "ToIndividual" in value &&
        "CategoryId" in value
    )
}

export interface TasksMovedMsg {
    FromIndividual: boolean
    FromQueueId: string
    FromCategoryId: string
    ToQueueId: string
    ToIndividual: boolean
    ToCategoryId: string
    TasksIds: string[]
}

export const isTasksMovedMsg = (value: unknown): value is TasksMovedMsg => {
    return !!(
        typeof value === "object" &&
        value &&
        "FromIndividual" in value &&
        "FromQueueId" in value &&
        "FromCategoryId" in value &&
        "ToIndividual" in value &&
        "ToCategoryId" in value &&
        "ToQueueId" in value &&
        "TasksIds" in value
    )
}

export interface TaskDetachedMsg {
    TaskId: string
    OperatorId: string
}

export interface TaskAssignedMsg {
    Task: Task
    OperatorId: string
}

export interface TaskRoutedMsg {
    Task: Task
    OperatorId: string
    FromQueueId: string
    FromIndividual: boolean
    FromCategoryId: string
}

export interface TaskCompletedMsg {
    TaskId: string
    TenantId: string
    QueueId?: string
    OperatorId?: string
}

export interface TaskFromQueueDeletedMsg {
    TaskId: string
    TenantId: string
    FromQueueId: string
}

export type TaskOfAutomaticProcessing = {
    Id: string
    TenantId: string
    ChannelId: string
    ChannelType: string
    Client: TaskClient
    AgentId: string
    StartedAt: number
    FirstReplyAt: number
    CurrentFirstReplyAt: number
    RoutedAt: number
    CurrentRoutedAt: number
    EnqueuedAt: number
    ConnectedOperatorIds: string[]
}

export type TaskCommon = Task | TaskOfAutomaticProcessing

export function isTask(value: unknown): value is Task {
    return !!(
        typeof value === "object" &&
        value &&
        "Id" in value &&
        "TenantId" in value &&
        "Status" in value &&
        "Channel" in value &&
        "CreatedAt" in value
    )
}

export function isTaskOfAutomaticProcessing(value: unknown): value is TaskOfAutomaticProcessing {
    return !!(
        typeof value === "object" &&
        value &&
        "Id" in value &&
        "TenantId" in value &&
        "ChannelId" in value &&
        "ChannelType" in value &&
        "AgentId" in value &&
        "Client" in value
    )
}

export function isTaskCompletedMsg(value: unknown): value is TaskCompletedMsg {
    return !!(typeof value === "object" && value && "TaskId" in value && "TenantId" in value)
}

export function isTaskFromQueueDeletedMsg(value: unknown): value is TaskFromQueueDeletedMsg {
    return !!(typeof value === "object" && value && "TaskId" in value && "TenantId" in value && "FromQueueId" in value)
}
