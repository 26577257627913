export enum OperatorStatusValueDto {
    Online,
    Offline,
    DoNotRoute
}

export type SelectedOperatorStatusDto = {
    Value: OperatorStatusValueDto
    Description: string
    UpdatedAt: number
}

export const isSelectedOperatorStatusDto = (value: unknown): value is SelectedOperatorStatusDto => {
    if (typeof value !== "object" || value === null) {
        return false
    }

    const status = value as Partial<SelectedOperatorStatusDto>

    return (
        isValidOperatorStatusValueDto(status.Value) &&
        typeof status.Description === "string" &&
        typeof status.UpdatedAt === "number"
    )
}

const isValidOperatorStatusValueDto = (value: unknown): value is OperatorStatusValueDto => {
    return typeof value === "number" && Object.values(OperatorStatusValueDto).includes(value)
}
