import { ButtonFacebookType, ButtonPlacement } from "../../models/markdownButton"
import { ButtonsParser, defaultAttributes, MarkdownButton } from "./buttonsParser/buttonsParser"
import { TFunction } from "i18next"
import { logDebug } from "../common/logError"
import { splitSpacesExcludeQuotes } from "../common/splitSpacesExcludeQuotes"
import { MarkdownCustomLanguages } from "./markdownCustomLanguages"
import { getDefaultParameters } from "../../components/ContentEditor/extensions/Keyboard/KeyboardNode"

export type KeyboardButtons = { [rowIdx: string]: MarkdownButton[] }

export const defaultBlockValues = {
    additional_value: "",
    language: "",
    template: "",
    whatsapp_template_enabled: false
}

export const defaultButtonsParameters = {
    placement: ButtonPlacement.Inline,
    fb_type: ButtonFacebookType.Button
}

export const groupButtonsIntoRows = (buttons: MarkdownButton[]): KeyboardButtons => {
    return buttons.reduce((result: KeyboardButtons, button) => {
        result[button.Row] = result[button.Row] || []
        result[button.Row].push(button)
        return result
    }, {})
}

const getLanguageParams = (nodeAttrsParams: string, startParamsIndex: number): string => {
    let result = ""
    const endParamsIndex = nodeAttrsParams.indexOf(")")

    if (startParamsIndex === -1) {
        logDebug("getParamsFromNodeAttrs got not opened params block:", nodeAttrsParams)
    } else if (endParamsIndex === -1) {
        logDebug("getParamsFromNodeAttrs got not closed params block:", nodeAttrsParams)
    } else {
        result = nodeAttrsParams.substring(startParamsIndex + 1, endParamsIndex)
    }

    return result
}

export const isCommaUsedToSeparate = (language: string) => language === MarkdownCustomLanguages.Widget
export const isQuotesRequired = (language: string) => language === MarkdownCustomLanguages.Widget

export const getLanguageWithParameters = (language: string) => {
    let lang
    const startParamsIndex = language.indexOf("(")
    if (startParamsIndex === -1) {
        lang = language
    } else {
        lang = language.substr(0, startParamsIndex)
    }
    const parameters = getLanguageParams(language, startParamsIndex)

    return {
        language: lang,
        parameters: {
            ...getDefaultParameters(lang),
            ...splitSpacesExcludeQuotes(parameters).reduce<Record<string, string>>((result, p) => {
                const [key, value] = p.split(":")
                result[key] = value?.replace(/,$/, "").replace(/^"(.*)",?$/, "$1")
                return result
            }, {})
        }
    }
}

export const getKeyboard = (content: string) => {
    const buttons = ButtonsParser.getButtons(content)
    return groupButtonsIntoRows(buttons)
}

export const dataToParamString = (
    data: { [key: string]: string } | MarkdownButton,
    options?: { separateByComma?: boolean; putInQuotes?: boolean }
) => {
    const combinedData = Object.entries(data)
        .filter(([key, attr]) => attr && key !== "BodyMarkdown")
        .map(([key, attr]) => {
            const value = options?.putInQuotes && typeof attr === "string" ? `"${attr.replaceAll(/"/g, '\\"')}"` : attr
            return `${key}:${value}`
        })
        .join(options?.separateByComma ? ", " : " ")

    return combinedData ? `(${combinedData})` : ""
}

export const keyboardToString = (keyboard: { [rowIdx: string]: MarkdownButton[] }) => {
    return Object.entries(keyboard).reduce((str, [_, buttons]) => {
        const buttonsString = buttons.reduce((s, btn) => {
            const attributes = `${dataToParamString(btn)}`
            return `${s}[${btn.BodyMarkdown.replaceAll(/[[\]]/g, match => (match === "[" ? "(" : ")"))}]${attributes}\n`
        }, "")

        return `${str}\n::\n${buttonsString}`
    }, "")
}

export const markdownButtonDefault = (t: TFunction) => ({
    BodyMarkdown: `${t("knowledgeBase:article-buttons.button")}`,
    Row: 1,
    Id: "1",
    ...defaultAttributes
})
