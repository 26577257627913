import React, { RefObject, useCallback, useEffect, useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import {
    Article as KnowledgeBaseArticle,
    ArticleAnswer,
    ArticleAnswersFormValues,
    ArticleEditFormValues,
    ArticleKind
} from "../../models/article"
import { getValuesFromArticle } from "../../utility/knowledgeBase/knowledgeBase"
import { FormikProps } from "formik/dist/types"
import FormikArticleEditForm from "./FormikArticleEditForm"
import FormikScenarioArticleEditForm from "../ScenarioArticle/FormikScenarioArticleEditForm"
import { Prompt } from "react-router-dom"
import useExitPrompt from "../../utility/common/useExitPrompt"
import { setEditedArticleExtId, setEditedArticleSurvey } from "../../store/knowledgeBase/actions"
import { useDispatch } from "react-redux"

const TITLE_MAX_LENGTH = 10000
const tNamespace = "knowledgeBase:article-edit-form."

export interface ArticleEditFormProps {
    article: KnowledgeBaseArticle
    formRef: RefObject<FormikProps<ArticleEditFormValues>>
    onSubmit: (values: ArticleEditFormValues) => void
    onUpdateAnswer?: (answer: ArticleAnswer) => void
    currentAnswer?: ArticleAnswer
    answersForm?: ArticleAnswersFormValues
    onTransformToScenario?: () => void
    onSelectAnswer?: (answerId: string) => void
    transformToScenario?: boolean
    scenarioTouched?: boolean
    questionsCount?: number
    isArticleExpanded: boolean
}

const ArticleEditForm: React.FC<ArticleEditFormProps> = props => {
    const {
        article,
        formRef,
        onTransformToScenario,
        transformToScenario,
        onSubmit,
        currentAnswer,
        onSelectAnswer,
        onUpdateAnswer,
        answersForm,
        scenarioTouched,
        questionsCount,
        isArticleExpanded
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [answer, setAnswer] = useState<ArticleAnswer | undefined>(currentAnswer)
    const [answerChanged, setAnswerChanged] = useState<boolean>(false)
    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false)

    useEffect(() => {
        if (answersForm && answersForm.Answers && currentAnswer) {
            if (answer && answer.Id === currentAnswer.Id) {
                return
            }
            const foundedAnswer = answersForm.Answers.find(a => a.Id === currentAnswer.Id)
            setAnswer(foundedAnswer)
        }
    }, [answer, answersForm, currentAnswer])

    useEffect(() => {
        return () => {
            setShowExitPrompt(false)
        }
    }, [setShowExitPrompt])

    const handleUpdateAnswer = useCallback(
        (content: string) => {
            if (currentAnswer && onUpdateAnswer) {
                onUpdateAnswer({
                    ...currentAnswer,
                    Text: content
                })
                !answerChanged && setAnswerChanged(true)
            }
        },
        [currentAnswer, onUpdateAnswer, answerChanged]
    )

    const handleUpdate = useCallback(
        (values: ArticleEditFormValues, dirty: boolean) => {
            if ((dirty || answerChanged || scenarioTouched) && !showExitPrompt) {
                setShowExitPrompt(true)
            }
        },
        [setShowExitPrompt, showExitPrompt, answerChanged, scenarioTouched]
    )

    useEffect(() => {
        setEditedArticleExtId(dispatch, article.ExtId)
        setEditedArticleSurvey(dispatch, article.Survey)
    }, [dispatch, article])

    return (
        <Formik
            innerRef={formRef}
            initialValues={getValuesFromArticle(article)}
            validationSchema={() => {
                return Yup.object().shape({
                    Title: Yup.string().max(TITLE_MAX_LENGTH).required(`${tNamespace}title-required`),
                    Answer: Yup.string(),
                    Type: Yup.string(),
                    Tags: Yup.string()
                        .trim()
                        .matches(/^(#\S+)?(\s+#\S+)*$/),
                    Parameters: Yup.array().of(
                        Yup.object().shape({
                            SlotId: Yup.string(),
                            Value: Yup.string()
                        })
                    )
                })
            }}
            onSubmit={(values: ArticleEditFormValues) => {
                onSubmit(values)
            }}
        >
            {formikProps => (
                <>
                    {article.Kind === ArticleKind.Scenario || transformToScenario ? (
                        <FormikScenarioArticleEditForm
                            article={article}
                            {...formikProps}
                            t={t}
                            questionsCount={questionsCount}
                        />
                    ) : (
                        <FormikArticleEditForm
                            article={article}
                            answer={answer}
                            answersCount={answersForm?.Answers.length ?? 0}
                            onTransformToScenario={onTransformToScenario}
                            onUpdateAnswer={handleUpdateAnswer}
                            onSelectAnswer={onSelectAnswer}
                            questionsCount={questionsCount}
                            {...formikProps}
                            t={t}
                            isArticleExpanded={isArticleExpanded}
                        />
                    )}
                    <ArticleFormChangeDetector
                        values={formikProps.values}
                        dirty={formikProps.dirty}
                        onUpdate={handleUpdate}
                    />
                    <Prompt
                        message={t(`${tNamespace}unsaved-changes`)}
                        when={formikProps.dirty || answerChanged || !!scenarioTouched}
                    />
                </>
            )}
        </Formik>
    )
}

interface FormChangeDetectorProps {
    values: ArticleEditFormValues
    dirty: boolean
    onUpdate: (values: ArticleEditFormValues, dirty: boolean) => void
}

const ArticleFormChangeDetector: React.FC<FormChangeDetectorProps> = props => {
    const { values, dirty, onUpdate } = props
    useEffect(() => {
        onUpdate(values, dirty)
    }, [values, onUpdate, dirty])
    return null
}

export default ArticleEditForm
