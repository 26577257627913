import React, { useMemo } from "react"
import styles from "./DialogSystem.module.scss"
import cn from "classnames"
import { usePortal } from "../../hooks/usePortal"
import { useAppSelector } from "../../store/store"
import { useActions } from "../../hooks/useAction"
import { selectDialogShownState } from "../../store/dialogSystem/selectors"
import { testId } from "../../utility/tests/testId"

export const DIALOG_SYSTEM_PORTAL_MOUNT = "dialog-system-portal"

export interface IDialogSystemProps {
    dialogId: string | number
    dialogSysPortalMountId?: string
}

const DialogSystem: React.FC<React.PropsWithChildren<IDialogSystemProps>> = ({
    dialogSysPortalMountId,
    dialogId,
    children
}) => {
    const shown = useAppSelector(state => selectDialogShownState(state, dialogId))
    const { hideDialog } = useActions()

    const portalMount = useMemo(() => document.getElementById(dialogSysPortalMountId || DIALOG_SYSTEM_PORTAL_MOUNT), [])
    const Portal = usePortal(portalMount)

    return (
        <Portal>
            <div
                data-testid={testId.dialogSystem}
                className={cn(styles.dialogSystem__mainContainer, shown && styles.dialogSystem__mainContainer_shown)}
                onClick={() =>
                    hideDialog({
                        dialogId
                    })
                }
            >
                {children}
            </div>
        </Portal>
    )
}

export default DialogSystem
