import React, { ReactNode } from "react"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import styles from "./Article.module.scss"

export interface ArticleProps extends ClassProps {
    symbolCode: string
    headerTop: ReactNode
    headerRight?: ReactNode
    title: ReactNode | string
    widgets?: ReactNode
    body?: ReactNode | string
    isExpanded: boolean
    testId?: string
}

const Article: React.FC<ArticleProps> = props => {
    const { className, headerTop, headerRight, title, body, widgets, isExpanded, testId } = props

    return (
        <div className={cn(styles.article, className)} data-test-id={testId}>
            <div className={cn(styles.article__header, isExpanded && styles.article_expanded)}>
                <div className={styles.article__headerTop}>{headerTop}</div>
                <div className={styles.article__headerSection}>
                    <div className={styles.article__title}>{title}</div>
                    {headerRight && <div className={styles.article__headerRight}>{headerRight}</div>}
                </div>
            </div>
            {widgets && (
                <div className={cn(styles.article__parameters, isExpanded && styles.article_expanded)}>{widgets}</div>
            )}
            {body && <div className={cn(styles.article__body, isExpanded && styles.article_expanded)}>{body}</div>}
        </div>
    )
}

export default Article
