import React, { useCallback, useEffect, useMemo, useState } from "react"
import "./Classifiers.scss"
import { WithTitle } from "../../utility/common/withTitle"
import PageLayout from "../../components/PageLayout/PageLayout"
import AddButton from "../../components/AddButton/AddButton"
import cn from "classnames"
import { getArticleTypes } from "../../store/knowledgeBase/thunks"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProject } from "../../store/projects/selectors"
import { useTranslation } from "react-i18next"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ViewModifyClassifierButtons } from "../../permissions"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../../components/AlertDialog/AlertDialog"
import { formTranslation } from "../../locales/form"
import SettingsContextMenuItem from "../../components/SettingsContextMenuItem/SettingsContextMenuItem"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import Can from "../../components/Can/Can"
import ClassifierTypeForm from "../../components/Classifiers/ClassifierTypeForm/ClassifierTypeForm"
import ClassifierForm from "../../components/Classifiers/ClassifierForm/ClassifierForm"
import { ClassifierConfigurationProcessed, ClassifierType } from "../../models/classifier"
import {
    deleteClassifier,
    getClassifiers,
    getClassifierServers,
    subscribeOnClassifierEvents,
    unsubscribeOnClassifierEvents
} from "../../store/classifiers/thunks"
import ClassifierList from "../../components/Classifiers/ClassifierList/ClassifierList"
import { IsDeletable } from "../../utility/classifier/classifierForm"
import { selectClassifiers, selectCurrentClassifier } from "../../store/classifiers/selectors"
import { updateCurrentClassifier } from "../../store/classifiers/actions"

const tNamespace = "classifiers:"

enum SidebarContentType {
    chooseClassifierForm,
    addClassifierForm,
    updateClassifierForm
}

interface SidebarContentBase extends WithTitle {
    stepBack?: boolean
    onBack?: () => void
    settings?: JSX.Element[]
}

interface ChooseClassifierFormContent extends SidebarContentBase {
    type: SidebarContentType.chooseClassifierForm
}

interface AddClassifierFormContent extends SidebarContentBase {
    type: SidebarContentType.addClassifierForm
    classifierType: ClassifierType
}

interface UpdateClassifierFormContent extends SidebarContentBase {
    type: SidebarContentType.updateClassifierForm
    classifierType: ClassifierType
    classifier: ClassifierConfigurationProcessed
}

type SidebarContent = ChooseClassifierFormContent | AddClassifierFormContent | UpdateClassifierFormContent

const Classifiers: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const project = useSelector(selectCurrentProject)
    const classifiers = useSelector(selectClassifiers)
    const selectedClassifier = useSelector(selectCurrentClassifier)

    const [sidebarClosed, setSidebarClosed] = useState(true)
    const [sidebarContent, setSidebarContent] = useState<SidebarContent | null>(null)

    const [configIdToDelete, setConfigIdToDelete] = useState<string | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-confirmation.title`)}
            message={t(`${tNamespace}delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={handleDeleteClassifier}
            variant="danger"
            onExited={onExited}
        />
    ))

    const editAllowed = usePermissionsCheck([ViewModifyClassifierButtons])
    const linkedClassifier = useMemo(
        () => classifiers?.find(c => c.ClassifierConfigId === selectedClassifier?.LinkedClassifierConfigId),
        [classifiers, selectedClassifier?.LinkedClassifierConfigId]
    )

    useEffect(() => {
        if (project) {
            const projectId = project.id

            dispatch(getArticleTypes(projectId))
            dispatch(getClassifierServers(projectId))
            dispatch(getClassifiers(projectId))
            dispatch(subscribeOnClassifierEvents(projectId))
        }
        return () => {
            project && dispatch(unsubscribeOnClassifierEvents(project.id))
        }
    }, [dispatch, project])

    const closeSidebar = useCallback(() => {
        setSidebarClosed(true)
        updateCurrentClassifier(dispatch)
    }, [dispatch])

    const openSidebar = useCallback(() => setSidebarClosed(false), [])

    const handleChooseClassifier = useCallback(() => {
        closeSidebar()
        setSidebarContent({
            type: SidebarContentType.chooseClassifierForm,
            title: t(`${tNamespace}add-classifier`)
        })
        openSidebar()
    }, [closeSidebar, openSidebar, t])

    const handleAddClassifier = useCallback(
        (type: ClassifierType) => {
            setSidebarContent({
                type: SidebarContentType.addClassifierForm,
                classifierType: type,
                title: t(`${tNamespace}add-classifier`),
                stepBack: true,
                onBack: handleChooseClassifier
            })
        },
        [t, handleChooseClassifier]
    )

    const handleUpdateClassifier = useCallback(
        (classifier: ClassifierConfigurationProcessed) => {
            if (selectedClassifier.ClassifierConfigId === classifier.ClassifierConfigId) return

            closeSidebar()

            const settings = [
                <SettingsContextMenuItem
                    key={classifier.ClassifierConfigId}
                    id={classifier.ClassifierConfigId}
                    icon={faTrash}
                    text={t(formTranslation.delete)}
                    danger
                    onClick={() => {
                        setConfigIdToDelete(classifier.ClassifierConfigId)
                        openModal()
                    }}
                />
            ]
            setSidebarContent({
                type: SidebarContentType.updateClassifierForm,
                classifierType: classifier.Type,
                title: t(`${tNamespace}classifier-settings`),
                classifier,
                settings
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t, openModal, selectedClassifier]
    )

    const handleDeleteClassifier = useCallback(() => {
        if (project && configIdToDelete) {
            dispatch(deleteClassifier(project.id, configIdToDelete, closeSidebar))
        }
    }, [project, dispatch, closeSidebar, configIdToDelete])

    const renderSidebarContent = useCallback(() => {
        if (!sidebarContent || !project) return null

        switch (sidebarContent.type) {
            case SidebarContentType.chooseClassifierForm:
                return <ClassifierTypeForm onSelect={handleAddClassifier} />
            case SidebarContentType.addClassifierForm:
                return (
                    <ClassifierForm
                        project={project}
                        classifierType={sidebarContent.classifierType}
                        submitCallback={closeSidebar}
                        linkedClassifier={linkedClassifier}
                    />
                )
            case SidebarContentType.updateClassifierForm:
                return (
                    <ClassifierForm
                        project={project}
                        classifier={sidebarContent.classifier}
                        classifierType={sidebarContent.classifierType}
                        submitCallback={closeSidebar}
                        disabled={!editAllowed}
                        linkedClassifier={linkedClassifier}
                    />
                )
        }
    }, [closeSidebar, handleAddClassifier, sidebarContent, editAllowed, project, linkedClassifier])

    return (
        <PageLayout isSidebarCollapsed={sidebarClosed}>
            <PageLayout.Content className="classifiers">
                <div className="classifiers__header">
                    <span className="classifiers__title">{t(`${tNamespace}machine-learning`)}</span>
                    <Can permission={ViewModifyClassifierButtons}>
                        <AddButton
                            variant="outline-primary"
                            onClick={handleChooseClassifier}
                            className={cn("classifiers__btn-add", !sidebarClosed ? "hidden" : undefined)}
                            text={t(`${tNamespace}add-classifier`)}
                        />
                    </Can>
                </div>
                <ClassifierList onClick={handleUpdateClassifier} selectedClassifier={selectedClassifier} />
            </PageLayout.Content>
            <PageLayout.Sidebar
                title={sidebarContent ? sidebarContent.title : ""}
                onClose={closeSidebar}
                settings={
                    editAllowed && selectedClassifier && IsDeletable(selectedClassifier, linkedClassifier)
                        ? sidebarContent?.settings
                        : undefined
                }
                stepBack={sidebarContent?.stepBack}
                onBack={sidebarContent?.onBack}
            >
                {renderSidebarContent()}
            </PageLayout.Sidebar>
        </PageLayout>
    )
}

export default Classifiers
