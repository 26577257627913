import React, { useEffect, useMemo } from "react"
import { ClassProps } from "../../utility/common/props"
import Editor, { Props as EditorProps } from "rich-markdown-editor"
import KeyboardNode, { CodeBlock, MarkdownNode, QuickActionsNode } from "./extensions/Keyboard/KeyboardNode"
import { WithT } from "i18next"
import { Dispatch } from "../../utility/common/storeHelper"
import { makeArticleContentEditorDictionary } from "../../utility/articleContentEditor/dictionary"
import { getArticleRedactorEmbeds } from "../../utility/knowledgeBase/articleRedactorEmbeds"
import { articleContentEditorTranslation } from "../../locales/articleContentEditor"
import { uploadFile } from "../../utility/common/files"
import { PageLayoutContentId } from "../PageLayout/PageLayout"
import { getPerformanceObserver, logPerformanceObserverInitializingError } from "../../performance"

type Deps = ClassProps & WithT

export interface ContentEditorProps extends Deps {
    allowedFileExtensions?: string[]
    allowedVideoExtensions?: string[]
    content: string
    onChange: (getContent: () => string) => void
    isEditable?: boolean
    projectId?: string
    onOpenArticle?: (articleCode: string) => void
    onChangeLocation?: (path: string) => void
    onDispatch?: Dispatch
    disableExtensions?: EditorProps["disableExtensions"]
}

export const DEFAULT_EDITOR_DIS_EXT: NonNullable<EditorProps["disableExtensions"]> = [
    "code_block",
    "code_fence",
    "container_notice",
    "emoji",
    "widget",
    "quickactions"
]

const ContentEditor: React.FC<ContentEditorProps> = props => {
    const {
        projectId,
        t,
        onOpenArticle,
        onChangeLocation,
        onDispatch,
        content,
        allowedFileExtensions = [],
        allowedVideoExtensions = [],
        isEditable,
        onChange,
        className,
        disableExtensions = DEFAULT_EDITOR_DIS_EXT
    } = props

    useEffect(() => {
        let observer: PerformanceObserver | undefined
        try {
            observer = getPerformanceObserver("ContentEditor")
            observer?.observe({ type: "event", buffered: true })
        } catch (e) {
            logPerformanceObserverInitializingError("ContentEditor", e)
        }
        return () => {
            observer?.disconnect()
        }
    }, [])

    const dictionary = useMemo(() => makeArticleContentEditorDictionary(t), [t])
    const embeds = useMemo(
        () => getArticleRedactorEmbeds(t, allowedFileExtensions, allowedVideoExtensions),
        [t, allowedFileExtensions, allowedVideoExtensions]
    )

    const extensions = useMemo(() => {
        if (projectId && onOpenArticle && onChangeLocation && onDispatch) {
            return [
                new KeyboardNode({ projectId, onOpenArticle, onDispatch, t, onChangeLocation }),
                new CodeBlock({ projectId, onOpenArticle, onDispatch, t }),
                new MarkdownNode({ projectId, onOpenArticle, onDispatch, t }),
                new QuickActionsNode({ projectId, onOpenArticle, onDispatch, t })
            ]
        } else {
            return []
        }
    }, [projectId, onOpenArticle, onDispatch, t, onChangeLocation])

    return (
        <Editor
            className={className}
            defaultValue={content}
            value={content}
            placeholder={isEditable ? t(articleContentEditorTranslation["newLineEmpty"]) : undefined}
            readOnly={!isEditable}
            autoFocus={isEditable}
            onChange={onChange}
            uploadImage={isEditable ? uploadFile : undefined}
            uploadFile={isEditable ? uploadFile : undefined}
            uploadVideo={isEditable ? uploadFile : undefined}
            allowedFileExtensions={allowedFileExtensions}
            allowedVideoExtensions={allowedVideoExtensions}
            embeds={embeds}
            dictionary={dictionary}
            extensions={extensions}
            disableExtensions={disableExtensions}
            scrollableContainerId={PageLayoutContentId}
            hiddenTopOffset={120}
        />
    )
}

export default ContentEditor
