import React, { useCallback, useEffect, useState } from "react"
import styles from "./Slots.module.scss"
import SlotSettingsTable from "../../components/SlotSettings/SlotSettingsTable/SlotSettingsTable"
import { SlotDto } from "../../models/slot"
import { useTranslation } from "react-i18next"
import AddButton from "../../components/AddButton/AddButton"
import SearchInput from "../../components/SearchInput/SearchInput"
import { GET_SLOT_PAGE_FAILED } from "../../store/slots/constants"
import { ITEMS_PER_PAGE, RequestType as RT } from "../../models/pagination"
import usePaginationRequest from "../../utility/common/usePaginationRequest"
import { getSystemErrorMessage } from "../../core/error"
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage"

interface Props {
    projectId: string
    title: string
    onCreate: (callback: () => void) => () => void
    onSettingsClick: (onUpdate: (slot: SlotDto) => void) => (slot: SlotDto) => void
    onDelete: (slotId: string, callback: () => void) => void
}

const Slots: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { projectId, title, onCreate, onSettingsClick, onDelete } = props

    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const [data, setData] = useState<SlotDto[]>([])
    const [recordsTotal, setRecordsTotal] = useState(0)

    const handlePageChange = useCallback((pageIndex: number, searchValue = "") => {
        setCurrentPageIndex(pageIndex)
        setSearchValue(searchValue)
    }, [])

    const { asyncState: pageState, reload } = usePaginationRequest<SlotDto>(
        projectId,
        RT.SLOT,
        GET_SLOT_PAGE_FAILED,
        currentPageIndex,
        ITEMS_PER_PAGE,
        searchValue
    )

    const handleUpdateSlot = (updatedSlot: SlotDto) => {
        setData(data.map(s => (s.Id === updatedSlot.Id ? updatedSlot : s)))
    }

    const handleDeleteSlot = (slotId: string) => {
        onDelete(slotId, reload)
    }

    useEffect(() => {
        const data = pageState.data
        if (data) {
            setData(data.Items)
            setRecordsTotal(data.Filtered ? data.Filtered : 0)
        }
    }, [pageState])

    if (!projectId) return null

    return (
        <div className={styles.slots}>
            <div className={styles.slots__header}>
                <span className={styles.slots__title}>{title}</span>
                <AddButton
                    variant="outline-primary"
                    className={styles["slots__btn-add"]}
                    text={t("project:add-slot")}
                    onClick={onCreate(reload)}
                    disabled={pageState.inProcess}
                />
            </div>
            <SearchInput onChange={searchValue => handlePageChange(0, searchValue)} />
            <SlotSettingsTable
                slots={data}
                onSettingsClick={onSettingsClick(handleUpdateSlot)}
                onDelete={handleDeleteSlot}
                onPageChange={handlePageChange}
                recordsTotal={recordsTotal}
                pageIndex={currentPageIndex}
                error={pageState.error && <ErrorMessage text={getSystemErrorMessage(pageState.error, t)} />}
            />
        </div>
    )
}

export default Slots
