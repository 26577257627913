import React, { useCallback, useEffect, useState, useRef, useMemo } from "react"
import styles from "./DialogChat.module.scss"
import MessageList from "../../MessageList/MessageList"
import {
    MessageAttachment,
    MessageDirection,
    SendMessageRequest,
    ApiMessagesDU,
    UpdatedMessage
} from "../../../models/Dialogs/message"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"
import {
    DialogClient,
    IGetOperatorClientBadgesResponse,
    IGetOperatorsListResponse
} from "../../../models/Dialogs/dialog"
import { ClassProps } from "../../../utility/common/props"
import cn from "classnames"
import SettingsContextMenuItem from "../../SettingsContextMenuItem/SettingsContextMenuItem"
import SettingsContextMenu from "../../SettingsContextMenu/SettingsContextMenu"
import SettingsButton from "../../SettingsButton/SettingsButton"
import DialogTime from "../../DialogTime/DialogTime"
import AttachmentItem from "../../AttachmentItem/AttachmentItem"
import { useTranslation } from "react-i18next"
import { GALLERY_VIEW_DIALOG_ID, GalleryViewDialog } from "../../GalleryPreviewer/GalleryPreviewer"
import { useActions } from "../../../hooks/useAction"
import { GALLERY_BOX_DIALOG_ID, GalleryBoxDialog } from "../../GalleryBox/GalleryBox"
import { testId } from "../../../utility/tests/testId"
import {
    useLazyGetOperatorChannelsQuery,
    useLazyGetOperatorsListQuery,
    useGetDialogMessagesQuery,
    useSendMessageMutation,
    dialogsApi
} from "../../../api/controllers/dialogs"
import store, { useAppDispatch, useAppSelector } from "../../../store/store"
import DialogPath from "../DialogPath/DialogPath"
import DialogMessageInput from "../DialogMessageInput/DialogMessageInput"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import DialogChatLoader from "./DialogChatLoader"
import { throttle } from "../../../utility/common/throttle"
import { useGetDialogApiCachedEndpointData } from "../../../api/selectors"
import { selectDialogId, selectMessagesPaginationState } from "../../../store/dialogs/selectors"
import { deduplArrayObjects } from "../../../helpers/array"
import { actions } from "../../../store/dialogs/slice"

export interface RightDialogActions {
    dropdown: DialogAction[]
    main: DialogAction[]
}

export interface DialogAction {
    id: string
    name: string
    icon: IconProp
    onClick: () => void
    disabled?: boolean
}

export interface DialogChatProps {
    leftActions: DialogAction[]
    rightActions: RightDialogActions
    dispatchedTime?: number
    readonlyChat?: boolean
    onChannelSelect?: (name: string) => void
    dialog: {
        Client: DialogClient
        Channel: {
            Id: string
        }
        Project?: {
            Id: string
        }
    }
    isActiveDialog: boolean
}

const tAttachmentsNamespace = "dialogs:attachments"
const tMessagesNamespace = "dialogs:messages"

export const MESSAGES_PER_PAGE = 100

const buildSenderIdsByDir = (msgs: ApiMessagesDU[], dir: MessageDirection) => {
    const msgsPrepared = msgs
        .filter(x => {
            const Field = x.Fields as UpdatedMessage
            return Field.Direction === dir
        })
        .map(x => {
            const Field = x.Fields as UpdatedMessage
            return Field.Sender.Id
        })

    return deduplArrayObjects(msgsPrepared)
}

export type TSenderSignature = IGetOperatorClientBadgesResponse | IGetOperatorsListResponse

const DialogChat: React.FC<DialogChatProps> = props => {
    const { rightActions, leftActions, dispatchedTime, onChannelSelect, readonlyChat, dialog, isActiveDialog } = props
    const dispatch = useAppDispatch()

    const { Client } = dialog

    const initialMessageRequest: SendMessageRequest = {
        OmniUserId: dialog.Client.OmniUserId,
        ChannelId: dialog.Channel.Id,
        StartNewDialog: isActiveDialog ? false : true,
        Attachments: [],
        Actions: [],
        Text: "",
        Meta: {}
    }

    const { t } = useTranslation()
    const { showDialog } = useActions()
    const [attachments, setAttachments] = useState<MessageAttachment[]>([])
    const getMessagesRequestParams = useAppSelector(selectMessagesPaginationState)
    const lastMessageRef = useRef<HTMLDivElement>(null)
    const dialogChatBodyRef = useRef<HTMLDivElement>(null)
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const selectedDialogId = useAppSelector(selectDialogId)

    const getOperatorClientsBadgesSelector = dialogsApi.endpoints.getOperatorClientsBadges.select()
    const getOperatorClientsBadgesQuery = getOperatorClientsBadgesSelector(store.getState())

    const projectId = useMemo(() => {
        if (dialog.Project) {
            return dialog.Project.Id
        }

        return getOperatorClientsBadgesQuery.data!.find(x => x.OmniUserId === dialog.Client.OmniUserId)!.ProjectId
    }, [getOperatorClientsBadgesQuery, dialog])

    const throttledSetMessagesRequestParams = useCallback(
        throttle((newCount: number) => {
            dispatch(actions.setMessagesPaginationState({ Count: newCount, FromTodayDialogs: false }))
        }, 2000),
        []
    )

    const onChangePagination = (newCount: number) => {
        const { data, isLoading } = getDialogMessagesQuery

        if (!isLoading && data?.HasMore) {
            throttledSetMessagesRequestParams(newCount)
        }
    }

    const getDialogMessagesQuery = useGetDialogMessagesQuery(
        {
            ...getMessagesRequestParams!,
            ProjectId: projectId
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true
        }
    )

    const {
        isLoading: isMessagesLoading,
        isError: isMessagesError,
        isUninitialized: isMessagesUnitialized,
        isFetching: isMessagesFetching,
        error,
        data: response
    } = getDialogMessagesQuery

    const [sendMessageMutation, sendMessageState] = useSendMessageMutation()

    const { isLoading: isSendMessageLoading } = sendMessageState
    const [sendersSignature, setSendersSignature] = useState<TSenderSignature[]>([])

    // const [getMessageSenderTrigger, getMessageSenderQuery] = useLazyGetDialogPersonQuery()

    const [getOperatorsListTrigger] = useLazyGetOperatorsListQuery({
        refetchOnReconnect: true
    })

    const [getOperatorChannelsTrigger, getOperatorChannelsQuery] = useLazyGetOperatorChannelsQuery({
        refetchOnReconnect: true
    })

    const dialogChatInitialSetup = useCallback(async () => {
        const operators = buildSenderIdsByDir(response!.Messages, 1)

        const getOperatorChannelsTriggerCont = getOperatorChannelsTrigger({
            omniUserId: Client.OmniUserId,
            projectId
        })

        const getOperatorsListTriggerCont = getOperatorsListTrigger({
            projectId,
            operators
        })

        await getOperatorChannelsTriggerCont
        const getOperatorsListQuery = await getOperatorsListTriggerCont

        const operatorsSignature = getOperatorsListQuery.data || []
        setSendersSignature([...operatorsSignature, Client])
    }, [Client, getOperatorsListTrigger, getOperatorChannelsTrigger, projectId, response])

    useEffect(() => {
        if (!projectId) {
            return
        }

        if (!response?.Messages) {
            return
        }

        const operators = buildSenderIdsByDir(response!.Messages, 1)

        const getOperatorChannelsTriggerCont = getOperatorChannelsTrigger({
            omniUserId: Client.OmniUserId,
            projectId
        })

        const getOperatorsListTriggerCont = getOperatorsListTrigger({
            projectId,
            operators
        })

        dialogChatInitialSetup()

        return () => {
            getOperatorChannelsTriggerCont.abort()
            getOperatorsListTriggerCont.abort()
            getOperatorChannelsTriggerCont.unsubscribe()
            getOperatorsListTriggerCont.unsubscribe()
        }
    }, [dialogChatInitialSetup, Client, getOperatorsListTrigger, getOperatorChannelsTrigger, projectId, response])

    const handleAttachmentPreviewClosePress = useCallback(
        (url: string) => setAttachments([...attachments].filter(x => x.Url !== url)),
        [attachments]
    )

    const handleAttachmentSelect = (attachment: MessageAttachment) => {
        setAttachments(prev => [...prev, attachment])
    }

    const handleAttachmentImagePress = useCallback(
        (attachment: MessageAttachment) =>
            showDialog({
                dialogId: GALLERY_VIEW_DIALOG_ID,
                dialogMeta: {
                    name: attachment.Name,
                    url: attachment.Url,
                    mime: attachment.ContentType,
                    items: attachments.map(x => ({
                        name: x.Name,
                        url: x.Url,
                        mime: x.ContentType
                    }))
                }
            }),
        [attachments, showDialog]
    )

    const handleAttachmentsMoreShow = useCallback(
        () =>
            showDialog({
                dialogId: GALLERY_BOX_DIALOG_ID,
                dialogMeta: {
                    attachments,
                    setAttachments
                }
            }),
        [attachments, setAttachments, showDialog]
    )

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const renderLeftActions = () => {
        return leftActions.map(a => <DialogChatAction key={`${a.id}-left-actions`} action={a} />)
    }

    const renderRightActions = () => {
        const result = rightActions.main.map(a => <DialogChatAction key={`${a.id}-right-actions-main`} action={a} />)
        if (rightActions.dropdown.length > 0) {
            const items = rightActions.dropdown.map(a => (
                <SettingsContextMenuItem
                    key={`${a.id}-right-actions-dropdown`}
                    id={a.id}
                    onClick={a.onClick}
                    text={a.name}
                    icon={a.icon}
                />
            ))
            result.push(
                <SettingsContextMenu key="right-actions-dropdown" items={items}>
                    <SettingsButton />
                </SettingsContextMenu>
            )
        }
        return result
    }

    const getDialogQueryCachedData = useGetDialogApiCachedEndpointData({
        endpoint: "getDialog",
        selectArgs: selectedDialogId
    })

    const getSender = (message: UpdatedMessage) =>
        sendersSignature.find(x => x.OmniUserId === message.Sender.Id) as TSenderSignature

    const onWheelOrScroll = () => {
        const currEl = dialogChatBodyRef.current
        if (currEl && currEl.scrollTop - 10 <= currEl.offsetHeight - currEl.scrollHeight) {
            onChangePagination(getMessagesRequestParams!.Count + MESSAGES_PER_PAGE)
        }
    }

    const handleClickLoadMore = () => {
        onChangePagination(getMessagesRequestParams!.Count + MESSAGES_PER_PAGE)
    }

    const handleMessageSend = async (message = "") => {
        if (!message && !attachments.length) {
            return
        }

        if (!projectId) {
            return
        }

        await sendMessageMutation({
            message: {
                ...initialMessageRequest,
                Text: message,
                Attachments: attachments
            },
            body: getMessagesRequestParams!,
            projectId
        })

        setAttachments([])
    }

    useEffect(() => {
        if (!isSendMessageLoading) {
            scrollToBottom()
        }
    }, [sendMessageState, isSendMessageLoading])

    const lastMessageEntity = response?.Messages.filter(x => "AddedDate" in x.Fields).sort((a, b) => {
        if ("AddedDate" in a.Fields && "AddedDate" in b.Fields) {
            return b.Fields.AddedDate - a.Fields.AddedDate
        }

        return 0
    })

    const allUserChannels = getOperatorChannelsQuery.data

    const lastMessageChannelId =
        lastMessageEntity?.length && "ChannelId" in lastMessageEntity[0].Fields
            ? lastMessageEntity[0].Fields.ChannelId
            : null

    useEffect(() => {
        if (!onChannelSelect || !allUserChannels?.length) {
            return
        }

        if (lastMessageChannelId) {
            onChannelSelect(lastMessageChannelId)
        } else {
            if (allUserChannels?.length) {
                onChannelSelect(allUserChannels[0].channel_id)
            }
        }
    }, [lastMessageChannelId, allUserChannels])

    const lastMessageChannelEnity = allUserChannels?.find(x => x.channel_id === lastMessageChannelId)

    // TODO: Запросить дизайн
    if (isMessagesUnitialized) return <ErrorMessage text={t(`${tMessagesNamespace}.list-empty`)} />

    if (isMessagesLoading) return <DialogChatLoader />

    // TODO: Запросить дизайн, не путать с isMessagesLoading (который срабатывает только в первый раз)
    // if (isMessagesFetching) return <SomeLoader />

    // TODO: Запросить дизайн
    if (isMessagesError && error) {
        return <ErrorMessage text={t(`${tMessagesNamespace}.list-loading-error`)} />
    }

    return (
        <div className={styles.dialogChat} data-test-id={testId.dialogChat}>
            <div className={styles.dialogChat__header}>
                {dispatchedTime && <DialogTime dispatchedTime={dispatchedTime} />}
                <div>{renderLeftActions()}</div>
                <div>{renderRightActions()}</div>
            </div>
            <div
                className={styles.dialogChat__body}
                onScroll={onWheelOrScroll}
                onWheel={onWheelOrScroll}
                ref={dialogChatBodyRef}
            >
                <div className="message-list-footer" ref={messagesEndRef} />
                <MessageList getSender={getSender} messages={response!.Messages} lastMessageRef={lastMessageRef} />
                {getMessagesRequestParams?.FromTodayDialogs && getDialogMessagesQuery.data?.HasMore && (
                    <button className={styles.dialogChat__loadMore} onClick={handleClickLoadMore}>
                        {t(`${tMessagesNamespace}.load-more`)}
                    </button>
                )}
            </div>
            <div className={styles.dialogChat__footer}>
                <div className={styles.dialogChat__window}>
                    <div className={styles.dialogChat__inputHeader}>
                        {getDialogQueryCachedData.data ? (
                            <DialogPath
                                project={getDialogQueryCachedData.data.Project}
                                channel={getDialogQueryCachedData.data.Channel}
                                onChannelSelect={onChannelSelect}
                                channels={allUserChannels}
                                showDropdownArrow
                            />
                        ) : (
                            <DialogPath
                                onChannelSelect={onChannelSelect}
                                channels={allUserChannels}
                                channel={
                                    lastMessageChannelEnity
                                        ? {
                                              Id: lastMessageChannelEnity.channel_id,
                                              Title: lastMessageChannelEnity.channel_title,
                                              Type: lastMessageChannelEnity.channel_type
                                          }
                                        : null
                                }
                                showDropdownArrow
                            />
                        )}
                    </div>
                    <DialogMessageInput
                        onAttachmentSelect={handleAttachmentSelect}
                        onSend={handleMessageSend}
                        isAdvanced
                        readonlyChat={readonlyChat}
                    />
                </div>
            </div>
            <div className={styles.dialogChat__attachments}>
                {[...attachments].splice(0, 4).map(props => (
                    <AttachmentItem
                        containerClassName={styles.dialogChat__attachment}
                        title={props.Name}
                        url={props.Url}
                        size={props.Size}
                        onPressCloseButton={() => handleAttachmentPreviewClosePress(props.Url)}
                        onPressItem={() => handleAttachmentImagePress(props)}
                    />
                ))}
                {attachments.length > 4 && (
                    <div className={styles.dialogChat__moreFiles} onClick={handleAttachmentsMoreShow}>
                        {t(`${tAttachmentsNamespace}.moreAtchFiles`, {
                            filesCount: attachments.length - 4
                        })}
                    </div>
                )}
            </div>
            <GalleryBoxDialog attachments={attachments} setAttachments={setAttachments} />
            <GalleryViewDialog />
        </div>
    )
}

const DialogChatAction: React.FC<{ action: DialogAction } & ClassProps> = ({ action, className }) => {
    return (
        <Button
            id={action.id}
            variant="link"
            onClick={action.onClick}
            className={cn(styles.dialogChat__action, className)}
            disabled={action.disabled}
        >
            <FontAwesomeIcon icon={action.icon} />
        </Button>
    )
}

export default DialogChat
