import * as React from "react"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { projectPath } from "../../routerPaths"
import { push } from "connected-react-router"
import { actions } from "../../store/dialogs/slice"
import styles from "./MenusFrame.module.scss"

interface IntegrationFrameProps {
    src: string
    title: string
    id: string
}

export const MenusFrame: React.FC<IntegrationFrameProps> = props => {
    const { src, title, id } = props

    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    const iframeRef = useRef<HTMLIFrameElement>(null)

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data) {
                switch (event.data.type) {
                    case "iframeHeight":
                        if (event.data.id === id) {
                            const { height } = event.data
                            if (iframeRef.current) {
                                Object.assign(iframeRef.current.style, {
                                    height,
                                    width: height === "100vh" ? "450px" : "420px",
                                    zIndex: height === "100vh" ? "9999" : "auto",
                                    position: height === "100vh" ? "fixed" : "relative"
                                })
                            }
                        }
                        return
                    case "jumpToClient":
                        if (projectId && event.data.omniUserId) {
                            dispatch(actions.setCurrentOperatorClientId(event.data.omniUserId))
                            dispatch(push(`${projectPath}/${projectId}/dialogs`))
                        }
                        return
                }
            }
        }

        window.addEventListener("message", handleMessage)

        return () => {
            window.removeEventListener("message", handleMessage)
        }
    }, [dispatch, id, iframeRef, projectId])

    return (
        <iframe
            className={styles.menusFrame}
            title={title}
            ref={iframeRef}
            id={`iframe-${title}`}
            src={src}
            width="100%"
            loading="lazy"
        />
    )
}
