import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { FormikProps } from "formik/dist/types"
import {
    Article as KnowledgeBaseArticle,
    ArticleAnswer,
    ArticleEditFormValues,
    ArticleKind
} from "../../models/article"
import { WithT } from "i18next"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import Article from "../Article/Article"
import { nameof } from "../../utility/common/nameof"
import { Button, Form } from "react-bootstrap"
import "./ArticleEditForm.scss"
import { FormikArticleTypeSelect } from "../ArticleType/ArticleType"
import { FormikArticleTags } from "../ArticleTags/ArticleTags"
import { FormikArticleParameters } from "../ArticleParameters/ArticleParameters"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/pro-light-svg-icons/faAngleRight"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"
import { useField, useFormikContext } from "formik"
import FormikTextareaAutosize from "../FormikTextareaAutosize/FormikTextareaAutosize"
import ArticleKindSelect from "../ArticleKindSelect/ArticleKindSelect"
import ArticleAddFunctionsButton from "../ArticleAddFunctionsButton/ArticleAddFunctionsButton"
import debounce from "lodash/debounce"
import ArticleToolbar from "../ArticleToolbar/ArticleToolbar"
import ArticleViewContext from "../ArticleViewProvider/ArticleViewContext"
import { ARTICLE_CONTENT_UPDATE_DELAY } from "../../utility/knowledgeBase/constants"
import ArticleContentEditor from "../ArticleContentEditor/ArticleContentEditor"

const tNamespace = "knowledgeBase:article-edit-form."

interface Props {
    article: KnowledgeBaseArticle
    answer?: ArticleAnswer
    answersCount?: number
    onUpdateAnswer?: (content: string) => void
    onTransformToScenario?: () => void
    onSelectAnswer?: (answerId: string) => void
    questionsCount?: number
    isArticleExpanded: boolean
}

const URL_SLOT = "sys_url"

const getParamId = () => Math.random().toString(36)

const FormikArticleEditForm: React.FC<Props & FormikProps<ArticleEditFormValues> & WithT> = props => {
    const {
        article,
        onTransformToScenario,
        handleSubmit,
        t,
        onSelectAnswer,
        onUpdateAnswer,
        answersCount,
        answer,
        questionsCount,
        isArticleExpanded
    } = props
    const [titleField, titleMeta] = useField<string>(nameof<ArticleEditFormValues>("Title"))
    const { availableKinds } = useContext(ArticleViewContext)
    const { onAddSlot, onChooseAnswer, onOpenQuestions } = useContext(ArticleContext)
    const { setFieldValue, values } = useFormikContext<ArticleEditFormValues>()

    useEffect(() => {
        setFieldValue(nameof<ArticleEditFormValues>("Expanded"), isArticleExpanded, false)
    }, [isArticleExpanded, setFieldValue])

    const handleSelectSlot = (slotId: string) => {
        setFieldValue(
            nameof<ArticleEditFormValues>("Parameters"),
            [
                ...values.Parameters,
                {
                    SlotId: slotId,
                    Value: "",
                    Id: getParamId()
                }
            ],
            false
        )
    }

    const handleSelectKind = (kind: ArticleKind) => {
        setFieldValue(nameof<ArticleEditFormValues>("Kind"), kind, false)
        switch (kind) {
            case ArticleKind.Common:
                break
            case ArticleKind.External:
                if (!values.Parameters.some(v => v.SlotId === URL_SLOT)) {
                    setFieldValue(
                        nameof<ArticleEditFormValues>("Parameters"),
                        [
                            ...values.Parameters,
                            {
                                SlotId: URL_SLOT,
                                Value: "",
                                Id: getParamId()
                            }
                        ],
                        false
                    )
                }
                break
            case ArticleKind.Scenario:
                onTransformToScenario?.()
                break
        }
    }

    const handleContentChange = useCallback(
        (content: string) => {
            if (!onUpdateAnswer) {
                return
            }
            onUpdateAnswer(content)
        },
        [onUpdateAnswer]
    )

    const handleContentChangedDebounced = useMemo(
        () => debounce(handleContentChange, ARTICLE_CONTENT_UPDATE_DELAY),
        [handleContentChange]
    )

    const handleChooseAnswer = () => onChooseAnswer(true, true, onSelectAnswer)
    const handleOpenAnswer = () => onChooseAnswer(true, false, onSelectAnswer)

    return (
        <Form className="article-edit-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <Article
                symbolCode={article.SymbolCode}
                headerTop={
                    <>
                        <FormikArticleTypeSelect />
                        <FormikArticleTags />
                    </>
                }
                headerRight={
                    <ArticleKindSelect kinds={availableKinds} currentKind={values.Kind} onSelect={handleSelectKind} />
                }
                title={
                    <FormikTextareaAutosize
                        id="article-edit-form-title"
                        className="article-edit-form__input article-edit-form__title"
                        field={titleField}
                        meta={titleMeta}
                        placeholder={t(`${tNamespace}title`)}
                    />
                }
                widgets={
                    <>
                        <ArticleAddFunctionsButton
                            t={t}
                            onCreateAnswer={handleChooseAnswer}
                            onCreateParameters={() => onAddSlot(handleSelectSlot)}
                        />
                        <ArticleToolbar
                            onOpenAnswers={handleOpenAnswer}
                            answersCount={answersCount}
                            onOpenQuestions={() => onOpenQuestions(true)}
                            questionsCount={questionsCount}
                        />
                        <FormikArticleParameters
                            addButton={
                                <Button
                                    variant="light"
                                    className="article-edit-form__add-parameter-btn"
                                    onClick={() => onAddSlot(handleSelectSlot)}
                                >
                                    {t(`${tNamespace}add-parameter`)}
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </Button>
                            }
                        />
                    </>
                }
                body={
                    <ArticleContentEditor
                        content={answer?.Text ?? ""}
                        onChange={handleContentChangedDebounced}
                        isEditable
                        t={t}
                    />
                }
                isExpanded={isArticleExpanded}
            />
        </Form>
    )
}

export default FormikArticleEditForm
