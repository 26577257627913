import React, { FormEvent, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import FormContent from "../../FormHelpers/Content/FormContent"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { nameof2, nameof3 } from "../../../utility/common/nameof"
import {
    AutoGreetingOperatorProjectSettings,
    ClientSurveyOperatorProjectSettings,
    CommonOperatorProjectSettings,
    CustomMenusValue,
    CustomMenuValue,
    CustomSectionsValue,
    CustomSectionValue,
    DialogSurveyOperatorProjectSettings,
    HoldOperatorProjectSettings,
    KPIOperatorProjectSettings,
    NotificationsOperatorProjectSettings,
    OutgoingDialogOperatorProjectSettings,
    ProjectSettingsListValue,
    ResponseTemplateOperatorProjectSettings,
    WorkplaceOperatorProjectSettings
} from "../../../models/projectSettings"
import RadioButtonValidatableInput from "../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import {
    ChooseTopicToFinishDialogType,
    DialogSurveyOperatorProjectSettingsValues,
    NewWorkplaceOperatorProjectSettings,
    OperatorsWorkValues
} from "../../../models/projectSettingsValues"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import FormSubSection from "../../FormHelpers/SubSection/FormSubSection"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { Form } from "react-bootstrap"
import Slider from "../../Slider/Slider"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectProjectSettings, selectUpdateProjectSettingsState } from "../../../store/projects/selectors"
import ProjectSettingsLayout from "../../ProjectSettingsLayout/ProjectSettingsLayout"
import { FieldArray, Formik, FormikProps } from "formik"
import InfoIcon from "../../InfoIcon/InfoIcon"
import { formTranslation } from "../../../locales/form"
import { selectArticleTypes, selectCatalogsMap, selectCatalogsState } from "../../../store/knowledgeBase/selectors"
import FormSection from "../../FormHelpers/Section/FormSection"
import { CreatableSelect } from "../../Select/Select"
import { getArticleTypes, getCatalogs } from "../../../store/knowledgeBase/thunks"
import RemovableListItem from "../../FormHelpers/RemovableListItem/RemovableListItem"
import MultipleSelectControl from "../../FormHelpers/MultipleSelectControl/MultipleSelectControl"
import { useDifferenceCount } from "../../../utility/project/projectSettings"
import TimeoutControl from "../TimeoutControl"
import { getSurveys } from "../../../store/surveys/thunks"
import { selectSurveys } from "../../../store/surveys/selectors"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import {
    buildOperatorsWorkSettingsRequest,
    defaultOperatorProjectSettings,
    getInitialValues,
    PushNotificationVolumeLevelBounds
} from "./helpers"
import { updateProjectSettings } from "../../../store/projects/thunks"
import FormControlWithAdd from "../../FormHelpers/ControlWithAdd/FormControlWithAdd"
import ValidatableInput from "../../ValidatableInput/ValidatableInput"

const tNamespace = "project:project-settings.operators."
const tTooltipNamespace = `${tNamespace}tooltip.`
const tTimeNamespace = "common:time."

interface FormProps extends FormikProps<OperatorsWorkValues> {
    onChange: (e: FormEvent<HTMLFormElement>, initialValues: OperatorsWorkValues, values: OperatorsWorkValues) => void
    onCustomInputChange: <K>(name: string, value: K, initialValues: OperatorsWorkValues) => void
}

const FormikOperatorsWorkSettingsForm: React.FC<FormProps> = props => {
    const { t } = useTranslation()
    const { onChange, onCustomInputChange, initialValues, values, setFieldValue } = props
    const surveys = useSelector(selectSurveys)

    const articleTypes = useSelector(selectArticleTypes)
    const catalogsState = useSelector(selectCatalogsState)
    const catalogsMap = useSelector(selectCatalogsMap)

    const catalogs = catalogsState.data?.map(code => {
        const tree = catalogsMap[code]
        const item = tree.items[tree.rootId]

        return {
            id: item.id,
            title: item.data.title
        }
    })

    return (
        <Form onChange={e => onChange(e, initialValues, values)} onKeyPress={preventSubmitOnEnter}>
            <FormAccordion title={t(`${tNamespace}general`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedCheckBox
                            id="formAllowChatJoinRequests"
                            name={nameof2<OperatorsWorkValues, CommonOperatorProjectSettings>(
                                "Common",
                                "AllowChatJoinRequests"
                            )}
                            label={t(`${tNamespace}allow-chat-join-requests`)}
                        />
                        <DebouncedValidatableInput
                            as="select"
                            id="formAuditorDashboardSurveyId"
                            name={nameof2<OperatorsWorkValues, CommonOperatorProjectSettings>(
                                "Common",
                                "AuditorDashboardSurveyId"
                            )}
                            label={t(`${tNamespace}auditor-dashboard-survey-id`)}
                            icon={
                                <InfoIcon
                                    id="tooltipAuditorDashboardSurveyId"
                                    content={t(`${tTooltipNamespace}auditor-dashboard-survey-id`)}
                                />
                            }
                            disabled={!surveys.length}
                        >
                            <option value="">{t(formTranslation.select)}</option>
                            {surveys.map(({ Id, Title }) => (
                                <option value={Id} key={Id}>
                                    {Title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                        <DebouncedValidatableInput
                            id="formPhoneNumberMask"
                            name={nameof2<OperatorsWorkValues, ClientSurveyOperatorProjectSettings>(
                                "ClientSurvey",
                                "PhoneNumberMask"
                            )}
                            label={t(`${tNamespace}phone-number-mask`)}
                        />
                    </FormSection>
                </FormContent>
            </FormAccordion>
            <FormAccordion title={t(`${tNamespace}client-survey`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedValidatableInput
                            id="formMainSurveyId"
                            as="select"
                            disabled={!surveys.length}
                            name={nameof2<OperatorsWorkValues, ClientSurveyOperatorProjectSettings>(
                                "ClientSurvey",
                                "MainSurveyId"
                            )}
                            label={t(`${tNamespace}main-survey-id`)}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {surveys.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {option.Title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                        <DebouncedValidatableInput
                            id="formShortSurveyId"
                            as="select"
                            disabled={!surveys.length}
                            name={nameof2<OperatorsWorkValues, ClientSurveyOperatorProjectSettings>(
                                "ClientSurvey",
                                "ShortSurveyId"
                            )}
                            label={t(`${tNamespace}short-survey-id`)}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {surveys.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {option.Title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                    </FormSection>
                </FormContent>
            </FormAccordion>
            <FormAccordion title={t(`${tNamespace}dialog-survey`)} size="lg">
                <FormContent>
                    <FormSection>
                        <RadioButtonValidatableInput
                            value={ChooseTopicToFinishDialogType.Required}
                            id="formChooseTopicToFinishDialogRequired"
                            name={nameof2<OperatorsWorkValues, DialogSurveyOperatorProjectSettingsValues>(
                                "DialogSurvey",
                                "ChooseTopicToFinishDialog"
                            )}
                            label={t(`${tNamespace}choose-topic-to-finish-dialog-required`)}
                        />
                        <RadioButtonValidatableInput
                            value={ChooseTopicToFinishDialogType.Automatic}
                            id="formChooseTopicToFinishDialogAutomatic"
                            name={nameof2<OperatorsWorkValues, DialogSurveyOperatorProjectSettingsValues>(
                                "DialogSurvey",
                                "ChooseTopicToFinishDialog"
                            )}
                            label={t(`${tNamespace}choose-topic-to-finish-dialog-automatic`)}
                        />
                    </FormSection>
                    <FormSection>
                        <DebouncedValidatableInput
                            as="select"
                            id="formDialogTopicCatalogId"
                            name={nameof2<OperatorsWorkValues, DialogSurveyOperatorProjectSettings>(
                                "DialogSurvey",
                                "DialogTopicCatalogId"
                            )}
                            label={t(`${tNamespace}dialog-topic-catalog-id`)}
                            disabled={!catalogs?.length}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {catalogs?.map(({ id, title }) => (
                                <option value={id} key={id}>
                                    {title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                        <DebouncedValidatableInput
                            as="select"
                            id="formDialogProgramCatalogId"
                            name={nameof2<OperatorsWorkValues, DialogSurveyOperatorProjectSettings>(
                                "DialogSurvey",
                                "DialogProgramCatalogId"
                            )}
                            label={t(`${tNamespace}dialog-program-catalog-id`)}
                            disabled={!catalogs?.length}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {catalogs?.map(({ id, title }) => (
                                <option value={id} key={id}>
                                    {title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                        <DebouncedValidatableInput
                            id="formChooseProgramFieldName"
                            type="text"
                            name={nameof2<OperatorsWorkValues, DialogSurveyOperatorProjectSettingsValues>(
                                "DialogSurvey",
                                "ChooseProgramFieldName"
                            )}
                            label={t(`${tNamespace}choose-program-field-name`)}
                        />
                        <DebouncedValidatableInput
                            id="formDialogSurveyId"
                            as="select"
                            disabled={!surveys.length}
                            name={nameof2<OperatorsWorkValues, DialogSurveyOperatorProjectSettings>(
                                "DialogSurvey",
                                "DialogSurveyId"
                            )}
                            label={t(`${tNamespace}dialog-survey`)}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {surveys.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {option.Title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                        <FieldArray
                            name={nameof3<
                                OperatorsWorkValues,
                                DialogSurveyOperatorProjectSettings,
                                ProjectSettingsListValue
                            >("DialogSurvey", "UserDissatisfaction", "Values")}
                            render={({ push, remove }) => (
                                <Form.Group controlId="formUserDissatisfaction">
                                    <Form.Label>
                                        {t(`${tNamespace}user-dissatisfaction`)}
                                        <InfoIcon
                                            id="tooltipUserDissatisfaction"
                                            content={t(`${tTooltipNamespace}user-dissatisfaction`)}
                                        />
                                    </Form.Label>
                                    <CreatableSelect
                                        value={{
                                            label: t(formTranslation.enterText),
                                            value: ""
                                        }}
                                        options={[]}
                                        noOptionsMessage={() => null}
                                        formatCreateLabel={(inputValue: string) =>
                                            `${t(`${tNamespace}add-rating`)}: ${inputValue}`
                                        }
                                        onChange={option => {
                                            if (option) {
                                                push(option.value)
                                                onCustomInputChange(
                                                    nameof3<
                                                        OperatorsWorkValues,
                                                        DialogSurveyOperatorProjectSettings,
                                                        ProjectSettingsListValue
                                                    >("DialogSurvey", "UserDissatisfaction", "Values"),
                                                    option.value,
                                                    initialValues
                                                )
                                            }
                                        }}
                                        hideDropdownIndicator
                                    />
                                    {values.DialogSurvey.UserDissatisfaction.Values.map((v: string, index: number) => (
                                        <RemovableListItem
                                            key={index}
                                            onDelete={() => {
                                                remove(index)
                                                onCustomInputChange(
                                                    nameof3<
                                                        OperatorsWorkValues,
                                                        DialogSurveyOperatorProjectSettings,
                                                        ProjectSettingsListValue
                                                    >("DialogSurvey", "UserDissatisfaction", "Values"),
                                                    values.DialogSurvey.UserDissatisfaction.Values[index],
                                                    initialValues
                                                )
                                            }}
                                        >
                                            {v}
                                        </RemovableListItem>
                                    ))}
                                </Form.Group>
                            )}
                        />
                    </FormSection>
                </FormContent>
            </FormAccordion>
            <FormAccordion title={t(`${tNamespace}new-operator-workplace`)} size="lg">
                <FormContent>
                    <FormSection>
                        <FormControlWithAdd
                            name={nameof3<
                                OperatorsWorkValues,
                                NewWorkplaceOperatorProjectSettings,
                                CustomSectionsValue
                            >("NewWorkplace", "CustomSections", "Values")}
                            label={t(`${tNamespace}custom-section-add`)}
                            icon={
                                <InfoIcon
                                    id="tooltipCustomSectionUrl"
                                    content={t(`${tTooltipNamespace}custom-section-add`)}
                                />
                            }
                            onCreateEmptyValue={() => ""}
                        >
                            {({ name, remove }) =>
                                values.NewWorkplace.CustomSections.Values?.map(
                                    (v: CustomSectionValue, index: number) => (
                                        <FormSection key={index}>
                                            <ValidatableInput
                                                id="formCustomSectionTitle"
                                                name={`${name}[${index}].Title`}
                                                label={t(`${tNamespace}custom-section-title`)}
                                                onDelete={() => {
                                                    remove(index)
                                                    onCustomInputChange(
                                                        nameof3<
                                                            OperatorsWorkValues,
                                                            NewWorkplaceOperatorProjectSettings,
                                                            CustomSectionsValue
                                                        >("NewWorkplace", "CustomSections", "Values"),
                                                        values.NewWorkplace.CustomSections.Values[index],
                                                        initialValues
                                                    )
                                                }}
                                            />
                                            <ValidatableInput
                                                id="formCustomSectionUrl"
                                                type="text"
                                                name={`${name}[${index}].Url`}
                                                label={t(`${tNamespace}custom-section-url`)}
                                            />
                                            <DebouncedCheckBox
                                                id={`formInitSectionOpened[${index}]`}
                                                name={`${name}[${index}].ExpandByDefault`}
                                                label={t(`${tNamespace}custom-section-opened`)}
                                                onChange={v => {
                                                    setFieldValue(`${name}[${index}].ExpandByDefault`, v)
                                                }}
                                            />
                                        </FormSection>
                                    )
                                )
                            }
                        </FormControlWithAdd>
                    </FormSection>
                    <FormSection>
                        <FormControlWithAdd
                            name={nameof3<OperatorsWorkValues, NewWorkplaceOperatorProjectSettings, CustomMenusValue>(
                                "NewWorkplace",
                                "CustomMenus",
                                "Values"
                            )}
                            label={t(`${tNamespace}custom-menu-add`)}
                            icon={
                                <InfoIcon
                                    id="tooltipCustomSectionUrl"
                                    content={t(`${tTooltipNamespace}custom-section-add`)}
                                />
                            }
                            onCreateEmptyValue={() => ""}
                        >
                            {({ name, remove }) =>
                                values.NewWorkplace.CustomMenus.Values?.map((v: CustomMenuValue, index: number) => (
                                    <FormSection key={index}>
                                        <ValidatableInput
                                            id="formCustomMenuTitle"
                                            name={`${name}[${index}].Title`}
                                            label={t(`${tNamespace}custom-menu-title`)}
                                            onDelete={() => {
                                                remove(index)
                                                onCustomInputChange(
                                                    nameof3<
                                                        OperatorsWorkValues,
                                                        NewWorkplaceOperatorProjectSettings,
                                                        CustomMenusValue
                                                    >("NewWorkplace", "CustomMenus", "Values"),
                                                    values.NewWorkplace.CustomSections.Values[index],
                                                    initialValues
                                                )
                                            }}
                                        />
                                        <ValidatableInput
                                            id="formCustomMenuUrl"
                                            type="text"
                                            name={`${name}[${index}].Url`}
                                            label={t(`${tNamespace}custom-menu-url`)}
                                        />
                                        <ValidatableInput
                                            id="formCustomMenuIcon"
                                            type="text"
                                            name={`${name}[${index}].Icon`}
                                            label={t(`${tNamespace}custom-menu-icon`)}
                                        />
                                    </FormSection>
                                ))
                            }
                        </FormControlWithAdd>
                    </FormSection>
                    <FormSection>
                        <DebouncedValidatableInput
                            id="formSetClientsListSectionName"
                            type="text"
                            name={nameof2<OperatorsWorkValues, NewWorkplaceOperatorProjectSettings>(
                                "NewWorkplace",
                                "ClientsSectionName"
                            )}
                            label={t(`${tNamespace}clients-list-section-name`)}
                        />
                        <DebouncedValidatableInput
                            id="formNewClientSurveyId"
                            as="select"
                            disabled={!surveys.length}
                            name={nameof2<OperatorsWorkValues, NewWorkplaceOperatorProjectSettings>(
                                "NewWorkplace",
                                "ClientSurveyId"
                            )}
                            label={t(`${tNamespace}client-survey`)}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {surveys.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {option.Title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                        <DebouncedValidatableInput
                            id="formNewDialogSurveyId"
                            as="select"
                            disabled={!surveys.length}
                            name={nameof2<OperatorsWorkValues, NewWorkplaceOperatorProjectSettings>(
                                "NewWorkplace",
                                "DialogSurveyId"
                            )}
                            label={t(`${tNamespace}dialog-survey`)}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {surveys.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {option.Title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}kpi-tracking-timers`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedCheckBox
                            id="formDialogTimerEnabled"
                            name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>("KPI", "DialogTimerEnabled")}
                            label={t(`${tNamespace}dialog-timer-enabled`)}
                        />
                        <FormSubSection>
                            <TimeoutControl
                                id="formGreenDialogTimerTimeout"
                                name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                    "KPI",
                                    "GreenDialogTimerTimeout"
                                )}
                                label={t(`${tNamespace}green-dialog-timer-timeout`)}
                            />
                            <TimeoutControl
                                id="formYellowDialogTimerTimeout"
                                name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                    "KPI",
                                    "YellowDialogTimerTimeout"
                                )}
                                label={t(`${tNamespace}yellow-dialog-timer-timeout`)}
                            />
                            <DebouncedCheckBox
                                id="formDialogTimerTimeoutPlusEnabled"
                                name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                    "KPI",
                                    "DialogTimerTimeoutPlusEnabled"
                                )}
                                label={t(`${tNamespace}red-dialog-timer-timeout-plus-symbol`)}
                            />
                            <DebouncedCheckBox
                                id="formDialogTimerTimeoutCustomLabelEnabled"
                                name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                    "KPI",
                                    "DialogTimerTimeoutCustomLabelEnabled"
                                )}
                                label={t(`${tNamespace}dialog-timer-custom-label-enabled`)}
                            />
                            <FormSubSection>
                                <DebouncedValidatableInput
                                    id="formDialogTimerTimeoutCustomLabelEnabledName"
                                    type="text"
                                    name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                        "KPI",
                                        "DialogTimerTimeoutCustomLabelEnabledName"
                                    )}
                                    label={t(`${tNamespace}dialog-timer-custom-label`)}
                                    disabled={!values.KPI.DialogTimerTimeoutCustomLabelEnabled}
                                />
                            </FormSubSection>
                        </FormSubSection>
                        <DebouncedCheckBox
                            id="formDialogWaitingForClientAnswerTimerEnabled"
                            name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                "KPI",
                                "DialogWaitingForClientAnswerTimerEnabled"
                            )}
                            label={t(`${tNamespace}dialog-waiting-for-client-answer-timer-enabled`)}
                        />
                        <FormSubSection>
                            <TimeoutControl
                                id="formDialogWaitingForClientAnswerTimeout"
                                name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                    "KPI",
                                    "DialogWaitingForClientAnswerTimeout"
                                )}
                                label={t(`${tNamespace}dialog-waiting-for-client-answer-timeout`)}
                            />
                        </FormSubSection>
                        <DebouncedCheckBox
                            id="formDialogWaitingForOperatorAnswerTimerEnabled"
                            name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                "KPI",
                                "DialogWaitingForOperatorAnswerTimerEnabled"
                            )}
                            label={t(`${tNamespace}dialog-waiting-for-operator-answer-timer-enabled`)}
                        />
                        <FormSubSection>
                            <TimeoutControl
                                id="formDialogWaitingForOperatorAnswerTimeout"
                                name={nameof2<OperatorsWorkValues, KPIOperatorProjectSettings>(
                                    "KPI",
                                    "DialogWaitingForOperatorAnswerTimeout"
                                )}
                                label={t(`${tNamespace}dialog-waiting-for-operator-answer-timeout`)}
                            />
                        </FormSubSection>
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}operator-workplace`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedCheckBox
                            id="formShowDialogHistory"
                            name={nameof2<OperatorsWorkValues, WorkplaceOperatorProjectSettings>(
                                "Workplace",
                                "ShowDialogHistory"
                            )}
                            label={t(`${tNamespace}show-dialog-history`)}
                        />
                        <DebouncedCheckBox
                            id="formForwardingMessage"
                            name={nameof2<OperatorsWorkValues, WorkplaceOperatorProjectSettings>(
                                "Workplace",
                                "ForwardingMessage"
                            )}
                            label={t(`${tNamespace}forwarding-message`)}
                        />
                        <DebouncedCheckBox
                            id="formRespondWithArticleEnabled"
                            name={nameof2<OperatorsWorkValues, WorkplaceOperatorProjectSettings>(
                                "Workplace",
                                "RespondWithArticleEnabled"
                            )}
                            label={t(`${tNamespace}respond-with-article-enabled`)}
                        />
                        <DebouncedCheckBox
                            id="formUseSpellChecker"
                            name={nameof2<OperatorsWorkValues, WorkplaceOperatorProjectSettings>(
                                "Workplace",
                                "UseSpellChecker"
                            )}
                            label={t(`${tNamespace}use-spell-checker`)}
                        />
                        <DebouncedCheckBox
                            id="formUseEmoji"
                            name={nameof2<OperatorsWorkValues, WorkplaceOperatorProjectSettings>(
                                "Workplace",
                                "UseEmoji"
                            )}
                            label={t(`${tNamespace}use-emoji`)}
                        />
                        <DebouncedCheckBox
                            id="formSetPreviousTopicsAfterFinishDialog"
                            name={nameof2<OperatorsWorkValues, WorkplaceOperatorProjectSettings>(
                                "Workplace",
                                "SetPreviousTopicsAfterFinishDialog"
                            )}
                            label={t(`${tNamespace}set-previous-topics-after-finish-dialog`)}
                        />
                        <DebouncedCheckBox
                            id="formLoadHistoryByEmail"
                            name={nameof2<OperatorsWorkValues, WorkplaceOperatorProjectSettings>(
                                "Workplace",
                                "LoadHistoryByEmail"
                            )}
                            label={t(`${tNamespace}load-history-by-email`)}
                        />
                        <DebouncedCheckBox
                            id="formLoadHistoryByPhoneNumber"
                            name={nameof2<OperatorsWorkValues, WorkplaceOperatorProjectSettings>(
                                "Workplace",
                                "LoadHistoryByPhoneNumber"
                            )}
                            label={t(`${tNamespace}load-history-by-phone-number`)}
                        />
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}automatic-greeting`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedCheckBox
                            id="formOperatorAutoGreetingEnabled"
                            name={nameof2<OperatorsWorkValues, AutoGreetingOperatorProjectSettings>(
                                "AutoGreeting",
                                "OperatorAutoGreetingEnabled"
                            )}
                            label={t(`${tNamespace}operator-auto-greeting-enabled`)}
                        />
                        <FormSubSection>
                            <DebouncedValidatableInput
                                id="formOperatorAutoGreetingMale"
                                type="text"
                                name={nameof2<OperatorsWorkValues, AutoGreetingOperatorProjectSettings>(
                                    "AutoGreeting",
                                    "OperatorAutoGreetingMale"
                                )}
                                label={t(`${tNamespace}operator-auto-greeting-male`)}
                                disabled={!values.AutoGreeting.OperatorAutoGreetingEnabled}
                            />
                            <DebouncedValidatableInput
                                id="formOperatorAutoGreetingFemale"
                                type="text"
                                name={nameof2<OperatorsWorkValues, AutoGreetingOperatorProjectSettings>(
                                    "AutoGreeting",
                                    "OperatorAutoGreetingFemale"
                                )}
                                label={t(`${tNamespace}operator-auto-greeting-female`)}
                                disabled={!values.AutoGreeting.OperatorAutoGreetingEnabled}
                            />
                            <DebouncedValidatableInput
                                id="formOperatorAutoGreetingNoGender"
                                type="text"
                                name={nameof2<OperatorsWorkValues, AutoGreetingOperatorProjectSettings>(
                                    "AutoGreeting",
                                    "OperatorAutoGreetingNoGender"
                                )}
                                label={t(`${tNamespace}operator-auto-greeting-no-gender`)}
                                disabled={!values.AutoGreeting.OperatorAutoGreetingEnabled}
                            />
                        </FormSubSection>
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}response-templates`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedValidatableInput
                            as="select"
                            id="formFastReplyTemplates"
                            name={nameof2<OperatorsWorkValues, ResponseTemplateOperatorProjectSettings>(
                                "ResponseTemplate",
                                "FastReplyTemplates"
                            )}
                            label={t(`${tNamespace}fast-reply-templates`)}
                            icon={
                                <InfoIcon
                                    id="tooltipFastReplyTemplates"
                                    content={t(`${tTooltipNamespace}fast-reply-templates`)}
                                />
                            }
                            disabled={!catalogs?.length}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {catalogs?.map(({ id, title }) => (
                                <option value={id} key={id}>
                                    {title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                        <FieldArray
                            name={nameof3<
                                OperatorsWorkValues,
                                ResponseTemplateOperatorProjectSettings,
                                ProjectSettingsListValue
                            >("ResponseTemplate", "FastReplyHoldTemplates", "Values")}
                            render={({ push, remove }) => (
                                <>
                                    <Form.Group controlId="formFastReplyHoldTemplates">
                                        <Form.Label>
                                            {t(`${tNamespace}fast-reply-hold-templates`)}
                                            <InfoIcon
                                                id="tooltipFastReplyHoldTemplates"
                                                content={t(`${tTooltipNamespace}fast-reply-hold-templates`)}
                                            />
                                        </Form.Label>
                                        <CreatableSelect
                                            value={{
                                                label: t(formTranslation.enterText),
                                                value: ""
                                            }}
                                            noOptionsMessage={() => null}
                                            onChange={option => {
                                                if (option) {
                                                    push(option.value)
                                                    onCustomInputChange(
                                                        nameof3<
                                                            OperatorsWorkValues,
                                                            ResponseTemplateOperatorProjectSettings,
                                                            ProjectSettingsListValue
                                                        >("ResponseTemplate", "FastReplyHoldTemplates", "Values"),
                                                        option.value,
                                                        initialValues
                                                    )
                                                }
                                            }}
                                            formatCreateLabel={(inputValue: string) =>
                                                `${t(formTranslation.add)}: ${inputValue}`
                                            }
                                            hideDropdownIndicator
                                        />
                                        {values.ResponseTemplate.FastReplyHoldTemplates.Values.map(
                                            (v: string, index: number) => (
                                                <RemovableListItem
                                                    key={index}
                                                    onDelete={() => {
                                                        remove(index)
                                                        onCustomInputChange(
                                                            nameof3<
                                                                OperatorsWorkValues,
                                                                ResponseTemplateOperatorProjectSettings,
                                                                ProjectSettingsListValue
                                                            >("ResponseTemplate", "FastReplyHoldTemplates", "Values"),
                                                            values.ResponseTemplate.FastReplyHoldTemplates.Values[
                                                                index
                                                            ],
                                                            initialValues
                                                        )
                                                    }}
                                                >
                                                    {v}
                                                </RemovableListItem>
                                            )
                                        )}
                                    </Form.Group>
                                </>
                            )}
                        />
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}auto-reminders`)} size="lg">
                <FormContent>
                    <FormSection>
                        <TimeoutControl
                            id="formWaitingForClientResponseTimeout"
                            label={t(`${tNamespace}waiting-for-client-response-timeout`)}
                            tooltip={t(`${tTooltipNamespace}waiting-for-client-response-timeout`)}
                            name={nameof2<OperatorsWorkValues, ResponseTemplateOperatorProjectSettings>(
                                "ResponseTemplate",
                                "WaitingForClientResponseTimeout"
                            )}
                            daysHidden
                        />
                        <FieldArray
                            name={nameof3<
                                OperatorsWorkValues,
                                ResponseTemplateOperatorProjectSettings,
                                ProjectSettingsListValue
                            >("ResponseTemplate", "WaitingForClientResponseTimeoutMessages", "Values")}
                            render={({ push, remove }) => (
                                <Form.Group controlId="formWaitingForClientResponseTimeoutMessages">
                                    <Form.Label>
                                        {t(`${tNamespace}waiting-for-client-response-timeout-messages`)}
                                        <InfoIcon
                                            id="tooltipUserDissatisfaction"
                                            content={t(
                                                `${tTooltipNamespace}waiting-for-client-response-timeout-messages`
                                            )}
                                        />
                                    </Form.Label>
                                    <CreatableSelect
                                        value={{
                                            label: t(formTranslation.enterText),
                                            value: ""
                                        }}
                                        options={[]}
                                        noOptionsMessage={() => null}
                                        formatCreateLabel={(inputValue: string) =>
                                            `${t(formTranslation.add)}: ${inputValue}`
                                        }
                                        onChange={option => {
                                            if (option) {
                                                push(option.value)
                                                onCustomInputChange(
                                                    nameof3<
                                                        OperatorsWorkValues,
                                                        ResponseTemplateOperatorProjectSettings,
                                                        ProjectSettingsListValue
                                                    >(
                                                        "ResponseTemplate",
                                                        "WaitingForClientResponseTimeoutMessages",
                                                        "Values"
                                                    ),
                                                    option.value,
                                                    initialValues
                                                )
                                            }
                                        }}
                                        hideDropdownIndicator
                                    />
                                    {values.ResponseTemplate.WaitingForClientResponseTimeoutMessages.Values.map(
                                        (v: string, index: number) => (
                                            <RemovableListItem
                                                key={index}
                                                onDelete={() => {
                                                    remove(index)
                                                    onCustomInputChange(
                                                        nameof3<
                                                            OperatorsWorkValues,
                                                            ResponseTemplateOperatorProjectSettings,
                                                            ProjectSettingsListValue
                                                        >(
                                                            "ResponseTemplate",
                                                            "WaitingForClientResponseTimeoutMessages",
                                                            "Values"
                                                        ),
                                                        values.ResponseTemplate.WaitingForClientResponseTimeoutMessages
                                                            .Values[index],
                                                        initialValues
                                                    )
                                                }}
                                            >
                                                {v}
                                            </RemovableListItem>
                                        )
                                    )}
                                </Form.Group>
                            )}
                        />
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}client-auto-hold`)} size="lg">
                <FormContent>
                    <FormSection>
                        <FieldArray
                            name={nameof3<
                                OperatorsWorkValues,
                                ResponseTemplateOperatorProjectSettings,
                                ProjectSettingsListValue
                            >("ResponseTemplate", "TriggerMessagesForRunClientHolding", "Values")}
                            render={({ push, remove }) => (
                                <>
                                    <Form.Group controlId="formTriggerMessagesForRunClientHolding">
                                        <Form.Label>
                                            {t(`${tNamespace}trigger-messages-for-run-client-holding`)}
                                            <InfoIcon
                                                id="tooltipTriggerMessagesForRunClientHolding"
                                                content={t(
                                                    `${tTooltipNamespace}trigger-messages-for-run-client-holding`
                                                )}
                                            />
                                        </Form.Label>
                                        <CreatableSelect
                                            value={{
                                                label: t(formTranslation.enterText),
                                                value: ""
                                            }}
                                            options={[]}
                                            noOptionsMessage={() => null}
                                            formatCreateLabel={(inputValue: string) =>
                                                `${t(`${tNamespace}add-phrase`)}: ${inputValue}`
                                            }
                                            onChange={option => {
                                                if (option) {
                                                    push(option.value)
                                                    onCustomInputChange(
                                                        nameof3<
                                                            OperatorsWorkValues,
                                                            ResponseTemplateOperatorProjectSettings,
                                                            ProjectSettingsListValue
                                                        >(
                                                            "ResponseTemplate",
                                                            "TriggerMessagesForRunClientHolding",
                                                            "Values"
                                                        ),
                                                        option.value,
                                                        initialValues
                                                    )
                                                }
                                            }}
                                            hideDropdownIndicator
                                        />
                                    </Form.Group>
                                    <div>
                                        {values.ResponseTemplate.TriggerMessagesForRunClientHolding.Values.map(
                                            (v: string, index: number) => (
                                                <RemovableListItem
                                                    key={index}
                                                    onDelete={() => {
                                                        remove(index)
                                                        onCustomInputChange(
                                                            nameof3<
                                                                OperatorsWorkValues,
                                                                ResponseTemplateOperatorProjectSettings,
                                                                ProjectSettingsListValue
                                                            >(
                                                                "ResponseTemplate",
                                                                "TriggerMessagesForRunClientHolding",
                                                                "Values"
                                                            ),
                                                            values.ResponseTemplate.TriggerMessagesForRunClientHolding
                                                                .Values[index],
                                                            initialValues
                                                        )
                                                    }}
                                                >
                                                    {v}
                                                </RemovableListItem>
                                            )
                                        )}
                                    </div>
                                </>
                            )}
                        />
                    </FormSection>
                    <FormSection>
                        <TimeoutControl
                            id="formClientHoldingTimeout"
                            label={t(`${tNamespace}client-holding-timeout`)}
                            tooltip={t(`${tTooltipNamespace}client-holding-timeout`)}
                            name={nameof2<OperatorsWorkValues, ResponseTemplateOperatorProjectSettings>(
                                "ResponseTemplate",
                                "ClientHoldingTimeout"
                            )}
                            daysHidden
                        />
                        <FieldArray
                            name={nameof3<
                                OperatorsWorkValues,
                                ResponseTemplateOperatorProjectSettings,
                                ProjectSettingsListValue
                            >("ResponseTemplate", "ClientHoldingMessages", "Values")}
                            render={({ push, remove }) => (
                                <Form.Group controlId="forClientHoldingMessages">
                                    <Form.Label>
                                        {t(`${tNamespace}client-holding-messages`)}
                                        <InfoIcon
                                            id="tooltipClientHoldingMessages"
                                            content={t(`${tTooltipNamespace}client-holding-messages`)}
                                        />
                                    </Form.Label>
                                    <CreatableSelect
                                        value={{
                                            label: t(formTranslation.enterText),
                                            value: ""
                                        }}
                                        options={[]}
                                        noOptionsMessage={() => null}
                                        formatCreateLabel={(inputValue: string) =>
                                            `${t(formTranslation.add)}: ${inputValue}`
                                        }
                                        onChange={option => {
                                            if (option) {
                                                push(option.value)
                                                onCustomInputChange(
                                                    nameof3<
                                                        OperatorsWorkValues,
                                                        ResponseTemplateOperatorProjectSettings,
                                                        ProjectSettingsListValue
                                                    >("ResponseTemplate", "ClientHoldingMessages", "Values"),
                                                    option.value,
                                                    initialValues
                                                )
                                            }
                                        }}
                                        hideDropdownIndicator
                                    />
                                    {values.ResponseTemplate.ClientHoldingMessages.Values.map(
                                        (v: string, index: number) => (
                                            <RemovableListItem
                                                key={index}
                                                onDelete={() => {
                                                    remove(index)
                                                    onCustomInputChange(
                                                        nameof3<
                                                            OperatorsWorkValues,
                                                            ResponseTemplateOperatorProjectSettings,
                                                            ProjectSettingsListValue
                                                        >("ResponseTemplate", "FastReplyHoldTemplates", "Values"),
                                                        values.ResponseTemplate.FastReplyHoldTemplates.Values[index],
                                                        initialValues
                                                    )
                                                }}
                                            >
                                                {v}
                                            </RemovableListItem>
                                        )
                                    )}
                                </Form.Group>
                            )}
                        />
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}auto-hold`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedCheckBox
                            id="formAutoHoldingEnabled"
                            name={nameof2<OperatorsWorkValues, HoldOperatorProjectSettings>(
                                "Hold",
                                "AutoHoldingEnabled"
                            )}
                            label={t(`${tNamespace}auto-holding-enabled`)}
                        />
                    </FormSection>
                    <FormSection>
                        <TimeoutControl
                            id="formDropDialogTimeout"
                            label={t(`${tNamespace}drop-dialog-timeout`)}
                            name={nameof2<OperatorsWorkValues, HoldOperatorProjectSettings>(
                                "Hold",
                                "DropDialogTimeout"
                            )}
                            daysHidden
                        />
                        <TimeoutControl
                            id="formFinishDialogWithoutClientResponseTimeout"
                            label={t(`${tNamespace}finish-dialog-without-client-response-timeout`)}
                            name={nameof2<OperatorsWorkValues, HoldOperatorProjectSettings>(
                                "Hold",
                                "FinishDialogWithoutClientResponseTimeout"
                            )}
                            daysHidden
                        />
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}operator-notifications`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedCheckBox
                            id="formPushNotificationEnabled"
                            name={nameof2<OperatorsWorkValues, NotificationsOperatorProjectSettings>(
                                "Notifications",
                                "PushNotificationEnabled"
                            )}
                            label={t(`${tNamespace}push-notification-enabled`)}
                        />
                        <Form.Group controlId="formPushNotificationVolumeLevel">
                            <Form.Label>{t(`${tNamespace}push-notification-volume-level`)}</Form.Label>
                            <Slider
                                min={PushNotificationVolumeLevelBounds.Min}
                                max={PushNotificationVolumeLevelBounds.Max}
                                step={PushNotificationVolumeLevelBounds.Step}
                                marks={{ 0: "0", 10: "10" }}
                                onChange={v => {
                                    setFieldValue(
                                        nameof2<OperatorsWorkValues, NotificationsOperatorProjectSettings>(
                                            "Notifications",
                                            "PushNotificationVolumeLevel"
                                        ),
                                        v,
                                        false
                                    )
                                    onCustomInputChange(
                                        nameof2<OperatorsWorkValues, NotificationsOperatorProjectSettings>(
                                            "Notifications",
                                            "PushNotificationVolumeLevel"
                                        ),
                                        v,
                                        initialValues
                                    )
                                }}
                                value={values.Notifications.PushNotificationVolumeLevel}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPushNotificationTimeout">
                            <Form.Label>{t(`${tNamespace}push-notification-timeout`)}</Form.Label>
                            <Slider
                                min={1}
                                max={10}
                                step={1}
                                marks={{ 1: `1 ${t(`${tTimeNamespace}sec`)}`, 10: `10 ${t(`${tTimeNamespace}sec`)}` }}
                                onChange={v => {
                                    setFieldValue(
                                        nameof2<OperatorsWorkValues, NotificationsOperatorProjectSettings>(
                                            "Notifications",
                                            "PushNotificationTimeout"
                                        ),
                                        v,
                                        false
                                    )
                                    onCustomInputChange(
                                        nameof2<OperatorsWorkValues, NotificationsOperatorProjectSettings>(
                                            "Notifications",
                                            "PushNotificationTimeout"
                                        ),
                                        v,
                                        initialValues
                                    )
                                }}
                                value={values.Notifications.PushNotificationTimeout}
                            />
                        </Form.Group>
                    </FormSection>
                </FormContent>
            </FormAccordion>

            <FormAccordion title={t(`${tNamespace}outgoing-dialogs`)} size="lg">
                <FormContent>
                    <FormSection>
                        <DebouncedCheckBox
                            id="formFinishOutgoingDialogAfterStart"
                            name={nameof2<OperatorsWorkValues, OutgoingDialogOperatorProjectSettings>(
                                "OutgoingDialog",
                                "FinishOutgoingDialogAfterStart"
                            )}
                            label={t(`${tNamespace}finish-outgoing-dialog-after-start`)}
                        />
                        <MultipleSelectControl
                            id="formOutgoingMessageIntentsRecordTypes"
                            label={t(`${tNamespace}outgoing-message-intents-record-types`)}
                            name={nameof3<
                                OperatorsWorkValues,
                                OutgoingDialogOperatorProjectSettings,
                                ProjectSettingsListValue
                            >("OutgoingDialog", "OutgoingMessageIntentsRecordTypes", "Values")}
                            options={articleTypes?.Types.map(articleType => ({
                                label: articleType.Title,
                                value: articleType.Id
                            }))}
                            selectedItems={remove => (
                                <>
                                    {values.OutgoingDialog.OutgoingMessageIntentsRecordTypes.Values.map((v, index) => (
                                        <RemovableListItem key={index} onDelete={() => remove(index)}>
                                            {v}
                                        </RemovableListItem>
                                    ))}
                                </>
                            )}
                            onSelect={(inputName, option) => onCustomInputChange(inputName, option, initialValues)}
                            disabled={!articleTypes?.Types.length}
                        />
                    </FormSection>
                </FormContent>
            </FormAccordion>
        </Form>
    )
}

interface Props {
    projectId: string
    title: string
    open: boolean
}

const OperatorsWorkSettingsForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { projectId, title, open } = props
    const dispatch = useDispatch()
    const settings = useSelector(selectProjectSettings)
    const updateProjectSettingsState = useSelector(selectUpdateProjectSettingsState, shallowEqual)

    const { differenceCount, onOpenSettings, onChange, onCustomInputChange, reset } = useDifferenceCount()
    const initialValues = useMemo(() => settings && getInitialValues(settings), [settings])

    useEffect(() => {
        dispatch(getSurveys(projectId))
        dispatch(getArticleTypes(projectId))
        dispatch(getCatalogs(projectId))
    }, [dispatch, projectId])

    useEffect(() => {
        if (open && settings) {
            onOpenSettings(defaultOperatorProjectSettings, settings?.Operator)
        }
    }, [onOpenSettings, settings, open])

    if (!initialValues) return null

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values: OperatorsWorkValues) => {
                dispatch(updateProjectSettings(projectId, buildOperatorsWorkSettingsRequest(values), reset))
            }}
        >
            {formikProps => (
                <ProjectSettingsLayout.Container
                    title={title}
                    onButtonClick={formikProps.handleSubmit}
                    buttonText={t(formTranslation.save)}
                    hideButton={!differenceCount}
                    notifications={differenceCount}
                    loading={updateProjectSettingsState.inProcess}
                >
                    <FormikOperatorsWorkSettingsForm
                        {...formikProps}
                        onChange={onChange}
                        onCustomInputChange={onCustomInputChange}
                    />
                </ProjectSettingsLayout.Container>
            )}
        </Formik>
    )
}

export default OperatorsWorkSettingsForm
