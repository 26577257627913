import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "./DialogMessageInput.module.scss"
import { User } from "../../../models/user"
import { Formik } from "formik"
import { Button, Form } from "react-bootstrap"
import { ClassProps } from "../../../utility/common/props"
import cn from "classnames"
import UserCard from "../../UserCard/UserCard"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../../locales/form"
import LazyContentEditor from "../../ContentEditor/LazyContentEditor"
import { FormikProps } from "formik/dist/types"
import { useGetAllowedFileExtensions } from "../../../utility/knowledgeBase/articleRedactorEmbeds"
import { useHotkeys } from "react-hotkeys-hook"
import { testId } from "../../../utility/tests/testId"
import IconButton from "../../IconButton/IconButton"
import { faPaperPlaneTop } from "../../../assets/images/font-awesome-exported/faPaperPlaneTop"
import { DEFAULT_EDITOR_DIS_EXT } from "../../ContentEditor/ContentEditor"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperclip, faImage } from "@fortawesome/pro-light-svg-icons"
import { uploadFile } from "../../../utility/common/files"
import { MessageAttachment } from "../../../models/Dialogs/message"

export const MSG_INPUT_FORM_MAX_HEIGHT = 265

interface MessageInputInitialValue {
    text: string
}

export interface DialogMessageInputProps extends ClassProps {
    user?: User
    onCancel?: () => void
    onSend: (message: string) => void
    onAttachmentSelect?: (attachment: MessageAttachment) => void
    initialValue?: string
    placeholder?: string
    inputClassName?: string
    readonlyChat?: boolean
    isAdvanced?: boolean
}

export enum imageTypes {
    jpeg = ".jpeg",
    jpg = ".jpg",
    png = ".png",
    svg = ".svg"
}

const DialogMessageInput: React.FC<DialogMessageInputProps> = props => {
    const {
        className,
        inputClassName,
        placeholder,
        user,
        onSend,
        onCancel,
        onAttachmentSelect,
        initialValue = "",
        isAdvanced = false,
        readonlyChat = false
    } = props
    const { t } = useTranslation()
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const inputFileRef = useRef<HTMLInputElement>(null)
    const formWrapperRef = useRef<HTMLFormElement>(null)
    const allowedFileExtensions = useGetAllowedFileExtensions()
    const [formWrapperFixedHeight, setFormWrapperFixedHeightState] = useState<boolean>(false)

    const imageType = "image"
    const fileType = "file"

    interface MessageValues {
        text: string
    }

    const formRef = useRef<FormikProps<MessageValues>>(null)

    useHotkeys(
        "ctrl+enter",
        () => {
            if (formRef.current) formRef.current.handleSubmit()
        },
        {
            enableOnFormTags: true,
            enableOnContentEditable: true
        }
    )

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const onFormWrapperResize = useCallback(() => {
        const formWrapperEl = formWrapperRef.current

        if (!formWrapperEl) {
            return
        }

        const formWrapperElSizes = formWrapperEl.getBoundingClientRect()
        const currHeight = formWrapperElSizes.height

        setFormWrapperFixedHeightState(currHeight >= MSG_INPUT_FORM_MAX_HEIGHT)
    }, [])

    useEffect(() => {
        const resizeObserver = new ResizeObserver(onFormWrapperResize)
        const formWrapperEl = formWrapperRef.current

        if (!formWrapperEl) {
            return
        }

        resizeObserver.observe(formWrapperEl)

        return () => {
            if (!formWrapperEl) {
                return
            }

            resizeObserver.unobserve(formWrapperEl)
        }
    }, [onFormWrapperResize])

    const specifyAttachmentType = (fileName: string) => {
        for (const type of Object.values(imageTypes)) {
            if (fileName.endsWith(type)) {
                return imageType
            }
        }

        return fileType
    }

    const handleFileSelectClick = (accept = "*") => {
        if (!inputFileRef.current) {
            return
        }

        inputFileRef.current.accept = accept
        inputFileRef.current.click()
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files == null || e.target.files.length === 0) {
            return
        }

        const selectedFile = e.target.files[0]
        const fileUrl = await uploadFile(selectedFile)
        const attachment: MessageAttachment = {
            Url: fileUrl,
            ContentType: specifyAttachmentType(selectedFile.name),
            Name: selectedFile.name,
            Size: selectedFile.size
        }

        if (onAttachmentSelect) {
            onAttachmentSelect(attachment)
        }

        e.target.value = ""
    }

    const getEditor = (formikProps: FormikProps<MessageInputInitialValue>) => {
        if (isAdvanced) {
            const handleAdvancedChange = (getContent: () => string) =>
                formikProps.setFieldValue(
                    "text",
                    getContent()
                        .replace(/^[\\\n]+/gm, "")
                        .replace(/[\n\\]+$/g, "")
                )

            return (
                <LazyContentEditor
                    t={t}
                    onChange={handleAdvancedChange}
                    content={initialValue}
                    className={cn(
                        styles.messageInput__control,
                        styles.messageInput__control_advanced,
                        readonlyChat && styles.messageInput__control_disabled,
                        inputClassName,
                        formWrapperFixedHeight && styles.messageInput__control_stable
                    )}
                    allowedFileExtensions={allowedFileExtensions}
                    isEditable={!readonlyChat}
                    disableExtensions={[
                        ...DEFAULT_EDITOR_DIS_EXT,
                        "checkbox_list",
                        "heading",
                        "table",
                        "hr",
                        "blockquote"
                    ]}
                />
            )
        } else {
            return (
                <Form.Control
                    name="text"
                    as="textarea"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.text}
                    className={cn(styles.messageInput__control, inputClassName)}
                    placeholder={placeholder}
                    ref={inputRef}
                    contentEditable={!readonlyChat}
                />
            )
        }
    }

    return (
        <Formik<MessageInputInitialValue>
            innerRef={formRef}
            initialValues={{ text: initialValue }}
            onSubmit={(values, { resetForm }) => {
                onSend(values.text)

                // for editor
                const proseMirrorDiv = document.querySelector(".ProseMirror[contenteditable='true']")

                if (proseMirrorDiv !== null) {
                    while (proseMirrorDiv.lastChild) {
                        proseMirrorDiv.removeChild(proseMirrorDiv.lastChild)
                    }
                }

                // for textarea
                resetForm({ values: { text: initialValue } })
            }}
        >
            {formikProps => (
                <Form
                    ref={formWrapperRef}
                    className={cn(styles.messageInput, className)}
                    onSubmit={formikProps.handleSubmit}
                    onKeyPress={preventSubmitOnEnter}
                    data-test-id={testId.messageInput}
                >
                    <div className={styles.messageInput__wrapper}>
                        {user && (
                            <div className={styles.messageInput__user}>
                                <UserCard
                                    lastName={user.LastName}
                                    firstName={user.FirstName}
                                    picture={user.Picture}
                                    isNewWorkplace
                                />
                            </div>
                        )}
                        {getEditor(formikProps)}
                    </div>
                    <div className={styles.messageInput__footer}>
                        <div className={styles.messageInput__quickButtons}>
                            <FontAwesomeIcon
                                onClick={() => handleFileSelectClick()}
                                icon={faPaperclip}
                                className={styles.messageInput__quickButton}
                            />
                            <FontAwesomeIcon
                                onClick={() => handleFileSelectClick("image/*")}
                                icon={faImage}
                                className={styles.messageInput__quickButton}
                            />
                        </div>
                        <div className={styles.messageInput__buttonGroup}>
                            {onCancel && (
                                <Button variant="light" onClick={onCancel}>
                                    {t(formTranslation.cancel)}
                                </Button>
                            )}
                            <IconButton
                                variant="primary"
                                type="submit"
                                className={styles.messageInput__submit}
                                icon={faPaperPlaneTop}
                                testId="message-input_submit-button"
                                disabled={readonlyChat}
                            />
                        </div>
                    </div>
                    <input
                        type="file"
                        className={styles.messageInput__fileInput}
                        ref={inputFileRef}
                        onChange={handleFileChange}
                    />
                </Form>
            )}
        </Formik>
    )
}

export default DialogMessageInput
