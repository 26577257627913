import React, { useEffect, useState } from "react"
import { faClock } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { beautifyMsToHMSString } from "../../utility/common/formatDate"
import styles from "./DialogTime.module.scss"

interface DialogTimeProps {
    dispatchedTime: number // time in ms
}

const calculateCurrentTimeStr = (dispatchedTime: number): string => beautifyMsToHMSString(Date.now() - dispatchedTime)

const DialogTime = ({ dispatchedTime }: DialogTimeProps) => {
    const [currentTimeStr, setCurrentTimeStr] = useState(calculateCurrentTimeStr(dispatchedTime))

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null

        timer = setInterval(() => {
            setCurrentTimeStr(calculateCurrentTimeStr(dispatchedTime))
        }, 1000)

        return () => {
            if (timer) {
                clearInterval(timer)
            }
        }
    }, [dispatchedTime])

    return (
        <div className={styles.dialogTime}>
            <FontAwesomeIcon icon={faClock} size="2x" className={styles.dialogTime__icon} color="#1066A8" />
            <span className={styles.dialogTime__timeSpan}>{currentTimeStr}</span>
        </div>
    )
}

export default DialogTime
