import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/pro-light-svg-icons"
import { DialogFinishScore } from "../../../../../models/Dialogs/message"
import styles from "./Rating.module.scss"

export type RatingProps = {
    score: DialogFinishScore
}

const ACTIVE_COLOR = "#F79F1F"
const INACTIVE_COLOR = "#A2A2A2"

const Rating = ({ score }: RatingProps) => {
    return (
        <div className={styles.rating}>
            {new Array(5).fill(null).map((r, index) => (
                <FontAwesomeIcon
                    icon={faStar}
                    color={Number(score) >= index + 1 ? ACTIVE_COLOR : INACTIVE_COLOR}
                    size="lg"
                />
            ))}
        </div>
    )
}

export default Rating
