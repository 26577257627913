import { createBrowserHistory } from "history"
import { routerMiddleware as createRouterMiddleware } from "connected-react-router"
import { ThunkAction, configureStore } from "@reduxjs/toolkit"
import "../utility/common/setupYupCustomValidations"
import createRootReducer from "./rootReducer"
import { queryApi } from "../api/api"
import { Action } from "redux"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { createLogger } from "redux-logger"

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL + "/"
})

const routerMiddleware = createRouterMiddleware(history)
const middlewares = [routerMiddleware, queryApi.middleware]

if (process.env.NODE_ENV === "development") {
    const logger = createLogger({
        collapsed: true,
        duration: true,
        timestamp: true
    })

    middlewares.push(logger)
}

const store = configureStore({
    reducer: createRootReducer(history),
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
    devTools: process.env.NODE_ENV === "development"
})

export type Store = typeof store
export type AppState = ReturnType<typeof store.getState>
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type AppDispatch = typeof store.dispatch

export default store
