import React from "react"
import { ClassProps } from "../../utility/common/props"
import "./FileAttachment.scss"
import cn from "classnames"
import { faFile, faFileExcel, faFilePdf, faFileWord } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MessageAttachment } from "../../models/Dialogs/message"

export enum DocType {
    Doc = ".doc",
    Docx = ".docx",
    Rtf = ".rtf",
    Xls = ".xls",
    Xlsx = ".xlsx",
    Pdf = ".pdf"
}

const mapDocTypeToClassName = (docType: DocType, prefix: string): string => {
    switch (docType) {
        case DocType.Doc:
        case DocType.Docx:
        case DocType.Rtf:
            return `${prefix}doc`
        case DocType.Xls:
        case DocType.Xlsx:
            return `${prefix}xls`
        case DocType.Pdf:
            return `${prefix}pdf`
        default:
            return `${prefix}unknown`
    }
}

interface Props extends ClassProps {
    attachment: Omit<MessageAttachment, "Size">
}

const FileAttachment: React.FC<Props> = props => {
    const { className, attachment } = props

    const tryGetDocType = (url: string) => {
        if (!url) {
            return null
        }
        for (const value of Object.values(DocType)) {
            if (url.endsWith(value)) return value
        }

        return null
    }

    const getIcon = (docType: DocType | null) => {
        if (!docType) {
            return faFile
        }

        switch (docType) {
            case DocType.Docx:
            case DocType.Doc:
            case DocType.Rtf:
                return faFileWord
            case DocType.Xls:
            case DocType.Xlsx:
                return faFileExcel
            case DocType.Pdf:
                return faFilePdf
            default:
                return faFile
        }
    }

    const docType = tryGetDocType(attachment.Url)

    return (
        <div className={cn("fileAttachment", className)}>
            <div
                className={cn(
                    "fileAttachment__icon",
                    docType && mapDocTypeToClassName(docType, "fileAttachment__icon_")
                )}
            >
                <FontAwesomeIcon size="2x" icon={getIcon(docType)} />
            </div>
            <a className="fileAttachment__title" href={attachment.Url} rel="noopener noreferrer" target="_blank">
                {attachment.Name}
            </a>
        </div>
    )
}

export default FileAttachment
