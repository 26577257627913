import { DialogPerson } from "../../models/Dialogs/dialog"
import store, { Store } from "../../store/store"
import { dialogsApi } from "../controllers/dialogs"
import { IHub } from "../interfaces/IHub"

export const notificationAudioNockHelper = () => {
    const audioNode = new Audio("/Assets/sound/notify.mp3")

    const pushNotificationVolumeLevel =
        store.getState().project.settings?.Operator?.Notifications?.PushNotificationVolumeLevel

    audioNode.volume = pushNotificationVolumeLevel || 0.5
    audioNode.currentTime = 0
    audioNode.play().catch(e => console.error("Failed to play sound", e))
}

export const buildClientFullnameHelper = (client?: DialogPerson) => {
    if (!client) {
        return null
    }

    const fullnameTokens = [client.Lastname, client.Firstname, client.Middlename].filter(x => x)

    if (fullnameTokens) {
        return fullnameTokens.join(" ")
    }

    return null
}

export const getBadgeByClientIdHelper = async (clientId: string, dispatch: Store["dispatch"]) => {
    const getDialogBadgesSelector = dialogsApi.endpoints.getDialogBadges.select()
    let getDialogBadgesList = getDialogBadgesSelector(store.getState())

    const isCached = getDialogBadgesList.data?.some(x => x.Client.OmniUserId === clientId)

    if (!isCached) {
        getDialogBadgesList = await dispatch(
            dialogsApi.endpoints.getDialogBadges.initiate(undefined, {
                forceRefetch: true
            })
        )
    }

    return getDialogBadgesList.data?.find(x => x.Client.OmniUserId === clientId)
}

export type TCallbackFn = (rawData: string) => Promise<void>

export const handlersApplyHelper = <T extends Record<string, TCallbackFn>>(
    cb: T,
    eventHandler: IHub["registerEvent"]
) => Object.entries<TCallbackFn>(cb).forEach(([eventName, fn]) => eventHandler(eventName, fn))
