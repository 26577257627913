import { apiInstance } from "../instances"
import { LegacyDialog } from "../../models/Dialogs/legacyDialog"
import {
    IGetOperatorClientBadgesResponse,
    Dialog,
    DialogBadge,
    DialogsSurveys,
    IGetOperatorClientResponse,
    IGetOperatorClientRequest,
    IGetOperatorsListResponse,
    IGetOperatorsListRequest,
    IGetOperatorChannelsResponse,
    ISearchClientsByCriterionResponse
} from "../../models/Dialogs/dialog"
import { UpdateSlotContextRequest } from "../../models/Dialogs/surveyForm"
import {
    GetUpdatedMessagesRequest,
    GetUpdatedMessagesResponse,
    ISendFinishRequest,
    MergedMessageRequest,
    SendMessageResponse
} from "../../models/Dialogs/message"
import { cacher } from "../../utility/common/rtkQueryCacheUtils"
import { queryApi } from "../api"
import {
    operatorChannelsTag,
    operatorClientBadgesTag,
    operatorClientsSectionTitleTag,
    operatorClientTag,
    operatorsListTag
} from "../tags"
// import { fakeClientSurvey, fakeDialogPerson } from "../fake/data"
/* import {
    mockedDialog,
    mockedDialogBadges,
    mockedGetUpdatedMessagesResponse,
    mockedOperatorClient,
    mockedOperatorClientBadges,
    mockedOperatorClientsSectionTitle,
    mockedOperatorsList
} from "../../utility/tests/mockedObjects" */
import {
    customSectionsTag,
    customMenusTag,
    dialogBadgesTag,
    dialogMessagesTag,
    dialogsSurveys,
    dialogsTag
} from "../tags"
import { CustomMenusValue, CustomSectionsValue } from "../../models/projectSettings"
import store from "../../store/store"
import { buildQueryString } from "../../helpers/url"
import { convertRequestToResponse } from "../../utility/message/convertRequestToResponse"

// import { mockedDialog, mockedDialogBadges, mockedGetUpdatedMessagesResponse } from "../../utility/tests/mockedObjects"

const dialogsController = {
    get: (id: string): Promise<LegacyDialog> => apiInstance.get(`dialogs/${id}`).then(response => response.data)
}

export const dialogsApi = queryApi.injectEndpoints({
    endpoints(build) {
        return {
            getOperatorClientsSectionTitle: build.query<string, string>({
                // queryFn: () => {
                //     return { data: mockedOperatorClientsSectionTitle }
                // },
                query: projectId => ({
                    url: `${projectId}/operator-clients/section-name`
                }),
                providesTags(result, error, arg) {
                    return cacher.cacheByIdArg(operatorClientsSectionTitleTag)(result, error, arg)
                }
            }),
            getOperatorClientsBadges: build.query<IGetOperatorClientBadgesResponse[], void>({
                // queryFn: () => {
                //     return { data: mockedOperatorClientBadges }
                // },
                query: () => ({
                    url: `/operator-clients`
                }),
                providesTags(result, error) {
                    const remapped = result?.map(x => ({
                        ...x,
                        Id: x.OmniUserId
                    }))

                    return cacher.providesList(operatorClientBadgesTag)(remapped, error)
                }
            }),
            searchClientsByCriterion: build.query<ISearchClientsByCriterionResponse[], string>({
                // queryFn: () => {
                //     return { data: mockedOperatorClientBadges }
                // }
                query: query => ({
                    url: `/user-profiles?query=${query}`
                })
            }),
            getOperatorsList: build.query<IGetOperatorsListResponse[], IGetOperatorsListRequest>({
                // queryFn: () => {
                //     return { data: mockedOperatorsList }
                // },
                query: ({ projectId, operators: OperatorIds }) => ({
                    url: `operators/${projectId}/info`,
                    method: "POST",
                    body: {
                        OperatorIds
                    }
                }),
                providesTags(result, error, arg) {
                    const argsStr = JSON.stringify(arg)
                    return cacher.cacheByIdArg(operatorsListTag)(result, error, argsStr)
                }
            }),
            getCustomSections: build.query<CustomSectionsValue, string>({
                query: (projectId: string) => ({
                    url: `/custom-sections?projectId=${projectId}`
                }),
                providesTags(result, error) {
                    const sections = result?.Values
                    return cacher.providesList(customSectionsTag)(sections, error)
                }
            }),
            getCustomMenus: build.query<CustomMenusValue, string>({
                query: (projectId: string) => ({
                    url: `/custom-menus?projectId=${projectId}`
                }),
                providesTags(result, error) {
                    const menus = result?.Values
                    return cacher.providesList(customMenusTag)(menus, error)
                }
            }),

            getDialogBadges: build.query<DialogBadge[], void>({
                // queryFn: () => {
                //     return { data: mockedDialogBadges }
                // },
                query: () => ({
                    url: "/dialogs"
                }),
                providesTags(result, error) {
                    return cacher.providesList(dialogBadgesTag)(result, error)
                }
            }),
            getOperatorChannels: build.query<IGetOperatorChannelsResponse[], IGetOperatorClientRequest>({
                // queryFn: () => {
                //     return { data: mockedOperatorClient }
                // },
                query: ({ projectId, omniUserId }) => ({
                    url: buildQueryString({
                        baseUrl: "users/get_user_channels_info",
                        params: {
                            omni_user_id: omniUserId,
                            customer_id: projectId
                        }
                    })
                }),
                providesTags(result, error, arg) {
                    const argsStr = JSON.stringify(arg)
                    return cacher.cacheByIdArg(operatorChannelsTag)(result, error, argsStr)
                }
            }),
            getOperatorClient: build.query<IGetOperatorClientResponse, IGetOperatorClientRequest>({
                // queryFn: () => {
                //     return { data: mockedOperatorClient }
                // },
                query: ({ projectId, omniUserId }) => ({
                    url: `${projectId}/chat-users/${omniUserId}`
                }),
                providesTags(result, error, arg) {
                    const argsStr = JSON.stringify(arg)
                    return cacher.cacheByIdArg(operatorClientTag)(result, error, argsStr)
                }
            }),
            getDialog: build.query<Dialog, string>({
                // queryFn: () => {
                //     return { data: mockedDialog }
                // },
                query: dialogId => ({
                    url: `dialog/${dialogId}`
                }),
                providesTags(result, error, arg) {
                    return cacher.cacheByIdArg(dialogsTag)(result, error, arg)
                }
            }),
            getDialogsSurveys: build.query<DialogsSurveys, string>({
                query: projectId => ({
                    url: `${projectId}/survey/dialog-surveys`
                }),
                providesTags(result, error, arg) {
                    return cacher.cacheByIdArg(dialogsSurveys)(result, error, arg)
                }
            }),
            getDialogMessages: build.query<GetUpdatedMessagesResponse, GetUpdatedMessagesRequest>({
                // queryFn: () => {
                //     return { data: mockedGetUpdatedMessagesResponse }
                // },
                query: ({ ProjectId, ...body }) => ({
                    url: `messages?projectId=${ProjectId}`,
                    method: "POST",
                    body
                }),
                providesTags(result, error) {
                    const messages = result?.Messages.map(m => m.Fields)
                    return cacher.providesList(dialogMessagesTag)(messages, error)
                }
            }),
            sendFinish: build.mutation<boolean, ISendFinishRequest>({
                query: ({ ProjectId, ...body }) => ({
                    url: `/message/send-finish?projectId=${ProjectId}`,
                    method: "POST",
                    body
                })
            }),
            sendMessage: build.mutation<SendMessageResponse, MergedMessageRequest>({
                query: ({ message, projectId }) => ({
                    url: `/message/send-message?projectId=${projectId}`,
                    method: "POST",
                    body: message
                }),
                async onQueryStarted({ message, body }, { dispatch, queryFulfilled }) {
                    const currentOperator = store.getState().users.currentUser
                    const selectedDialogId = store.getState().dialogs.selectedDialogId

                    if (!selectedDialogId) {
                        return
                    }

                    const getDialogSelector = dialogsApi.endpoints.getDialog.select(selectedDialogId)
                    const getDialogQuery = getDialogSelector(store.getState())

                    if (!getDialogQuery.data) {
                        return
                    }

                    const getDialogBadgesPatchResult = dispatch(
                        dialogsApi.util.updateQueryData("getDialogBadges", undefined, oldState => {
                            const idxIntoList = oldState.findIndex(x => x.Client.OmniUserId === message.OmniUserId)

                            if (idxIntoList > -1) {
                                oldState[idxIntoList].Preview = message.Text
                            }
                        })
                    )

                    await queryFulfilled.catch(getDialogBadgesPatchResult.undo)

                    const getDialogMessagesPatchResult = dispatch(
                        dialogsApi.util.updateQueryData(
                            "getDialogMessages",
                            { ...body, ProjectId: getDialogQuery.data.Project.Id },
                            draft => {
                                if (currentOperator.data) {
                                    const operatorId = currentOperator.data.Login //@TODO change to OminUserId
                                    draft.Messages.push(convertRequestToResponse(message, operatorId))
                                }
                            }
                        )
                    )

                    await queryFulfilled.catch(getDialogMessagesPatchResult.undo)
                }
            }),
            postUpdateSlotContext: build.mutation<boolean, UpdateSlotContextRequest>({
                query: ({ DialogId, UpdatedSlots }) => ({
                    url: `dialog/update_slot_context?dialogId=${DialogId}`,
                    method: "POST",
                    body: { UpdatedSlots }
                }),
                invalidatesTags: [dialogsSurveys, dialogsTag]
            })
        }
    }
})

export const {
    useGetCustomSectionsQuery,
    useGetCustomMenusQuery,
    useSendMessageMutation,
    useGetDialogBadgesQuery,
    useGetOperatorClientsBadgesQuery,
    useLazyGetOperatorClientsBadgesQuery,
    useGetOperatorsListQuery,
    useLazyGetOperatorsListQuery,
    useGetOperatorChannelsQuery,
    useLazyGetOperatorChannelsQuery,
    useGetOperatorClientsSectionTitleQuery,
    useLazyGetOperatorClientQuery,
    useGetOperatorClientQuery,
    useGetDialogMessagesQuery,
    useGetDialogsSurveysQuery,
    useLazyGetDialogsSurveysQuery,
    useLazyGetDialogQuery,
    usePostUpdateSlotContextMutation,
    useSendFinishMutation,
    useSearchClientsByCriterionQuery,
    useLazySearchClientsByCriterionQuery
} = dialogsApi

export default dialogsController
