import React from "react"
import styles from "./CreatableTagSelect.module.scss"
import { Form } from "react-bootstrap"
import { CreatableSelect } from "../Select/Select"
import Tag from "../Tag/Tag"
import { FieldArray, useField } from "formik"
import { WithT } from "i18next"
import { formTranslation } from "../../locales/form"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"

export interface CreatableTagSelectProps extends WithT {
    id: string
    name: string
    label: string
    placeholder: string
    options?: OptionType[]
    disabled?: boolean
    icon?: React.ReactElement
}

const CreatableTagSelect: React.FC<CreatableTagSelectProps> = props => {
    const { id, name, label, placeholder, t, options = [], disabled, icon } = props
    const [field] = useField(name)

    return (
        <FieldArray
            name={name}
            render={({ push, remove }) => (
                <>
                    <Form.Group controlId={id} className={styles.creatableTagSelect}>
                        <Form.Label>
                            {label}
                            {icon}
                        </Form.Label>
                        <CreatableSelect
                            className={styles.creatableTagSelect__tagInput}
                            value={{ label: placeholder, value: "" }}
                            options={options}
                            noOptionsMessage={() => null}
                            onChange={option => option && push(option.value)}
                            formatCreateLabel={(inputValue: string) => `${t(formTranslation.add)}: ${inputValue}`}
                            isDisabled={disabled}
                        />
                    </Form.Group>
                    <div className={styles.creatableTagSelect__tags}>
                        {field.value.map((word: string, index: number) => (
                            <Tag
                                className={styles.creatableTagSelect__tag}
                                key={`${word}-${index}`}
                                index={index}
                                title={word}
                                onDelete={() => remove(index)}
                                disabled={disabled}
                            />
                        ))}
                    </div>
                </>
            )}
        />
    )
}

export default CreatableTagSelect
