import { Dropdown as DropdownBS } from "react-bootstrap"
import styles from "./Dropdown.module.scss"
import React, { ReactNode, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons"

export type IDropdownItem = {
    title: ReactNode | string
    name: string
}

export interface IDropdownProps {
    items: IDropdownItem[]
    onListItemSelect?: (name: string) => void
    onTriggerClick?: (e: React.MouseEvent<HTMLElement>) => void
    throughMode?: boolean
    showArrowIcon?: boolean
}

export const Dropdown: React.FC<React.PropsWithChildren<IDropdownProps>> = ({
    onListItemSelect,
    onTriggerClick,
    children,
    items = [],
    throughMode,
    showArrowIcon
}) => {
    const [menuShown, setMenuShown] = useState<boolean>(false)

    const handleDropdownItemClick = (name: string) => {
        if (onListItemSelect) {
            onListItemSelect(name)
        }

        setMenuShown(false)
    }

    const handleOnTriggerClick = (e: React.MouseEvent<HTMLElement>) => {
        if (onTriggerClick) {
            onTriggerClick(e)
        }

        setMenuShown(prev => !prev)
    }

    if (throughMode) {
        return <>{children}</>
    }

    return (
        <>
            <DropdownBS show={menuShown}>
                <DropdownBS.Toggle onClick={handleOnTriggerClick} className={styles.dropdown__toggle}>
                    {children}
                </DropdownBS.Toggle>
                <DropdownBS.Menu className={styles.dropdown__list}>
                    {items.map(x => (
                        <DropdownBS.Item
                            key={x.name}
                            className={styles.dropdown__item}
                            onClick={() => handleDropdownItemClick(x.name)}
                        >
                            {x.title}
                        </DropdownBS.Item>
                    ))}
                </DropdownBS.Menu>
            </DropdownBS>
            <button onClick={handleOnTriggerClick} className={styles.dropdown__toggleButton}>
                {showArrowIcon && <FontAwesomeIcon icon={menuShown ? faAngleUp : faAngleDown} />}
            </button>
        </>
    )
}
