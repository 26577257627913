import {
    DeleteFromQueueRequest,
    OperatorSummary,
    TenantQueues,
    UpdateOperatorQueuesRequest
} from "../../models/operator"
import { apiInstance } from "../instances"
import { OperatorDto } from "../../models/operatorDto"
import { processUser } from "../../utility/common/processUser"
import { channelTypeStringConverter } from "../../utility/channels/channelTypeStringConverter"
import { ChannelType } from "../../models/channel"

const operatorsController = {
    getActiveByTenant: (id: string): Promise<OperatorDto[]> =>
        apiInstance
            .get(`/tenants/${id}/operators/active`)
            .then(response =>
                response.data.map((operator: OperatorDto) => {
                    operator.Tasks = operator.Tasks.map(task => {
                        task.Channel.Type = channelTypeStringConverter.toChannelTypeString(
                            task.Channel.Type as unknown as ChannelType
                        )
                        return task
                    })
                    return operator
                })
            )
            .then(data => data.map(processUser)),
    deleteFromQueue: (id: string, request: DeleteFromQueueRequest): Promise<void> =>
        apiInstance.post(`/operators/${id}/delete_from_queue`, request).then(response => response.data),
    updateQueues: (id: string, request: UpdateOperatorQueuesRequest): Promise<TenantQueues> =>
        apiInstance.put(`/operators/${id}/queues`, request).then(response => response.data),
    makeOffline: (id: string): Promise<void> =>
        apiInstance.post(`/operators/${id}/make_offline`).then(response => response.data),
    getAllSummaryByTenant: (id: string, onlyDoRoute: boolean): Promise<OperatorSummary[]> =>
        apiInstance
            .get(`/tenants/${id}/operators/summary?onlyDoRoute=${onlyDoRoute}`)
            .then(response => response.data.map(processUser)),
    connectOperator: (projectId: string, operatorId: string, dialogId: string, notificationId: string) =>
        apiInstance.post(`/${projectId}/connected-operator/connect-to-dialog`, undefined, {
            params: { operatorId, dialogId, notificationId }
        })
}

export default operatorsController
