import React, { Ref } from "react"
import styles from "./MessageBasic.module.scss"
import cn from "classnames"
import {
    Action,
    MessageAttachment,
    MessageDirection,
    MessageTextType,
    UpdatedMessage
} from "../../../../../models/Dialogs/message"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faUserCircle } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import { getDateLocale } from "../../../../../locales"
import { format } from "date-fns"
import Button from "react-bootstrap/Button"
import { getLanguage } from "../../../../../utility/common/language"
import UserCard from "../../../../UserCard/UserCard"
import { SimpleChannel } from "../../../../../models/channel"
import AttachmentItem from "../../../../AttachmentItem/AttachmentItem"
import { GALLERY_VIEW_DIALOG_ID } from "../../../../GalleryPreviewer/GalleryPreviewer"
import { useActions } from "../../../../../hooks/useAction"
import { testId } from "../../../../../utility/tests/testId"
import {
    useGetAllowedFileExtensions,
    useGetAllowedVideoExtensions
} from "../../../../../utility/knowledgeBase/articleRedactorEmbeds"
import ContentEditor from "../../../../ContentEditor/ContentEditor"
import { TSenderSignature } from "../../../../Dialogs/DialogChat/DialogChat"

// import ChannelIcon from "../ChannelIcon/ChannelIcon"

const tNamespace = "dialogs:info."

export interface MessageBasicProps {
    message: UpdatedMessage
    sender: TSenderSignature
    channel?: SimpleChannel
    lastMessageRef?: Ref<HTMLDivElement>
    className?: string
}

const MessageBasic: React.FC<MessageBasicProps> = props => {
    const { className, message, sender, lastMessageRef } = props
    const allowedFileExtensions = useGetAllowedFileExtensions()
    const allowedVideoExtensions = useGetAllowedVideoExtensions()
    const { i18n, t } = useTranslation()

    const locale = { locale: getDateLocale(getLanguage(i18n)) }
    //const dispatch = useDispatch()
    const { showDialog } = useActions()

    const isOutgoing = message.Direction === MessageDirection.Outgoing

    const tryGetAttachments = (message: UpdatedMessage) => {
        if (!message.Attachments?.length) return null

        const attachments: MessageAttachment[] = []
        if (message.Attachments) attachments.push(...message.Attachments)

        return attachments
    }

    const attachments = tryGetAttachments(message)

    const renderAttachment = (attachment: MessageAttachment) => {
        if (!attachments) {
            return null
        }

        const items = attachments.map(x => ({
            name: x.Name,
            url: x.Url,
            mime: x.ContentType
        }))

        return (
            <AttachmentItem
                onPressItem={() =>
                    showDialog({
                        dialogId: GALLERY_VIEW_DIALOG_ID,
                        dialogMeta: {
                            name: attachment.Name,
                            url: attachment.Url,
                            mime: attachment.ContentType,
                            items
                        }
                    })
                }
                title={attachment.Name}
                //@TODO Remove ?? 0
                size={attachment.Size ?? 0}
                url={attachment.Url}
            />
        )
    }

    // const getChannel = () => {
    //     if (channel) {
    //         return (
    //             <div className={styles.messageBasic__info}>
    //                 <ChannelIcon type={channel.Type} />
    //                 <span>{channel.Title}</span>
    //             </div>
    //         )
    //     }
    // }

    const unknownClientFullName = `${t(`${tNamespace}unknown-client-lastname`)} ${t(
        `${tNamespace}unknown-client-firstname`
    )}`

    const operatorFullName =
        sender?.Firstname || sender?.Lastname || sender?.Middlename
            ? `${sender?.Lastname ?? ""} ${sender?.Firstname ?? ""} ${sender?.Middlename ?? ""}`
            : ""

    const clientFullName =
        sender?.Firstname || sender?.Lastname || sender?.Middlename
            ? `${sender?.Lastname ?? ""} ${sender?.Firstname ?? ""} ${sender?.Middlename ?? ""}`
            : unknownClientFullName

    const fullName = isOutgoing ? operatorFullName : clientFullName

    const hasButtonsSection = message.Text ? message.Text.includes("```buttons") : false

    function parseActions(message: string): Action[] {
        const regex = /\[(.*?)\]\(type:(action|url) visible:(true|false) action:(.*?)\)/g
        const actions: Action[] = []

        let match
        while ((match = regex.exec(message)) !== null) {
            actions.push({ action_id: match[4], action_text: match[1] })
        }

        return actions
    }

    function extractText(message: string): string {
        const startIndex = message.indexOf("```")

        if (startIndex !== -1) {
            return message.substring(0, startIndex).trim()
        }

        return message
    }

    const renderMessageInner = () => {
        switch (message.TextType) {
            case MessageTextType.Markdown:
                return (
                    <ContentEditor
                        className={styles.messageBasic__markdownContainer}
                        allowedFileExtensions={allowedFileExtensions}
                        allowedVideoExtensions={allowedVideoExtensions}
                        content={hasButtonsSection ? extractText(message.Text) : message.Text}
                        onChange={() => void 0}
                        isEditable={false}
                        t={t}
                    />
                )
        }

        return message.Text
    }

    const actions = hasButtonsSection ? parseActions(message.Text) : message.Actions

    return (
        <div
            className={cn(styles.messageBasic, isOutgoing ? styles.messageBasic_out : undefined, className)}
            data-test-id={testId.messageBasic}
            ref={lastMessageRef}
        >
            <UserCard
                firstName={sender?.Firstname}
                lastName={sender?.Lastname}
                picture={sender?.Picture}
                isShort
                className={styles.messageBasic__user}
                isClient={!isOutgoing}
                isNewWorkplace
            />
            <div className={styles.messageBasic__body}>
                <div className={styles.messageBasic__content}>
                    {message.Text && <div className={styles.messageBasic__text}>{renderMessageInner()}</div>}
                    {actions.length > 0 && (
                        <div className={cn(styles.messageBasic__actions, styles.messageBasic__section)}>
                            {actions.map(v => (
                                <Button
                                    as="div"
                                    key={v.action_id}
                                    className={styles.messageBasic__action}
                                    variant="outline-light"
                                >
                                    {v.action_text}
                                </Button>
                            ))}
                        </div>
                    )}
                    {attachments && (
                        <div className={cn(styles.messageBasic__attachments, styles.messageBasic__section)}>
                            {attachments.map(v => (
                                <div className={styles.messageBasic__attachment} key={v.Url}>
                                    {renderAttachment(v)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={styles.messageBasic__footer}>
                    <div className={styles.messageBasic__info}>
                        <FontAwesomeIcon
                            icon={isOutgoing ? faUserCircle : faUser}
                            className={cn(styles.messageBasic__icon, isOutgoing && styles.messageBasic__icon_operator)}
                        />
                    </div>
                    <div className={styles.messageBasic__info}>{`${fullName}`}</div>
                    <div className={styles.messageBasic__info}>
                        {format(new Date(message.AddedDate), "dd-MM-yyyy HH:mm:ss", locale)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessageBasic
