import React, { useCallback, useContext, useMemo, useState } from "react"
import { nameof, nameof2 } from "../../../utility/common/nameof"
import {
    ElementType,
    IFrameMode,
    SystemValues,
    TransportType,
    WebChatChannelValues
} from "../../../models/webChatValues"
import ValidatableInput from "../../ValidatableInput/ValidatableInput"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import ValidatableInputWithControl from "../../ValidatableInputWithControl/ValidatableInputWithControl"
import { useFormikContext } from "formik"
import InfoIcon from "../../InfoIcon/InfoIcon"
import RadioButtonValidatableInput from "../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { ParamValues } from "../../../models/parameterDeclaration"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import ConfigContext from "../../ConfigContext/ConfigContext"
import { BotSettingsValues } from "../../../models/channelValues"
import { useSelector } from "react-redux"
import { selectSurveys } from "../../../store/surveys/selectors"
import { formTranslation } from "../../../locales/form"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."

const WebChatSystemSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext<WebChatChannelValues>()

    const surveys = useSelector(selectSurveys)

    const { WebConfig } = useContext(ConfigContext)
    const advancedSettingsEnabled = useMemo<boolean>(() => WebConfig.webChat.advancedSettingsEnabled, [WebConfig])

    const specifiedHostEnabled = useMemo(
        () => values.SystemSettings.iFrameMode === IFrameMode.specifiedHost,
        [values.SystemSettings.iFrameMode]
    )

    const [rootElementEnabled, setRootElementEnabled] = useState(!!values.SystemSettings.rootElementValue)
    const [openChatButtonEnabled, setOpenChatButtonEnabled] = useState(
        !!values.SystemSettings.openChatButtonSelectorValue
    )

    const elementTypes: string[] = useMemo(() => Object.values(ElementType), [])

    const handleRootElementToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked
            setRootElementEnabled(checked)
            if (!checked) {
                setFieldValue(
                    nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "rootElementValue"),
                    "",
                    false
                )
            }
        },
        [setRootElementEnabled, setFieldValue]
    )

    const handleOpenChatButtonToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked
            setOpenChatButtonEnabled(checked)
            if (!checked) {
                setFieldValue(
                    nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "openChatButtonSelectorValue"),
                    "",
                    false
                )
            }
        },
        [setOpenChatButtonEnabled, setFieldValue]
    )

    return (
        <FormAccordion title={t(`${tNamespace}system`)}>
            <DebouncedCheckBox
                id="formSocketIOInitialLoad"
                name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "socketIOInitialLoad")}
                label={t(`${tNamespace}connection-on-page-load`)}
            />
            <DebouncedCheckBox
                id="formEnableMobileVersion"
                name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "enableMobileVersion")}
                label={t(`${tNamespace}adapt-for-mobile`)}
            />
            <DebouncedCheckBox
                id="formCobrowsingEnabled"
                name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "cobrowsingEnabled")}
                label={t(`${tNamespace}cobrowsing`)}
                icon={
                    <InfoIcon
                        id="popoverCobrowsingEnabled"
                        title={t(`${tNamespace}cobrowsing`)}
                        content={t(`${tTooltipNamespace}cobrowsing-info`)}
                    />
                }
            />
            {advancedSettingsEnabled && (
                <DebouncedCheckBox
                    id="formUseSentry"
                    name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "useSentry")}
                    label={t(`${tNamespace}use-sentry`)}
                />
            )}
            <ValidatableInputWithControl
                id="formRootElement"
                type="text"
                name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "rootElementValue")}
                selectName={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "rootElementType")}
                label={t(`${tNamespace}element-for-placing-widget-on-page`)}
                isEnabled={rootElementEnabled}
                onToggle={handleRootElementToggle}
                options={elementTypes}
                placeholder={t(formTranslation.enterText)}
            />
            <ValidatableInputWithControl
                id="formOpenChatButtonSelector"
                type="text"
                name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "openChatButtonSelectorValue")}
                selectName={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "openChatButtonSelectorType")}
                label={t(`${tNamespace}element-for-opening-widget`)}
                isEnabled={openChatButtonEnabled}
                onToggle={handleOpenChatButtonToggle}
                options={elementTypes}
                placeholder={t(formTranslation.enterText)}
            />
            {t(`${tNamespace}iframe-mode`)}
            <div className="channel-form__section">
                <RadioButtonValidatableInput
                    value={IFrameMode.disabled}
                    id="formIFrameMode1"
                    name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "iFrameMode")}
                    label={t(`${tNamespace}disabled`)}
                />
                <RadioButtonValidatableInput
                    value={IFrameMode.insecure}
                    id="formIFrameMode2"
                    name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "iFrameMode")}
                    label={t(`${tNamespace}all-external-addresses`)}
                    icon={
                        <InfoIcon
                            id="popoverIFrameMode2"
                            title={t(`${tNamespace}all-external-addresses`)}
                            content={t(`${tTooltipNamespace}all-external-addresses-info`)}
                        />
                    }
                />
                <RadioButtonValidatableInput
                    value={IFrameMode.specifiedHost}
                    id="formIFrameMode3"
                    name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "iFrameMode")}
                    label={t(`${tNamespace}ability-to-specify-host`)}
                    icon={
                        <InfoIcon
                            id="popoverIFrameMode3"
                            title={t(`${tNamespace}ability-to-specify-host`)}
                            content={t(`${tTooltipNamespace}ability-to-specify-host-info`)}
                        />
                    }
                />
                <div className="channel-form__subfield">
                    <ValidatableInput
                        id="formIFrameHost"
                        type="text"
                        name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "iFrameHost")}
                        disabled={!specifiedHostEnabled}
                        placeholder={t(formTranslation.enterText)}
                    />
                </div>
            </div>
            <div className="channel-form__section">
                <DebouncedValidatableInput
                    id="formSurveyForFilteringExternalContext"
                    as="select"
                    disabled={!surveys.length}
                    name={nameof2<WebChatChannelValues, ParamValues>("Params", "survey_for_filtering_external_context")}
                    label={t(`${tNamespace}survey-for-filtering-external-context`)}
                    icon={
                        <InfoIcon
                            id="popoverSurveyForFilteringExternalContext"
                            title={t(`${tNamespace}survey-for-filtering-external-context`)}
                            content={t(`${tTooltipNamespace}survey-for-filtering-external-context-info`)}
                        />
                    }
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {surveys.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Title}
                        </option>
                    ))}
                </DebouncedValidatableInput>
                <DebouncedValidatableInput
                    id="formOperatorName"
                    type="text"
                    name={nameof2<WebChatChannelValues, ParamValues>("Params", "operatorName")}
                    label={t(`${tNamespace}system-name`)}
                    placeholder={t(formTranslation.enterText)}
                />
                <DebouncedValidatableInput
                    id="formOperatorTimeServiceName"
                    type="text"
                    name={nameof2<WebChatChannelValues, ParamValues>("Params", "operatorTimeServiceName")}
                    label={t(`${tNamespace}time-service-name`)}
                    placeholder={t(formTranslation.enterText)}
                />
            </div>
            <DebouncedCheckBox
                id="formBottomPlacement"
                name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "bottomPlacement")}
                label={t(`${tNamespace}widget-bottom-placement`)}
            />
            <div className="channel-form__section">
                <DebouncedValidatableInput
                    id="formCustomCss"
                    as="textarea"
                    name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "customCss")}
                    label={t(`${tNamespace}custom-css`)}
                    className="webchat-form__custom-css"
                    placeholder={t(formTranslation.enterText)}
                />
                {advancedSettingsEnabled && (
                    <DebouncedValidatableInput
                        id="formDebug"
                        as="textarea"
                        name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "debug")}
                        label={t(`${tNamespace}debug-callback-authorization`)}
                        className="webchat-form__debug"
                        placeholder={t(formTranslation.enterText)}
                    />
                )}
                <DebouncedValidatableInput
                    id="formChatAnnouncement"
                    as="textarea"
                    name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "chatAnnouncement")}
                    label={t(`${tNamespace}chat-announcement`)}
                    placeholder={t(formTranslation.enterText)}
                    className="webchat-form__chat-announcement"
                    icon={
                        <InfoIcon
                            id="popoverChatAnnouncement"
                            title={t(`${tNamespace}chat-announcement`)}
                            content={t(`${tTooltipNamespace}chat-announcement-info`)}
                        />
                    }
                />
            </div>
            <DebouncedCheckBox
                id="formEnableTextActions"
                name={nameof2<WebChatChannelValues, ParamValues>("Params", "enable_text_actions")}
                label={t(`${tNamespace}enable-text-actions`)}
            />
            <DebouncedValidatableInput
                id="formProxyServerAddress"
                type="text"
                name={nameof2<WebChatChannelValues, ParamValues>("Params", "proxy_server_address")}
                label={t(`${tNamespace}proxy-server-address`)}
                placeholder={t(formTranslation.enterText)}
            />
            <ValidatableInput
                id="formToken"
                type="text"
                name={nameof2<WebChatChannelValues, BotSettingsValues>("BotSettings", "Token")}
                label={t(`${tNamespace}token`)}
                placeholder={t(formTranslation.enterText)}
            />
            <ValidatableInput
                id="formPreprocessorWebHook"
                type="text"
                name={nameof<WebChatChannelValues>("PreprocessorWebHook")}
                label={t(`${tNamespace}preprocessor-webhook`)}
                placeholder={t(formTranslation.enterText)}
            />
            {t(`${tNamespace}custom-transport`)}
            <div className="channel-form__section">
                <RadioButtonValidatableInput
                    value={TransportType.websocket}
                    id="formCustomTransport1"
                    name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "customTransport")}
                    label="Websocket"
                />
                <RadioButtonValidatableInput
                    value={TransportType.polling}
                    id="formCustomTransport2"
                    name={nameof2<WebChatChannelValues, SystemValues>("SystemSettings", "customTransport")}
                    label="Polling"
                />
            </div>
        </FormAccordion>
    )
}

export default WebChatSystemSettings
