import React from "react"
import { ClassProps } from "../../utility/common/props"
import { SwitchTaskRequest, TaskOfAutomaticProcessing } from "../../models/task"
import { OperatorSummary } from "../../models/operator"
import { FormikProps, Formik } from "formik"
import { WithT } from "i18next"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { switchTask } from "../../store/tasks/thunks"
import RouteTaskFormBase from "../RouteTaskFormBase/RouteTaskFormBase"
import { selectSwitchTaskState } from "../../store/tasks/selectors"
import { nameof } from "../../utility/common/nameof"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import useOperatorQueues from "../../utility/queues/useOperatorQueues"
import { Queue } from "../../models/queue"

const tNamespace = "task:form."

interface RouteTaskValues {
    operatorId: string
    queueId: string
}

const defaultValues: RouteTaskValues = {
    operatorId: "",
    queueId: ""
}

interface RouteTaskFromAutomaticProcessingFormProps extends ClassProps {
    task: TaskOfAutomaticProcessing
    operators: OperatorSummary[]
    queues: Queue[]
    onCancel: () => void
    onAfterRoute: () => void
}

type FormikComponentProps = RouteTaskFromAutomaticProcessingFormProps & FormikProps<RouteTaskValues> & WithT

const FormikRouteTaskFromAutomaticProcessingForm: React.FC<FormikComponentProps> = props => {
    const { className, onCancel, t, operators, queues } = props

    const switchTaskState = useSelector(selectSwitchTaskState)
    const operatorQueues = useOperatorQueues<RouteTaskValues>(operators, queues)

    return (
        <RouteTaskFormBase className={className} t={t} onCancel={onCancel} state={switchTaskState}>
            <ValidatableInput id="selectOperator" as="select" type="text" name={nameof<RouteTaskValues>("operatorId")}>
                <option hidden value="">
                    {t(`${tNamespace}select-operator`)}
                </option>
                {operators.map(v => (
                    <option value={v.Id} key={v.Id}>{`${v.FirstName} ${v.LastName}`}</option>
                ))}
            </ValidatableInput>
            <ValidatableInput
                id="selectQueue"
                as="select"
                type="text"
                name={nameof<RouteTaskValues>("queueId")}
                label={t(`${tNamespace}to-operator-queue`)}
            >
                <option hidden value="">
                    {t(`${tNamespace}select-queue`)}
                </option>
                {operatorQueues.map(q => (
                    <option value={q.Id} key={q.Id}>
                        {q.Name}
                    </option>
                ))}
            </ValidatableInput>
        </RouteTaskFormBase>
    )
}

const createSwitchTaskRequest = (task: TaskOfAutomaticProcessing, values: RouteTaskValues): SwitchTaskRequest => ({
    TaskId: task.Id,
    OperatorId: values.operatorId,
    QueueId: values.queueId
})

const RouteTaskFromAutomaticProcessingForm: React.FC<RouteTaskFromAutomaticProcessingFormProps> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { task, onAfterRoute } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={defaultValues}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={() => {
                return Yup.object().shape({
                    operatorId: Yup.string().requiredExcludeEmpty(`${tNamespace}operator-required`),
                    queueId: Yup.string().requiredExcludeEmpty(`${tNamespace}queue-required`)
                })
            }}
            onSubmit={(values: RouteTaskValues) => {
                dispatch(switchTask(createSwitchTaskRequest(task, values), onAfterRoute))
            }}
        >
            {formikProps => <FormikRouteTaskFromAutomaticProcessingForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default RouteTaskFromAutomaticProcessingForm
