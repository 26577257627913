import React, { useState, ReactNode, useCallback } from "react"
import styles from "./FormAccordion.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { Accordion } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons"
import AccordionToggle from "./AccordionToggle"
import Spinner from "../Spinner/Spinner"

export interface FormAccordionProps extends ClassProps {
    disabled?: boolean
    headerClassName?: string
    title: string
    children: ReactNode
    headerElement?: JSX.Element
    loading?: boolean
    initialOpened?: boolean
    size?: "lg" | "md"
    eventKey?: string
    headerTitleClassName?: string
    id?: string
}

const FormAccordion: React.FC<FormAccordionProps> = props => {
    const {
        id,
        className,
        disabled,
        title,
        headerElement,
        children,
        loading = false,
        initialOpened = false,
        size = "md",
        eventKey = "0",
        headerClassName,
        headerTitleClassName
    } = props
    const [opened, setOpen] = useState(initialOpened)

    const handleToggle = useCallback(() => setOpen(!opened), [opened])

    return (
        <Accordion
            id={id}
            className={cn(className, styles.formAccordion)}
            defaultActiveKey={initialOpened ? "0" : undefined}
        >
            <AccordionToggle disabled={!!disabled} eventKey={eventKey} onToggle={handleToggle}>
                <div
                    className={cn(
                        styles.formAccordion__header,
                        size === "lg" && styles.formAccordion__header_lg,
                        headerClassName
                    )}
                >
                    <div
                        className={cn(
                            styles.formAccordion__title,
                            disabled ? styles.formAccordion__title_disabled : undefined,
                            headerTitleClassName
                        )}
                    >
                        {title}
                        {loading && <Spinner className={styles.formAccordion__spinner} />}
                    </div>
                    {headerElement && <div className={styles.formAccordion__headerElement}>{headerElement}</div>}
                    {!disabled ? (
                        <div className={styles.formAccordion__toggle}>
                            <FontAwesomeIcon icon={opened ? faChevronUp : faChevronDown} />
                        </div>
                    ) : undefined}
                </div>
            </AccordionToggle>
            <Accordion.Collapse eventKey={eventKey}>
                <div className={styles.formAccordion__body}>{children}</div>
            </Accordion.Collapse>
        </Accordion>
    )
}

export default FormAccordion
