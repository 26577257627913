import React, { memo, useCallback, useContext, useEffect, useState } from "react"
import "./MessageNode.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { Button } from "react-bootstrap"
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd"
import ButtonList, { ButtonsListProps, renderItem } from "./ButtonsList"
import { getBlockButtonId, reorder } from "../../../../utility/scenario/scenario"
import TextareaAutosize from "react-textarea-autosize"
import { ScenarioContext } from "../../../ScenarioEditor/ScenarioContext"
import CommonNode from "../Common/CommonNode"
import { ScenarioBlockType } from "../../../../models/scenario"
import { NodeComponentProps } from "react-flow-renderer/dist/nocss/types"
import { ElementId, Handle, Position, useUpdateNodeInternals } from "react-flow-renderer"
import cn from "classnames"
import debounce from "lodash/debounce"
import { handleUpdateButtons } from "../../../../utility/scenario/scenarioNode"

export interface MessagedNodeData {
    Text: string
    UpdateText: (text: string, nodeId: string | null) => void
    Buttons: MessagedNodeButton[]
    AddBlock: (source?: string, sourceHandle?: string) => void
}

export interface MessagedNodeButton {
    Id: string
    Title?: string
}

type Props = NodeComponentProps<MessagedNodeData>

const MessageNode: React.FC<Props> = ({ data, id, isConnectable }) => {
    const { selectedNode, setElements } = useContext(ScenarioContext)
    const disabled = selectedNode !== id
    const updateNodeInternals = useUpdateNodeInternals()
    const [buttons, setButtons] = useState(data.Buttons)

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination || result.destination.index === result.source.index) return
            setButtons(reorder(buttons, result.source.index, result.destination.index))
        },
        [buttons]
    )

    const handleAddBlock = (sourceHandle: string) => data.AddBlock(id, sourceHandle)

    const buttonListProps: ButtonsListProps = {
        buttons,
        disabled: disabled,
        onAddBlock: handleAddBlock,
        onUpdateButtons: buttons => setButtons(buttons),
        isConnectable
    }

    const handleTextChange = (text: string) => {
        data.UpdateText(text, id)
    }
    const handleTextChangedDebounced = debounce(handleTextChange, 200)

    const updateButtons = useCallback(
        (buttons: MessagedNodeButton[], id: ElementId) => handleUpdateButtons(setElements)(buttons, id),
        [setElements]
    )

    useEffect(() => {
        updateButtons(buttons, id)
    }, [updateButtons, buttons, id])

    useEffect(() => {
        updateNodeInternals(id)
    }, [id, data.Buttons, updateNodeInternals])

    return (
        <CommonNode
            id={id}
            className="message-node"
            headerClassName="message-node__header"
            type={ScenarioBlockType.Message}
            isConnectable={isConnectable}
        >
            <div className="message-node__content">
                <TextareaAutosize
                    className={cn("message-node__text", !data.Text.length && "message-node__text_invalid")}
                    defaultValue={data.Text}
                    onChange={e => handleTextChangedDebounced(e.target.value)}
                    readOnly={disabled}
                    maxRows={25}
                    minRows={3}
                />
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={id} renderClone={renderItem(buttonListProps)}>
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className="message-node__btn-list">
                            <ButtonList {...buttonListProps} />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="message-node__add-section">
                <Button
                    variant="light"
                    className="message-node__add-btn"
                    onClick={() => setButtons([...buttons, { Id: getBlockButtonId(id) }])}
                    disabled={disabled}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>
            <Handle
                id={id}
                type="source"
                position={Position.Right}
                className={cn("message-node__handle", "message-node__handle_default")}
                onClick={() => data.AddBlock(id, id)}
                isConnectable={isConnectable}
            />
        </CommonNode>
    )
}

export default memo(MessageNode)
