import React, { FormEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import FormContent from "../../FormHelpers/Content/FormContent"
import FormSection from "../../FormHelpers/Section/FormSection"
import {
    CommonReportSlotsValue,
    CommonReportSlotValue,
    ProjectSettingsListValue
} from "../../../models/projectSettings"
import {
    ReportProjectSettingsValues,
    StatisticsAndReportSettingsValues,
    StatisticsProjectSettingsValues
} from "../../../models/projectSettingsValues"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectProjectSettings, selectUpdateProjectSettingsState } from "../../../store/projects/selectors"
import ProjectSettingsLayout from "../../ProjectSettingsLayout/ProjectSettingsLayout"
import { Formik, FormikProps } from "formik"
import FormSectionTitle from "../../FormHelpers/SectionTitle/FormSectionTitle"
import { minimalTimezoneSet } from "compact-timezone-list"
import { nameof2, nameof3 } from "../../../utility/common/nameof"
import ValidatableInput from "../../ValidatableInput/ValidatableInput"
import SlotSelectControl from "../../AgentForm/common/SlotSelectControl"
import PairSettingsControl from "../../PairSettingsControl/PairSettingsControl"
import StatisticsCalculatingSelect from "./StatisticsCalculatingSelect"
import TimeoutControl from "../TimeoutControl"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import StatisticsPeriodSelect from "./StatisticsPeriodSelect"
import { formTranslation } from "../../../locales/form"
import FormControlWithAdd from "../../FormHelpers/ControlWithAdd/FormControlWithAdd"
import MultipleSelectControl from "../../FormHelpers/MultipleSelectControl/MultipleSelectControl"
import { selectSlots } from "../../../store/slots/selectors"
import { getSlots } from "../../../store/slots/thunks"
import RemovableListItem from "../../FormHelpers/RemovableListItem/RemovableListItem"
import { Form } from "react-bootstrap"
import { useDifferenceCount } from "../../../utility/project/projectSettings"
import operatorsController from "../../../api/controllers/operators"
import useAsyncState from "../../../utility/common/useAsyncState"
import { FETCH_OPERATORS_MESSAGE } from "../../TaskView/TaskView"
import { OperatorSummary } from "../../../models/operator"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { buildStatisticsSettingsRequest, getDefaultStatisticsSettings, getStatisticsInitialValues } from "./helpers"
import { updateProjectSettings } from "../../../store/projects/thunks"
import { getSlotsMap } from "../../../utility/scenario/scenarioGraph"
import { getCatalogs } from "../../../store/knowledgeBase/thunks"
import UnproductiveAnswersSelect from "../OperatorsWork/UnproductiveAnswersSelect"
import knowledgeBaseController from "../../../api/controllers/knowledgeBase"
import { selectCurrentUserLogin } from "../../../store/users/selectors"
import { handleHttpExceptionWithoutAction } from "../../../store/handleHttpException"
import * as knowledgeBaseCommentsConstants from "../../../store/knowledgeBaseComments/constants"

const tNamespace = "project:project-settings.statistics."

interface FormProps extends FormikProps<StatisticsAndReportSettingsValues> {
    projectId: string
    operators: OperatorSummary[]
    onChange: (
        e: FormEvent<HTMLFormElement>,
        initialValues: StatisticsAndReportSettingsValues,
        values: StatisticsAndReportSettingsValues
    ) => void
    onCustomInputChange: <K>(name: string, value: K, initialValues: StatisticsAndReportSettingsValues) => void
    articleTitles: Record<string, string>
    setArticleTitles: (articleTitles: Record<string, string>) => void
}

const getOperatorLabel = (operator: OperatorSummary) => `${operator.LastName} ${operator.FirstName}`

const FormikStatisticsProjectSettings: React.FC<FormProps> = props => {
    const { t } = useTranslation()
    const {
        projectId,
        operators,
        initialValues,
        values,
        onChange,
        onCustomInputChange,
        articleTitles,
        setArticleTitles
    } = props

    const slots = useSelector(selectSlots)
    const slotsMap = getSlotsMap(slots)

    return (
        <Form onChange={e => onChange(e, initialValues, values)} onKeyPress={preventSubmitOnEnter}>
            <ProjectSettingsLayout.Section border>
                <FormContent>
                    <FormSection>
                        <FormSectionTitle>{t(`${tNamespace}statistics`)}</FormSectionTitle>
                    </FormSection>
                    <MultipleSelectControl
                        id="formFunnelSlots"
                        label={t(`${tNamespace}funnel-slots`)}
                        name={nameof3<
                            StatisticsAndReportSettingsValues,
                            StatisticsProjectSettingsValues,
                            ProjectSettingsListValue
                        >("Statistics", "FunnelSlots", "Values")}
                        options={slots.map(slot => ({ label: slot.Title, value: slot.ExternalId }))}
                        selectedItems={remove => (
                            <>
                                {values.Statistics.FunnelSlots.Values.map((v, index) => (
                                    <RemovableListItem key={index} onDelete={() => remove(index)}>
                                        {slotsMap[v]?.Title ?? v}
                                    </RemovableListItem>
                                ))}
                            </>
                        )}
                        onSelect={(inputName, option) => onCustomInputChange(inputName, option, initialValues)}
                        disabled={!slots.length}
                    />
                    <UnproductiveAnswersSelect
                        projectId={projectId}
                        onCustomInputChange={onCustomInputChange}
                        articleTitles={articleTitles}
                        setArticleTitles={setArticleTitles}
                    />
                    <FormSection>
                        <StatisticsPeriodSelect
                            id="formTimeRangeStartDefiningField"
                            name={nameof2<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues>(
                                "Statistics",
                                "TimeRangeStartDefiningField"
                            )}
                            label={t(`${tNamespace}time-range-start-defining-field`)}
                        />
                        <StatisticsPeriodSelect
                            id="formTimeRangeEndDefiningField"
                            name={nameof2<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues>(
                                "Statistics",
                                "TimeRangeEndDefiningField"
                            )}
                            label={t(`${tNamespace}time-range-end-defining-field`)}
                        />
                        <StatisticsPeriodSelect
                            id="formHandledCountField"
                            name={nameof2<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues>(
                                "Statistics",
                                "HandledCountField"
                            )}
                            label={t(`${tNamespace}handled-count-field`)}
                        />
                        <MultipleSelectControl
                            id="formExcludedOperatorIds"
                            label={t(`${tNamespace}excluded-operator-ids`)}
                            name={nameof3<
                                StatisticsAndReportSettingsValues,
                                StatisticsProjectSettingsValues,
                                ProjectSettingsListValue
                            >("Statistics", "ExcludedOperatorIds", "Values")}
                            options={operators.map(o => ({ label: getOperatorLabel(o), value: o.Id }))}
                            selectedItems={remove => (
                                <>
                                    {values.Statistics.ExcludedOperatorIds.Values.map((v, index) => {
                                        const operator = operators.find(o => o.Id === v)
                                        return (
                                            <RemovableListItem key={index} onDelete={() => remove(index)}>
                                                {operator ? getOperatorLabel(operator) : v}
                                            </RemovableListItem>
                                        )
                                    })}
                                </>
                            )}
                            onSelect={(inputName, option) => onCustomInputChange(inputName, option, initialValues)}
                            disabled={!operators.length}
                        />
                        <TimeoutControl
                            id="formFCR"
                            name={nameof2<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues>(
                                "Statistics",
                                "FCR"
                            )}
                            label={t(`${tNamespace}fcr`)}
                        />
                        <DebouncedCheckBox
                            id="formFCRSeparateDifferentTopics"
                            name={nameof2<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues>(
                                "Statistics",
                                "FCRSeparateDifferentTopics"
                            )}
                            label={t(`${tNamespace}fcr-separate-different-topics`)}
                        />
                        <StatisticsCalculatingSelect
                            id="formSLEndDefiningField"
                            name={nameof2<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues>(
                                "Statistics",
                                "SLEndDefiningField"
                            )}
                            label={t(`${tNamespace}sl-end-defining-field`)}
                        />
                        <TimeoutControl
                            id="formSLPeriod"
                            name={nameof2<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues>(
                                "Statistics",
                                "SLPeriod"
                            )}
                            label={t(`${tNamespace}sl-period`)}
                            daysHidden
                        />
                        <StatisticsCalculatingSelect
                            id="formAHTStartDefiningField"
                            name={nameof2<StatisticsAndReportSettingsValues, StatisticsProjectSettingsValues>(
                                "Statistics",
                                "AHTStartDefiningField"
                            )}
                            label={t(`${tNamespace}aht-start-defining-field`)}
                        />
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>
            <ProjectSettingsLayout.Section>
                <FormContent>
                    <FormSection>
                        <FormSectionTitle>{t(`${tNamespace}reporting`)}</FormSectionTitle>
                    </FormSection>
                    <FormSection>
                        <ValidatableInput
                            id="formCommonReportTimeZone"
                            as="select"
                            name={nameof2<StatisticsAndReportSettingsValues, ReportProjectSettingsValues>(
                                "Report",
                                "CommonReportTimeZone"
                            )}
                            label={t(`${tNamespace}common-report-time-zone`)}
                        >
                            <option value="" hidden>
                                {t(formTranslation.select)}
                            </option>
                            {minimalTimezoneSet.map((timezone, index) => (
                                <option key={index} value={timezone.offset}>
                                    {timezone.label}
                                </option>
                            ))}
                        </ValidatableInput>
                    </FormSection>
                    <FormSection>
                        <FormControlWithAdd
                            name={nameof3<
                                StatisticsAndReportSettingsValues,
                                ReportProjectSettingsValues,
                                CommonReportSlotsValue
                            >("Report", "CommonReportSlots", "Values")}
                            label={t(`${tNamespace}common-report-slots`)}
                            onCreateEmptyValue={() => ""}
                        >
                            {({ name, remove }) =>
                                values.Report.CommonReportSlots.Values.map(
                                    (v: CommonReportSlotValue, index: number) => (
                                        <PairSettingsControl
                                            id={`formCommonReportSlots${index}`}
                                            keyControl={
                                                <SlotSelectControl
                                                    id={`formCommonReportSlotsSlotId${index}`}
                                                    name={`${name}[${index}].SlotId`}
                                                    label={t(`${tNamespace}slot`)}
                                                    t={t}
                                                />
                                            }
                                            valueControl={
                                                <ValidatableInput
                                                    id={`formCommonReportSlotsColumnTitle${index}`}
                                                    type="text"
                                                    name={`${name}[${index}].ColumnTitle`}
                                                    label={t(`${tNamespace}common-report-slots-column-title`)}
                                                />
                                            }
                                            onDelete={() => remove(index)}
                                        />
                                    )
                                )
                            }
                        </FormControlWithAdd>
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>
        </Form>
    )
}

interface Props {
    projectId: string
    title: string
    open: boolean
}

const StatisticsProjectSettings: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, title, open } = props
    const settings = useSelector(selectProjectSettings)
    const login = useSelector(selectCurrentUserLogin)
    const updateProjectSettingsState = useSelector(selectUpdateProjectSettingsState, shallowEqual)

    const { differenceCount, onOpenSettings, onChange, onCustomInputChange, reset } = useDifferenceCount()
    const initialValues = useMemo(() => settings && getStatisticsInitialValues(settings), [settings])
    const [articleTitles, setArticleTitles] = useState<Record<string, string>>({})

    const loadOperatorsNames = useCallback(
        () => operatorsController.getAllSummaryByTenant(projectId, false),
        [projectId]
    )
    const operatorsNamesState = useAsyncState(loadOperatorsNames, FETCH_OPERATORS_MESSAGE)

    useEffect(() => {
        dispatch(getSlots(projectId))
        dispatch(getCatalogs(projectId))
    }, [projectId])

    useEffect(() => {
        const getArticleTitles = async () => {
            const symbolCodes = initialValues?.Statistics.UnproductiveAnswers.Values
            if (!login || !symbolCodes || !symbolCodes.length) return

            try {
                const articleTitles = await knowledgeBaseController.getAllPublishTitles(projectId, {
                    Login: login,
                    SymbolCodes: symbolCodes
                })

                setArticleTitles(
                    articleTitles.ArticleTitles.reduce<Record<string, string>>((dictionary, articleTitle) => {
                        dictionary[articleTitle.SymbolCode] = articleTitle.Title
                        return dictionary
                    }, {})
                )
            } catch (e) {
                handleHttpExceptionWithoutAction(e, knowledgeBaseCommentsConstants.CONNECT_TO_HUB_FAILED, dispatch)
            }
        }
        getArticleTitles()
    }, [initialValues, login])

    useEffect(() => {
        if (open && settings) {
            onOpenSettings(getDefaultStatisticsSettings(), {
                Statistics: settings?.Statistics,
                Report: settings?.Report
            })
        }
    }, [onOpenSettings, settings])

    if (!initialValues) return null

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: StatisticsAndReportSettingsValues) => {
                dispatch(updateProjectSettings(projectId, buildStatisticsSettingsRequest(values), reset))
            }}
        >
            {formikProps => (
                <ProjectSettingsLayout.Container
                    title={title}
                    onButtonClick={formikProps.handleSubmit}
                    buttonText={t(formTranslation.save)}
                    hideButton={!differenceCount}
                    notifications={differenceCount}
                    loading={updateProjectSettingsState.inProcess}
                >
                    <FormikStatisticsProjectSettings
                        {...formikProps}
                        onChange={onChange}
                        onCustomInputChange={onCustomInputChange}
                        projectId={projectId}
                        operators={operatorsNamesState.asyncState.data ?? []}
                        articleTitles={articleTitles}
                        setArticleTitles={setArticleTitles}
                    />
                </ProjectSettingsLayout.Container>
            )}
        </Formik>
    )
}

export default StatisticsProjectSettings
